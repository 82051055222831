import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CreatedIncidentResult } from '../../../../../model/created-incident-data';
import { MergedMappedOpMode, ExternalFaultModes, InternalFaultModes } from 'src/app/model/mapped-op-mode';

@Component({
  selector: 's2a-machine-state-widget',
  templateUrl: './machine-state-widget.component.html',
  styleUrls: ['./machine-state-widget.component.scss']
})
export class MachineStateWidgetComponent implements OnInit {
  @Input() lineId: string;
  @Input() id: string;
  @Input() name: string;
  @Input() techDesc: string;
  @Input() icon: string;
  @Input() isLeadMachine: boolean;
  @Input() status: string;
  currentStatus: string;
  @Input() startTime: number;
  @Input() incidents: CreatedIncidentResult[];
  @Output() clickedIncident: EventEmitter<CreatedIncidentResult> = new EventEmitter<CreatedIncidentResult>();

  constructor() { }

  ngOnInit(): void {
    setInterval(() => {
      this.setCurrentStatus();
    }, 1000);
  }

  get bgColorClass(): string {
    if (this.status === 'running' || this.status === MergedMappedOpMode.PRODUCTIVE) {
      return 'bg-green';
    } else if (this.status === 'external-fault' || Object.values(ExternalFaultModes).includes(this.status)) {
      return 'bg-yellow';
    } else if (this.status === 'internal-fault' || Object.values(InternalFaultModes).includes(this.status)) {
      return 'bg-red';
    } else {
      return '';
    }
  }

  get textColor(): string {
    if (Object.values(ExternalFaultModes).includes(this.status)) {
      return '#060526';
    } else {
      return '#ffffff';
    }
  }

  getCurrentStatus(): string {
    if (this.status === 'external-fault' || this.status === 'internal-fault'
          || this.status !== MergedMappedOpMode.PRODUCTIVE && Object.values(MergedMappedOpMode).includes(this.status)) {
      return this.getTimeDifferenceRepresentation(this.startTime);
    }
    return '';
  }

  setCurrentStatus(): void {
    this.currentStatus = this.getCurrentStatus();
  }

  get isFault(): boolean {
    if (this.status === 'external-fault' || this.status === 'internal-fault'
          || this.status !== MergedMappedOpMode.PRODUCTIVE && Object.values(MergedMappedOpMode).includes(this.status)) {
      return true;
    }
    return false;
  }

  get iconSizeClass(): string {
    if (this.isFault) {
      return 'icon-lg';
    } else {
      return '';
    }
  }

  getTimeDifferenceInSeconds(startTime: number): number {
    const nowTime = (new Date()).getTime();
    return Math.max(Math.round((nowTime - startTime) / 1000), 0);
  }

  getTimeDifferenceRepresentation(startTime: number): string {
    let timeDifferenceInSeconds = this.getTimeDifferenceInSeconds(startTime);

    const hours = Math.floor(timeDifferenceInSeconds / (60 * 60));
    timeDifferenceInSeconds %= 60 * 60;
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    const seconds = timeDifferenceInSeconds % 60;

    if (hours > 0) {
      return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
    } else if (minutes > 0) {
      return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
    } else {
      return `${seconds}`;
    }
  }

  private padNumber(num: number): string {
    if (num < 10) {
      return `0${num}`;
    } else {
      return `${num}`;
    }
  }
}
