import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

import { MachineLossItem } from '../../../../../model/machine-history';
import { MergedMappedOpMode } from 'src/app/model/mapped-op-mode';


const UNCATEGORIZED_MAPPED_OP_MODE = 'other_fault';

@Component({
  selector: 's2a-interrupt-categories',
  templateUrl: './interrupt-categories.component.html',
  styleUrls: ['./interrupt-categories.component.scss']
})
export class InterruptCategoriesComponent implements OnInit {
  @Input() start: number;
  @Input() end: number;
  @Input() timezone: string;
  @Input() set rawLossesDetailed(rawLossesDetailed: MachineLossItem[]) {
    this.chartOptions.series = this.getLossesDetailed(rawLossesDetailed).reverse();

    const lastEnd: number = rawLossesDetailed[rawLossesDetailed.length - 1].end;
    if (this.end > lastEnd) {
      const currentTimeLinePos: number = (lastEnd - this.start) / (1000 * 60 * 60);
      this.chartOptions.yAxis.plotBands = [
        {
          color: this.currentTimeColor,
          from: currentTimeLinePos - 0.01,
          to: currentTimeLinePos + 0.03,
        }
      ];
    }

    this.updateFlag = true;
  }

  currentTimeColor = '#3382c2';

  Highcharts = Highcharts;
  chartOptions = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    xAxis: {
      title: {
        enabled: false
      },
      categories: ['losses'],
      labels: {
        enabled: false
      },
      lineColor: 'rgba(128, 128, 128, 0.5)',
      tickLength: 0,
    },
    yAxis: {
      title: {
        enabled: false,
      },
      opposite: true,
      min: 0,
      max: 1,
      gridLineWidth: 2,
      gridLineColor: 'rgba(128, 128, 128, 0.5)',
      tickInterval: 1,
      labels: {
        formatter: function (): string {
          return this.value;
        },
        style: {
          'font-size': '18px',
          color: '#777777',
        }
      },
      plotBands: [],
    },
    series: [],
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointWidth: 85,
        borderWidth: 0,
        point: {
          events: {
            click: function (): void { },
          },
        },
      },
      bar: {
        enableMouseTracking: true,
      }
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (): string {
        return '';
      },
      backgroundColor: '#303030',
      borderWidth: 0,
      padding: 15,
    },
    credits: {
      enabled: false
    },
  };
  updateFlag = false;

  get startHour(): number {
    return (new Date(this.start)).getUTCHours();
  }

  get startHourLocalTime(): number {
    const startLocalTime = moment.unix(this.start / 1000).tz(this.timezone || 'UTC');
    return parseInt(startLocalTime.format('HH'), 10);
  }

  get endHour(): number {
    return (new Date(this.end)).getUTCHours();
  }

  constructor(private translate: TranslateService, private router: Router) { }



  ngOnInit(): void {
    const startHourLocalTime = this.startHourLocalTime;
    this.chartOptions.yAxis.labels.formatter = function (): string {
      return `${(this.value + startHourLocalTime) % 24}:00`;
    };

    const translate: TranslateService = this.translate;
    const timezone: string = this.timezone;
    this.chartOptions.tooltip.formatter = function (): string {
      const interruptCategory: string = translate.instant('components.performance.line_dashboard.interrupts.' + this.series.name);
      const startTime: string = moment.unix(this.point.start / 1000).tz(timezone || 'UTC').format('HH:mm:ss');
      const endTime: string = moment.unix(this.point.end / 1000).tz(timezone || 'UTC').format('HH:mm:ss');
      const length: string = moment.utc(this.y * 60 * 60 * 1000).format('HH:mm:ss');
      let incidentClick = '';
      if (this.point.incidentId && this.series.name === UNCATEGORIZED_MAPPED_OP_MODE) {
        const clickToClassify: string = translate.instant('components.performance.line_dashboard.interrupts.click_to_classify');
        incidentClick = `<br><div style="color: #ffffff; font-size: 14px; text-align: center;">${clickToClassify}</div>`;
      }
      const tooltip = `
          <div style="color:#ffffff; font-size: 18px; font-weight: bold; text-align: center;">
            ${startTime} - ${endTime}
          </div><br>
          <div style="color: #ffffff; font-size: 14px; margin-top: -8px; font-weight: bold; text-align: center">
            (${length})
          </div><br>
          <div style="color: #ffffff; font-size: 18px; font-weight: bold; text-align: center;">
            ${interruptCategory}
          </div>
          ${incidentClick}
        `;
      return tooltip;
    };

    const router: Router = this.router;
    this.chartOptions.plotOptions.series.point.events.click = function (): void {
      if (this.options.incidentId && this.series.name === UNCATEGORIZED_MAPPED_OP_MODE) {
        window.open(router.createUrlTree(['/incidents', 'reporter', this.options.incidentId]).toString(), '_blank');
      }
    };

    const shiftLengthInHours: number = (this.end - this.start) / (60 * 60 * 1000);
    this.chartOptions.yAxis.max = shiftLengthInHours;
  }

  getLossesDetailed(rawLossesDetailed: MachineLossItem[]): any[] {
    return rawLossesDetailed.map((rawLoss: MachineLossItem) => {
      return {
        name: rawLoss.mapped_op_mode,
        data: [
          [(rawLoss.end - rawLoss.start) / (1000 * 60 * 60), rawLoss.start, rawLoss.end, rawLoss.incident_id, rawLoss.message]
        ],
        keys: ['y', 'start', 'end', 'incidentId', 'message'],
        className: Object.values(MergedMappedOpMode).includes(rawLoss.mapped_op_mode)
          ? rawLoss.mapped_op_mode + ' transparent' : 'other transparent'
      };
    });
  }
}
