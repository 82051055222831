import { Injectable } from '@angular/core';

import { Shift } from '@s2a/ng-shifts';

@Injectable({
  providedIn: 'root'
})
export class ShiftGridHelperService {
  constructor() {
  }

  public getHourLabelOffset(shift: Shift, shiftHour: number): string {
    if (shiftHour === 0) {
      return '0';
    }
    if (shiftHour === shift.duration) {
      return '95%';
    }
    const offset = (shiftHour / shift.duration) - 0.02;
    return `${offset * 100}%`;
  }

  public getHourOffset(shift: Shift, shiftHour: number): string {
    return `${(shiftHour / shift.duration) * 100}%`;
  }

  public getHourLabel(shift: Shift, shiftHour: number): string {
    const hour: number = ((new Date(shift.from)).getUTCHours() + shiftHour) % 24;
    if (hour >= 10) {
      return `${hour}:00`;
    }
    return `0${hour}:00`;
  }
}
