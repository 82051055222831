import { ProductionSite } from './../../../../../model/production-site';
import { SiteService } from './../../../../../services/site.service';
import { ProductionLineSelectionComponent } from './../../../../global/production-line-selection/production-line-selection.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 's2a-production-line-selection-insight',
  templateUrl: '../../../../global/production-line-selection/production-line-selection.component.html',
  styleUrls: ['../../../../global/production-line-selection/production-line-selection.component.scss']
})
export class ProductionLineSelectionInsightComponent extends ProductionLineSelectionComponent implements OnInit {

  constructor(private siteService: SiteService) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.siteService.getAllSites()
      .subscribe((sites: ProductionSite[]) => {
        this.lines = sites.map((site: ProductionSite) => <[string, string]>[site.lineId, site.lineName]);
        this.isLoading = false;
      });
  }
}
