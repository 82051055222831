import { Component, OnInit, Input } from '@angular/core';

import { Shift } from '@s2a/ng-shifts';

import { TooltipStateItem } from '../../../../../model/tooltip-state-item';
import { LossDetailed } from 'src/app/model/loss-detailed';

@Component({
  selector: 's2a-machine-losses',
  templateUrl: './machine-losses.component.html',
  styleUrls: ['./machine-losses.component.scss']
})
export class MachineLossesComponent implements OnInit {
  @Input() shift: Shift;
  @Input() lossesDetailed: LossDetailed[];
  @Input() timezone: string;

  public stateItems: TooltipStateItem[] = [
    {
      titleTranslationKey: 'production',
      colorClass: 'production'
    },
    {
      titleTranslationKey: 'speed_losses_and_minor_stops',
      colorClass: 'speed-losses-and-minor-stops'
    },
    {
      titleTranslationKey: 'breakdowns_internal',
      colorClass: 'breakdown'
    },
    {
      titleTranslationKey: 'external_stops',
      colorClass: 'external-stop'
    },
    {
      titleTranslationKey: 'planned_downtimes_and_change_over',
      colorClass: 'planned-downtimes-and-change-over'
    },
    {
      titleTranslationKey: 'other_faults',
      colorClass: 'other'
    }
  ];

  constructor() { }

  ngOnInit(): void { }
}
