<div class="row">
    <div class="col p-3 flex-grow-0" *ngFor="let kpi of kpiList">
        <s2a-donut-kpi *ngIf="kpi.kpiInfo1.type == 'donut'; else singleTextKpi" 
            [chartData]="getDonutChartData(kpi.kpiInfo1)"
            [titles]="getDonutFaults(kpi)" 
            [subtitle]="'components.performance.machine_report.machine_kpis.' + kpi.kpiInfo1.source | translate">
        </s2a-donut-kpi>
        <ng-template #singleTextKpi>
            <s2a-text-kpi 
                *ngIf="!kpi.kpiInfo2; else doubleTextKpi" 
                [rawValue1]="kpi.kpiInfo1.value"
                [type1]="kpi.kpiInfo1.type"
                [unit1]="getUnitText(kpi.kpiInfo1.unit)" 
                [title]="'components.performance.machine_report.machine_kpis.' + kpi.title | translate"
                [subtitle]="(kpi.subtitle ? 'components.performance.machine_report.machine_kpis.' + kpi.subtitle : '') | translate">
            </s2a-text-kpi>
        </ng-template>
        <ng-template #doubleTextKpi>
            <s2a-text-kpi 
                [rawValue1]="kpi.kpiInfo1.value"
                [type1]="kpi.kpiInfo1.type"
                [unit1]="getUnitText(kpi.kpiInfo1.unit)" 
                [rawValue2]="kpi.kpiInfo2.value"
                [type2]="kpi.kpiInfo2.type"
                [unit2]="getUnitText(kpi.kpiInfo2.unit)"
                [title]="'components.performance.machine_report.machine_kpis.' + kpi.title | translate"
                [subtitle]="(kpi.subtitle ? 'components.performance.machine_report.machine_kpis.' + kpi.subtitle : '') | translate">
            </s2a-text-kpi>
        </ng-template>
    </div>
</div>