<s2a-modal-header title="{{ 'components.language_selection.title' | translate }}" [modalRef]="modalRef"></s2a-modal-header>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table language-table">
      <tr *ngFor="let lang of translateHelper.availableLanguages" (click)="switchLanguage(lang)"
      [ngClass]="{'selected': (lang === translateHelper.getCurrentLanguage())}">
        <td><a class="btn btn-link">{{lang.description}}</a></td>
      </tr>
    </table>
  </div>
</div>
<!--
<div class="modal-footer">
  <button class="btn btn-primary btn-leaf float-right" type="Submit" (click)="modalRef.hide()" translate>global.close</button>
</div>
-->
