<s2a-modal-header title="{{ 'components.edit_incidents.title' | translate }}" [modalRef]="modalRef"></s2a-modal-header>

  <div class="modal-body">
    <ng-container *ngIf="isLoading">
      <s2a-spinner></s2a-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <!-- <h5 translate>components.edit_equipment.equipment_data</h5> -->
    <div>
      <div class="row">
        <!-- Category -->
        <div class="col-sm">
          <label class="form-label" for="category">
            <span translate>components.edit_incidents.downtime_category</span>
          </label>
          <select [ngModel]="selectedCategory" (ngModelChange)="chooseCategory($event)" id="category" class="form-control">
            <option *ngFor='let category of categories' [ngValue]="category">{{category.name}}</option>
          </select>
        </div>

        <!-- Time of Occurence / START TIME -->
        <div class="col-lg">
          <label class="form-label" translate for="startTimeTime">components.edit_incidents.start_date_time</label>
          <div class="row">
            <div class="col-sm">
              <input type="text" bsDatepicker class="form-control" [bsValue]="selectedStartDate" [disabled]="!isEditable">
            </div>
            <div class="col-sm">
              <timepicker id="startTimeTime" [showMeridian]="showMeridian" [showSpinners]="false" [(ngModel)]="selectedStartDate" [disabled]="!isEditable"></timepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Machine -->
        <div class="col-sm">
          <label class="form-label" for="machine">
            <span translate>components.edit_incidents.machine</span>
          </label>
          <select [ngModel]="selectedMachine" (ngModelChange)="chooseMachine($event)" id="machine" class="form-control">
            <option *ngFor='let machine of machines' [ngValue]="machine">{{machine.description}} ({{machine.techDesc}})</option>
          </select>
        </div>

        <!-- END TIME -->
        <div class="col-lg">
          <label class="form-label" translate for="endTimeTime">components.edit_incidents.end_date_time</label>
          <div class="row">
            <div class="col-sm">
              <input type="text" bsDatepicker class="form-control" [(bsValue)]="selectedEndDate">
            </div>
            <div class="col-sm">
              <timepicker id="endTimeTime" [showMeridian]="showMeridian" [showSpinners]="false" [(ngModel)]="selectedEndDate"></timepicker>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isIncident">
        <div class="row">
          <!-- Component -->
          <div class="col-sm">
            <label class="form-label" for="component">
              <span translate>components.edit_incidents.component</span>
            </label>
            <select [ngModel]="selectedComponent" (ngModelChange)="chooseComponent($event)" id="component" class="form-control">
              <option *ngFor='let component of components' [ngValue]="component">{{component.description}}</option>
            </select>
          </div>
          <!-- Status -->
          <div class="col-lg">
            <div class="form-group">
              <label class="form-label" for="solved">
                <span translate>components.edit_incidents.status</span>
              </label>
              <div>
                <ui-switch [(ngModel)]="selectedStatus" id="solved" color="" switchColor="" defaultBgColor="transparent" defaultBoColor="">
                </ui-switch>
                <span *ngIf="selectedStatus" translate>components.edit_incidents.solved</span>
                <span *ngIf="!selectedStatus" translate>components.edit_incidents.pending</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Scenario -->
          <div class="col-sm-6">
            <label class="form-label" for="scenario">
              <span translate>components.edit_incidents.scenario</span>
            </label>
            <select [ngModel]="selectedScenario" (ngModelChange)="chooseScenario($event)" id="scenario" class="form-control">
              <option *ngFor='let scenario of scenarios' [ngValue]="scenario">{{scenario.title}}</option>
            </select>
            <div *ngIf="selectedScenario">
              <hr class="transparent small">
              <span translate>components.edit_incidents.scenario_title</span>: {{selectedScenario.title}}
              <br>
              <span translate>components.edit_incidents.scenario_desc</span>: {{selectedScenario.description}}
            </div>
          </div>
          <!-- Subscenario -->
          <div class="col-sm-6" *ngIf="subscenarios.length > 0">
            <label class="form-label" for="subscenario">
              <span translate>components.edit_incidents.subscenario</span>
            </label>
            <select [ngModel]="selectedSubscenario" (ngModelChange)="chooseSubscenario($event)" id="subscenario" class="form-control">
              <option *ngFor='let subscenario of subscenarios' [ngValue]="subscenario">{{subscenario.title}}</option>
            </select>
            <div *ngIf="selectedSubscenario">
              <hr class="transparent small">
              <span translate>components.edit_incidents.scenario_title</span>: {{selectedSubscenario.title}}
              <br>
              <span translate>components.edit_incidents.scenario_desc</span>: {{selectedSubscenario.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="transparent small">
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-leaf float-right" type="Submit" translate (click)="save()">global.save</button>
  </div>
