import { Component, OnInit, Input } from '@angular/core';
import { Mpu } from '../../../../model/mpu';
import { EquipmentInsight } from '../../../../model/equipment-insight';
import { ProductionSiteEquipment } from '../../../../model/production-site-equipment';

@Component({
  selector: 's2a-mpu-widget',
  templateUrl: './mpu-widget.component.html',
  styleUrls: ['./mpu-widget.component.scss']
})
export class MpuWidgetComponent implements OnInit {

  title: string;
  iconUrl: string;
  mpuParts: ProductionSiteEquipment[] = [];

  public data: any[] = [];
  private _kpi: EquipmentInsight = undefined;
  private _mpu: Mpu = undefined;

  @Input() set mpu(value: Mpu) {
    if (value !== undefined && value !== null) {
      this._mpu = value;
      this.mpuParts = value.equipments;
      this.setMpuTitle();
      this.setIconUrl();
    }
  }

  @Input() set kpi(value: EquipmentInsight) {
    if (!value) {
      this.data = [];
      this._kpi = undefined;
      return;
    }
    this._kpi = value;
    if (value.losses) {
      const effective_running_time = 1.0 - value.losses.tech_fault -
        value.losses.org_fault - value.losses.lack - value.losses.tailback - value.losses.non_productive;
      this.data = [
        { share: value.losses.tech_fault, kind: 'technical faults', color: '#b3282e' },
        { share: value.losses.org_fault, kind: 'organisational faults', color: '#e34805' },
        { share: value.losses.lack, kind: 'lack', color: '#66b0e2' },
        { share: value.losses.tailback, kind: 'tailback', color: '#ff94eb' },
        { share: value.losses.non_productive, kind: 'non productive', color: '#ffffff' },
        { share: effective_running_time, kind: 'effective running time', color: '#97C218' }
      ];
    }
  }

  get kpisAvailable(): boolean {
    if (!!this._kpi && !!this._kpi.kpis && !this.objIsEmpty(this._kpi.kpis)) {
      return true;
    }
    return false;
  }

  private objIsEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  get kpi(): EquipmentInsight {
    return this._kpi;
  }

  get mpu(): Mpu {
    return this._mpu;
  }

  constructor() {
  }

  ngOnInit(): void {

  }

  setMpuTitle(): void {
    const mainUnitIndex = this.mpu.equipments.findIndex(eq => eq.equipmentId === this.mpu.representativeId);
    this.title = `${this.mpu.equipments[mainUnitIndex].type} | ${this.mpu.equipments[mainUnitIndex].serialNumber}`;
  }

  setIconUrl(): void {
    const mainUnitIndex = this.mpu.equipments.findIndex(eq => eq.equipmentId === this.mpu.representativeId);
    this.iconUrl = `${this.mpu.equipments[mainUnitIndex].iconUrl}`;
  }

  formatPercentage(value: number): string {
    const percentage = value;
    if (percentage.toFixed(1) === '100.0') {
      return '100';
    }
    return percentage.toFixed(1);
  }
}
