<div class="row">
  <div class="col">
    <div>{{'components.insight.structure-whiteboard.shift-selection.date' | translate}}</div>
    <input type="text" popup-placement="auto + bottom" placeholder="Datepicker" class="form-control" [maxDate]="maxDate" [bsValue]="selectedDate"
      (bsValueChange)="onDateChange($event)" [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }" bsDatepicker>
  </div>
  <div class="col">
    <div>{{'components.insight.structure-whiteboard.shift-selection.shift' | translate}}</div>
    <div class="btn-group col-12 btn-group px-0" dropdown>
      <button id="button-basic" dropdownToggle type="button" class="btn btn-shiftdropdown" aria-controls="dropdown-basic">
        {{getSlotDisplayText(selectedShiftSlot)}}
        <s2a-svg-icon path="global/actions/" name="navigationArrow" class="dropdownButton" ></s2a-svg-icon>
      </button>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
        <li role="menuitem" *ngFor="let shift of shiftModel.shifts" (click)="selectedSlotChanged(shift)" class="pointer">
          <div class="dropdown-item">{{getSlotDisplayText(shift)}}</div>
        </li>
      </ul>
    </div>
  </div>
</div>
