import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 's2a-performance-value',
  templateUrl: './performance-value.component.html',
  styleUrls: ['./performance-value.component.scss']
})
export class PerformanceValueComponent implements OnInit {
  @Input() icon: String;
  @Input() title: String;
  @Input() value: String;

  constructor() { }

  ngOnInit(): void {
  }
}
