import * as moment from 'moment-timezone';

import { Component, OnInit, Input} from '@angular/core';

import { MachineEquipment } from '@s2a/ng-equipment';
import { Shift } from '@s2a/ng-shifts';


@Component({
  selector: 's2a-shift-header',
  templateUrl: './shift-header.component.html',
  styleUrls: ['./shift-header.component.scss']
})
export class ShiftHeaderComponent implements OnInit {
  @Input() firstHeading: string;
  @Input() machine: MachineEquipment;
  @Input() secondHeading = '';
  @Input() shift: Shift;
  @Input() timezone: string;

  constructor() { }

  ngOnInit(): void {
  }

  get machineName(): string {
    return this.machine ? this.machine.description : '';
  }

  get techDesc(): string {
    return this.machine ? this.machine.techDesc : '';
  }


  get secondHeader(): string {
    return this.secondHeading;
  }

  get shiftDescription(): string {
    if (this.shift === undefined) {
      return '';
    }
    const shiftBegin = moment.unix(this.shift.from / 1000).tz(this.timezone);
    const shiftEnd = moment.unix(this.shift.from / 1000).tz(this.timezone).add(this.shift.duration, 'h');
    let shiftName = 'not available';

    if (this.shift.name !== undefined) {
      shiftName = this.shift.name;
    }

    return `${shiftBegin.format('MM-DD-YYYY - h:mm a')} - ${shiftEnd.format('h:mm a')} - ${shiftName}`;
  }
}
