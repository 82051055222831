<s2a-platform-header title="{{ 'apps.performance' | translate }}"></s2a-platform-header>

<div class="container-fluid">
  <div class="tab-nav">
    <ul class="nav-list list-unstyled list-inline">
      <li class="list-inline-item active">
        <a class="btn btn-link" [routerLink]="['/performance', 'line', lineId]">
          {{ 'components.performance.machine_report.line_dashboard' | translate }}
        </a>
      </li>
      <li [hidden]="isLoading" class="list-inline-item li-right">
        <a class="btn btn-link" (click)="exportReport()">
          <svg-icon src="assets/icons/excel-export.svg"></svg-icon>
        </a>
      </li>
    </ul>
  </div>
</div>

<div>
  <div class="container pb-4">
    <div class="row">
      <div class="col-8 offset-2">

        <div *ngIf="isLoading; else loaded" class="mt-5">
          <s2a-spinner></s2a-spinner>
        </div>

        <ng-template #loaded>

          <s2a-shift-header [firstHeading]="lineInfo" [machine]="machine" [shift]="shift" [timezone]="timezone"></s2a-shift-header>

          <s2a-shift-selection [shift]="shift" [shiftModel]='shiftModel' [timezone]="timezone" (shiftChanged)="changedShift($event)"></s2a-shift-selection>

          <s2a-machine-report-widgets [widgetInfos]="widgetInfos"></s2a-machine-report-widgets>

          <s2a-machine-main-faults-summary [lossesDetailed]="lossesDetailed"></s2a-machine-main-faults-summary>

          <s2a-machine-losses [shift]="shift" [lossesDetailed]="lossesDetailed" [timezone]="timezone"></s2a-machine-losses>

          <s2a-losses-waterfall [losses]="losses" [duration]="shift.duration"></s2a-losses-waterfall>

        </ng-template>

      </div>
    </div>
  </div>
</div>
