<header class="platform-header">
  <div class="brand-section" [routerLink]="['/home']">
    <svg-icon class="brand" src="assets/img/brand.svg"></svg-icon>
    <svg-icon class="brand-mobile" src="assets/img/brand-mobile.svg"></svg-icon>
  </div>

  <!-- Nav left start -->
  <nav class="header-nav-left">
    <div class="title-dropdown" dropdown>
      <div class="title-wrapper pl-2 pr-2 pr-sm-4 text-truncate" dropdownToggle (click)="false">
        {{title}}
        <svg-icon class="dropdown-icon d-none d-sm-block" src="assets/icons/down-arrow.svg"></svg-icon>
      </div>
      <ul class="header-service-grid dropdown-menu" *dropdownMenu role="menu">
        <li class="grid-item active" *ngFor="let app of apps" (click)="openApp(app)">
          <img src="assets/apps/service-icon-{{app.key}}.svg" alt="">
          <label translate>{{app.translationKey}}</label>
          <form *ngIf="app.key === 'lunchlist'" ngNoForm #lunchlistForm action="https://lunchlist.share2act-dev.io/" method="POST"
            target="LunchlistWindow">
            <input type="hidden" name="id">
            <input type="hidden" name="token">
          </form>
        </li>
      </ul>
    </div>
  </nav>

  <!-- Nav right start -->
  <nav class="header-nav-right">
    <div class="user-dropdown" dropdown>
      <div class="toggle-wrapper" dropdownToggle (click)="false">
        <!-- <div class="user-img">
          <img src="http://via.placeholder.com/50x50" alt="">
        </div> -->
        <div class="user-img">
          <span class="text-placeholder">{{getUserLetters()}}</span>
        </div>
        <div class="user-info">
          <div class="user-name">{{getUserName()}}</div>
          <div class="user-account">{{getUserAccount()}}</div>
        </div>
      </div>
      <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu">
        <li class="dropdown-item">
          <a class="btn btn-link btn-block text-left" routerLink="/user-management/user/me" translate>components.platform_header.my_profile</a>
        </li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item">
          <a class="btn btn-link btn-block text-left" (click)="toggleTheme()" translate>components.platform_header.toggle_theme</a>
        </li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item">
          <a class="btn btn-link btn-block text-left" (click)="changeLanguage()" translate>components.platform_header.change_language</a>
        </li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item">
          <a class="btn btn-link btn-block text-left" (click)="logout()" translate>components.platform_header.logout</a>
        </li>
      </ul>
    </div>

    <!-- <div class="notification-toggle">
      <span class="notification-badge">99+</span>
      <svg-icon class="notification-icon" src="assets/icons/notification.svg"></svg-icon>
    </div> -->
  </nav>
</header>
