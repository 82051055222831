import * as moment from 'moment';

import { Component, OnInit, Input } from '@angular/core';

import { Shift } from '@s2a/ng-shifts';

import { GridHelper } from '../grid-helper';

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

enum MappedOpMode {
  PRODUCTIVE = 'productive',
  TECH_FAULT = 'tech_fault',
  ORG_FAULT = 'org_fault',
  PREPARED = 'prepared',
  LACK = 'lack',
  TAILBACK = 'tailback',
  EXTERNAL = 'external',
  NON_PRODUCTIVE = 'non_productive',
  INCIDENT = 'incident',
  BREAKDOWN = 'breakdown',
  UNUSED = 'unused',
  NON_OP_MAINTENANCE = 'non_op_maintenance',
  NONA = 'nona',
  PLANNED_DOWNTIME = 'planned',
  CHANGEOVER = 'changeover',
  MINOR_STOP = 'minor_stop',
  OTHER_FAULT = 'other_fault'
}

interface LossDetailed {
  start: number;
  end: number;
  mapped_op_mode: MappedOpMode;
}

@Component({
  selector: 's2a-loss-chart',
  templateUrl: './loss-chart.component.html',
  styleUrls: ['./loss-chart.component.scss']
})
export class LossChartComponent implements OnInit {
  @Input() shift: Shift;
  @Input() lossesDetailed: LossDetailed[];
  @Input() timezone: string; // eg.: 'Europe/Berlin'
  public losses: LossDetailed[];
  public gridHelper: GridHelper;
  private shiftDuration: number;

  ngOnInit(): void {
    this.shiftDuration = this.shift.duration * HOUR_IN_MILLISECONDS;
    this.gridHelper = new GridHelper(this.shift, this.timezone);
    this.losses = this.lossesDetailed;
  }

  public getBackgroundLayerWidth(): string {
    const now = moment(new Date());
    const end = moment(this.shift.from);
    const duration = moment.duration(now.diff(end)).asHours();
    return (duration >= this.shift.duration ? '100%' : `${Math.round(duration / this.shift.duration * 100)}%`);
  }

  public getLossWidth(loss): string {
    const faultDuration = (loss.end - loss.start);
    return `${faultDuration / this.shiftDuration * 100}%`;
  }

  public getLossOffset(loss): string {
    return `${((loss.start - this.shift.from) / this.shiftDuration) * 100}%`;
  }

  public getLossColorClass(loss: LossDetailed): string {
    if (Object.values(MappedOpMode).includes(loss.mapped_op_mode)) {
      return loss.mapped_op_mode;
    }
    return 'non_productive';
  }

  get shiftHours(): number[] {
    if (this.shift === undefined) {
      return [];
    }
    return Array.from(Array(this.shift.duration + 1).keys());
  }
}
