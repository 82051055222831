import { Component, OnInit, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { LossDetailed } from 'src/app/model/loss-detailed';
import { MappedOpMode, MergedMappedOpMode } from 'src/app/model/mapped-op-mode';
import { TooltipStateItem } from 'src/app/model/tooltip-state-item';

const OTHER_FAULTS = 'other_faults';

@Component({
  selector: 's2a-machine-main-faults-summary',
  templateUrl: './machine-main-faults-summary.component.html',
  styleUrls: ['./machine-main-faults-summary.component.scss']
})
export class MachineMainFaultsSummaryComponent implements OnInit {
  public lossesGrouped: { [key: string]: LossSummary };
  public stateItems: TooltipStateItem[] = [
    {
      titleTranslationKey: 'breakdowns_internal',
      colorClass: 'breakdown'
    },
    {
      titleTranslationKey: 'external_stops',
      colorClass: 'external-stop'
    }
  ];

  @Input() lossesDetailed: LossDetailed[];

  get mainFaultKeys(): string[] {
    return Object.keys(this.lossesGrouped).filter(k => k !== OTHER_FAULTS);
  }

  get overallFaultCount(): number {
    return Object.values(this.lossesGrouped).reduce((count: number, loss: LossSummary) => count + loss.count_total, 0);
  }

  get overallFaultDuration(): number {
    return Object.values(this.lossesGrouped).reduce((duration: number, loss: LossSummary) => duration + loss.duration_total, 0);
  }

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.lossesGrouped = this.getLossesGroupedByMessageKey(this.lossesDetailed);
    this.translate.onLangChange.subscribe(() => {
      this.lossesGrouped[OTHER_FAULTS].info = this.getOtherFaultTranslationString();
      Object.keys(this.lossesGrouped)   // TODO: delete after Backend is sending messages for BF
        .filter(key => key !== OTHER_FAULTS && Object.values(MergedMappedOpMode).includes(key))
        .forEach(key => this.lossesGrouped[key].info = this.translate.instant('components.performance.line_dashboard.interrupts.' + key));
    });
  }

  public getLossesSubsetKeys(lossesKey: string): string[] {
    return Object.keys(this.lossesGrouped[lossesKey].losses_subset);
  }

  private getInitialGroupedLosses(): { [id: string]: LossSummary } {
    const losses_grouped = {};
    losses_grouped[OTHER_FAULTS] = { count_total: 0, duration_total: 0, info: this.getOtherFaultTranslationString() };
    return losses_grouped;
  }

  private getOtherFaultTranslationString(): string {
    return this.translate.instant('components.performance.machine_report.main_faults_summary.other_faults');
  }

  private getLossesGroupedByMessageKey(losses: LossDetailed[]): { [id: string]: LossSummary } {
    const mainLossesGrouped = this.getInitialGroupedLosses();
    losses.forEach(loss => {
      if (this.isNotMainFault(loss)) {
        return;
      }
      const key = this.getKeyOrDefault(loss);
      const duration = parseInt(loss.end, 10) - parseInt(loss.start, 10);
      if (mainLossesGrouped[key] === undefined) {
        mainLossesGrouped[key] = {
          info: this.isMessageMissing(loss)
            ? (loss.title
              ? loss.title
              : this.translate.instant('components.performance.line_dashboard.interrupts.' + loss.mapped_op_mode))
            : loss.message.info,
          duration_total: 0,
          count_total: 0,
          mapped_op_mode: loss.mapped_op_mode,
          losses_subset: {}
        };
      }
      mainLossesGrouped[key].duration_total += duration;
      mainLossesGrouped[key].count_total++;

      if (this.isMessageMissing(loss) || this.isKeywordMissing(loss)) {
        return;
      }
      const lossesSubsetKey = loss.message.keyword || loss.title;
      if (lossesSubsetKey) {
        if (mainLossesGrouped[key].losses_subset[lossesSubsetKey] === undefined) {
          mainLossesGrouped[key].losses_subset[lossesSubsetKey] = {
            keyword: lossesSubsetKey,
            count_total: 0,
            duration_total: 0
          };
        }
        mainLossesGrouped[key].losses_subset[lossesSubsetKey].duration_total += duration;
        mainLossesGrouped[key].losses_subset[lossesSubsetKey].count_total++;
      }
    });
    return mainLossesGrouped;
  }

  private isKeywordMissing(loss: LossDetailed): boolean {
    if (loss.message.keyword === undefined || loss.message.keyword === '') {
      return true;
    }
    return false;
  }

  private getKeyOrDefault(loss: LossDetailed): string {
    if (this.isMessageMissing(loss)) {
      return loss.mapped_op_mode;
    }
    const keyString = loss.message.id ? `[${loss.message.id}]-` : '';
    return loss.message.subsystem ? keyString + loss.message.subsystem : keyString.slice(0, keyString.length - 1);
  }

  private isMessageMissing(loss: LossDetailed): boolean {
    if (loss.message === undefined) {
      return true;
    }
    if (loss.message.subsystem === undefined) {
      return true;
    }
    return false;
  }

  private isNotMainFault(loss: LossDetailed): boolean {
    if (loss.mapped_op_mode !== MappedOpMode.ORG_FAULT
      && loss.mapped_op_mode !== MappedOpMode.TECH_FAULT
      && loss.mapped_op_mode !== MappedOpMode.INCIDENT
      && loss.mapped_op_mode !== MappedOpMode.BREAKDOWN
      && loss.mapped_op_mode !== MappedOpMode.EXTERNAL) {
      return true;
    }
    return false;
  }
}

interface LossSummary {
  info: string;
  count_total: number;
  mapped_op_mode: string;
  duration_total: number;
  losses_subset: { [keyword: string]: LossSubset };
}

interface LossSubset {
  keyword: string;
  count_total: number;
  duration_total: number;
}
