import { Component, Input, OnInit } from '@angular/core';

import { IconHelperService } from '@s2a/ng-common';

import { getKeyOrDefault, LossDetailed, otherFaults } from '../../../../../model/loss-detailed';
import { MappedOpMode } from '../../../../../model/mapped-op-mode';

@Component({
  selector: 's2a-states-for-validation',
  templateUrl: './states-for-validation.component.html',
  styleUrls: ['./states-for-validation.component.scss']
})
export class StatesForValidationComponent implements OnInit {
  public readonly states = [
    { title: 'components.insight.mpu-report.main_faults_common.prepared', mode: MappedOpMode.PREPARED },
    { title: 'components.insight.mpu-report.main_faults_common.lack', mode: MappedOpMode.LACK },
    { title: 'components.insight.mpu-report.main_faults_common.tailback', mode: MappedOpMode.TAILBACK },
  ];

  @Input() set lossesDetailed(value: LossDetailed[]) {
    if (value == null) {
      return;
    }
    const filteredValue = value.filter(v => [MappedOpMode.PREPARED, MappedOpMode.TAILBACK, MappedOpMode.LACK].includes(v.mapped_op_mode));
    this.calculateLossesSummaries(filteredValue);
  }

  public lossesSummary: { [id: string]: LossSummary };
  public lossesTotalSum = {
    'prepared': { duration: 0, count: 0 },
    'lack': { duration: 0, count: 0 },
    'tailback': { duration: 0, count: 0 },
  };
  public otherFaults = {
    'prepared': { duration: 0, count: 0 },
    'lack': { duration: 0, count: 0 },
    'tailback': { duration: 0, count: 0 },
  };

  constructor(private iconHelperService: IconHelperService) {
  }

  public ngOnInit(): void {
  }

  public calculateLossesSummaries(lossesDetailed: LossDetailed[]): void {
    const lossesSummary: { [key: string]: LossSummary } = {};
    lossesDetailed.forEach((loss: LossDetailed) => {
      const key: string = getKeyOrDefault(loss);
      const duration = parseInt(loss.end, 10) - parseInt(loss.start, 10);
      if (key === otherFaults) {
        this.otherFaults[loss.mapped_op_mode].count++;
        this.otherFaults[loss.mapped_op_mode].duration += duration;
        return;
      }

      const map_key: string = key + '_' + loss.mapped_op_mode;
      if (lossesSummary[map_key] == null) {
        lossesSummary[map_key] = {
          key: key,
          info: loss.message.info,
          durationTotal: 0,
          countTotal: 0,
          mapped_op_mode: loss.mapped_op_mode,
          lossesSubset: {}
        };
      }
      lossesSummary[map_key].durationTotal += duration;
      lossesSummary[map_key].countTotal++;

      if (loss.message.keyword != null && loss.message.keyword !== '') {
        if (lossesSummary[map_key].lossesSubset[loss.message.keyword] === undefined) {
          lossesSummary[map_key].lossesSubset[loss.message.keyword] = {
            keyword: loss.message.keyword,
            countTotal: 0,
            durationTotal: 0
          };
        }
        lossesSummary[map_key].lossesSubset[loss.message.keyword].durationTotal += duration;
        lossesSummary[map_key].lossesSubset[loss.message.keyword].countTotal++;
      }

      this.lossesTotalSum[loss.mapped_op_mode].duration += duration;
      this.lossesTotalSum[loss.mapped_op_mode].count++;
    });
    this.lossesSummary = lossesSummary;
  }

  public getLossesKeysByMode(mode: MappedOpMode): string[] {
    return Object.keys(this.lossesSummary).filter(k => this.lossesSummary[k].mapped_op_mode === mode);
  }

  public getLossesSubsetKeys(key: string): string[] {
    return Object.keys(this.lossesSummary[key].lossesSubset);
  }

  getAdditionalReportsIcon(): string {
    return this.iconHelperService.getIconUrl('global/reports/', 'additionalReports');
  }
}

interface LossSummary {
  key: string;
  info: string;
  mapped_op_mode: string;
  durationTotal: number;
  countTotal: number;
  lossesSubset: { [keyword: string]: LossSubset };
}

interface LossSubset {
  keyword: string;
  countTotal: number;
  durationTotal: number;
}
