import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CreatedIncidentResult } from '../../../../../model/created-incident-data';

@Component({
  selector: 's2a-incident-badge',
  templateUrl: './incident-badge.component.html',
  styleUrls: ['./incident-badge.component.scss'],
})
export class IncidentBadgeComponent {

  @Input()
  incidents: CreatedIncidentResult[];

  @Output() clickedIncident: EventEmitter<CreatedIncidentResult> = new EventEmitter<CreatedIncidentResult>();

  getIncidentDate(incident: CreatedIncidentResult): Date {
    return new Date(incident.incident_start_time);
  }

  openIncidents($event): void {
    $event.stopPropagation();
  }

}
