<div class="user-selection">
<ng-container *ngIf="openedInModal">
  <s2a-modal-header class="no-headline" title="{{ 'components.maintenance.add_user.title' | translate }}" [modalRef]="modalRef"></s2a-modal-header>
</ng-container>

<div class="modal-body">
  <ng-container *ngIf="isLoading">
    <s2a-spinner></s2a-spinner>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <form [formGroup]="formGroup">
      <div class="grid-wrapper">
        <div class="top-bar">
          <!-- SEARCH BAR -->
          <div [formGroup]="formGroup" class="search-bar">
            <input type="text" class="form-control" formControlName="searchString" placeholder="{{ 'global.search' | translate }}" />
          </div>
        </div>
        <div class="scroll-content" [ngClass]="{
          'scroll-content-modal': openedInModal
        }">
          <!-- Pinned parts -->
          <ul class="user-list list-unstyled">
            <li *ngFor="let user of selectedUsers" (click)="removeUser(user)">
              <div class="leaf-card active">
                <div class="user-wrapper">
                  <!-- <div class="user-img">
                    <img src="http://via.placeholder.com/50x50" alt="">
                  </div> -->
                  <div class="user-img">
                    <span class="text-placeholder">
                      {{ getUserLetters(user) }}
                    </span>
                  </div>
                  <div class="user-info">
                    <div class="user-name">{{user.firstname}} {{user.lastname}}</div>
                    <ng-container *ngIf="getUserInfo(user) !== null">
                      <small class="user-account">{{getUserInfo(user)}}</small>
                    </ng-container>
                  </div>
                </div>
                <div class="checkbox-wrapper">
                  <div class="checkbox">
                    <input [id]='user.userId' type='checkbox' [checked]="isChecked(user)" (change)="removeUser(user)" />
                    <label [for]='user.userId'>
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div *ngIf="!openedInModal" class="float-right">
            <ng-container *ngIf="!isSingleUser">
              <button class="btn btn-primary btn-leaf btnSave" type="Submit" translate (click)="save()">components.global.add_user.add</button>
            </ng-container>
            <button class="btn btn-primary btn-leaf btnDelete" type="Cancel" translate (click)="cancel()">components.global.add_user.cancel</button>
          </div>
          <hr>
          <!-- Search Results -->
          <ul class="user-list list-unstyled">
            <ng-container *ngFor="let user of users">
              <li *ngIf="!isChecked(user)" (click)="addUserDisabled(user) || addUser(user)">
                <div class="leaf-card"  [ngClass]="{'disabled-user': addUserDisabled(user)}">
                  <div class="user-wrapper">
                    <!-- <div class="user-img">
                      <img src="http://via.placeholder.com/50x50" alt="">
                    </div> -->
                    <div class="user-img">
                      <span class="text-placeholder">
                        {{ getUserLetters(user) }}
                      </span>
                    </div>
                    <div class="user-info">
                      <div class="user-name">{{user.firstname}} {{user.lastname}}</div>
                      <ng-container *ngIf="getUserInfo(user) !== null">
                        <small class="user-account">{{getUserInfo(user)}}</small>
                      </ng-container>
                    </div>
                  </div>
                  <div class="checkbox-wrapper">
                    <div class="checkbox">
                      <input *ngIf="!addUserDisabled(user)" [id]='user.userId' type='checkbox' [checked]="isChecked(user)" (change)="addUser(user)" />
                      <label [for]='user.userId'>
                        <span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
          <!-- No matching user -->
          <ng-container *ngIf="users.length === 0">
            <div class="row">
              <div class="col-12 text-center">
                <span translate>components.maintenance.add_user.no_matching_user</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="modal-footer" *ngIf="openedInModal && !isSingleUser">
  <button class="btn btn-primary btn-leaf float-right" type="Submit" translate (click)="save()">components.global.add_user.add</button>
</div>
</div>
