<ng-template #tooltip>
  <s2a-faults-tooltip [losses]="_losses"></s2a-faults-tooltip>
</ng-template>

<div #chartContainer class="chart-container mt-5 p-3">
  <div class="row mx-0">
    <div class="col px-0">
      <div>{{ 'components.global.charts.losses_waterfall.heading' | translate }}</div>
    </div>
    <div kendoTooltip [tooltipTemplate]="tooltip" position="bottom" class="col-auto px-0 mr-2 info-icon">
      <svg-icon title="" src="assets/icons/icon-info.svg"></svg-icon>
    </div>
  </div>
  <div class="row">
    <div class="col-auto d-flex flex-column pr-0 icon-container">
      <svg-icon *ngFor="let data of losses_waterfall_data" class="ml-2 loss-icon" [src]="data?.icon"></svg-icon>
    </div>

    <div id="fontsizetest"></div>
    <div class="col pl-0">
      <kendo-chart id="s2a-waterfall-chart" [style.height.px]="losses_waterfall_data.length * 35 + 31" [chartArea]="{background: 'none'}" [valueAxis]="{ max: 100, majornUnit: 25, color: '#ABCDEF' }"
        [categoryAxis]="{majorGridLines: {visible: false}}" (render)="kendoHelper.addViewBoxToSvg($event)"
        [resizeRateLimit]="1">
        <kendo-chart-series>

          <kendo-chart-series-item *ngFor="let data of losses_waterfall_data" type="bar" [gap]="0.25" [stack]="{ group: data?.group }"
            [data]="[data]" content="content" field="stack" colorField="color" [border]="{width: 0}">
            <kendo-chart-series-item-labels [content]="labelContent" position="insideEnd" color="#000" background="none"
              *ngIf="calculateSideForItem(data)==='left'">
            </kendo-chart-series-item-labels>
          </kendo-chart-series-item>

          <kendo-chart-series-item *ngFor="let data of losses_waterfall_data" type="bar" [gap]="0.25" [stack]="{ group: data?.group }"
            [data]="[data]" content="content" field="value" colorField="stackColor" [border]="{width: data?.border}">
            <kendo-chart-series-item-labels [content]="labelContent" position="insideEnd" [color]="data.textColor"
              background="none" *ngIf="calculateSideForItem(data)==='middle'">
            </kendo-chart-series-item-labels>
            <kendo-chart-series-item-labels [content]="labelContent" color="#ABCDEF" background="none" *ngIf="calculateSideForItem(data)==='right'">
            </kendo-chart-series-item-labels>
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
      {{ 'components.global.charts.losses_waterfall.shift_duration' | translate }} {{ getFormattedDuration() }}
    </div>
  </div>
  <div class="row d-none d-print-block pb-0 pt-1 mb-0">
    <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
  </div>

</div>