<div class="mx-3 mt-3">
  <div class="tooltip-title">
    {{ 'components.performance.machine_report.losses.state_colors' | translate }}
  </div>
  <ul class="color-items">
    <li *ngFor="let stateItem of stateItems">
      <div class="d-flex flex-row align-items-center">
        <div class="mr-2 color-field" [ngClass]="stateItem.colorClass"></div>
        {{ 'components.performance.machine_report.losses.states.' + stateItem.titleTranslationKey | translate }}
      </div>
    </li>
  </ul>
</div>