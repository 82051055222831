import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 's2a-donut-kpi',
  templateUrl: './donut-kpi.component.html',
  styleUrls: ['./donut-kpi.component.scss']
})
export class DonutKpiComponent implements OnInit {
  @Input() chartData: any[];
  @Input() titles: string[];
  @Input() subtitle: string;
  public availabilityChartData: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.availabilityChartData = this.chartData;
  }

  get percentAvailability(): number {
    return this.chartData[0].value * 100;
  }
}
