<div class="table-container p-3 mt-5">
  <div class="my-2 flex-container">
    <div style="flex: 1; align-self: flex-end; margin: 0px;">{{'components.insight.mpu-report.states_for_validation.heading'
      | translate}}</div>
    <div class="gradient no-flex">
      <svg-icon style="vertical-align: middle;" title="{{'components.insight.mpu-report.states_for_validation.for_internal_use_only' | translate}}"
        [src]="getAdditionalReportsIcon()"></svg-icon>
      {{'components.insight.mpu-report.states_for_validation.for_internal_use_only' | translate}}
    </div>
  </div>

  <ng-template ngFor let-state [ngForOf]="states">
    <div class="mt-3" *ngIf="lossesTotalSum[state.mode].count !== 0">
      <div class="header" [translate]="state.title"></div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th style="width: 20%;" scope="col">
                {{'components.insight.mpu-report.main_faults_common.message_no' | translate}}
              </th>
              <th style="width: 45%;" scope="col">
                {{'components.insight.mpu-report.main_faults_common.message' | translate}}
              </th>
              <th style="width: 10%;" scope="col" class="text-right">{{'components.insight.mpu-report.main_faults_common.count'
                | translate}}</th>
              <th style="width: 25%;" scope="col" class="text-right">{{'components.insight.mpu-report.main_faults_common.duration'
                | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-key [ngForOf]="getLossesKeysByMode(state.mode)">
              <tr class="table-border-top">
                <td class="h-100">
                  <div style="display: table; height: 100%; width: 100%;">
                    <div style="display: table-cell; height: 100%;">
                      <div class="colorcode h-100" [ngClass]="state.mode"></div>
                    </div>
                    <div style="display: table-cell; vertical-align: top; height: 100%;">
                      <span class="h-100">{{lossesSummary[key].key}}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div>{{ lossesSummary[key].info }}</div>
                </td>
                <td class="text-right">
                  <div *ngIf="getLossesSubsetKeys(key).length === 0">
                    {{ lossesSummary[key].countTotal }}
                  </div>
                </td>
                <td class="text-right">
                  <div *ngIf="getLossesSubsetKeys(key).length === 0">
                    {{ lossesSummary[key].durationTotal | formatDuration }}
                  </div>
                </td>
              </tr>
              <tr *ngFor="let subsetKey of getLossesSubsetKeys(key)">
                <td class="h-100">
                  <div style="display: table; height: 100%; width: 100%;">
                    <div style="display: table-cell; height: 100%;">
                      <div class="colorcode h-100" [ngClass]="state.mode"></div>
                    </div>
                  </div>
                </td>
                <td>
                  {{ lossesSummary[key].lossesSubset[subsetKey].keyword }}
                </td>
                <td class="text-right">
                  {{ lossesSummary[key].lossesSubset[subsetKey].countTotal }}
                </td>
                <td class="text-right">
                  {{ lossesSummary[key].lossesSubset[subsetKey].durationTotal | formatDuration }}
                </td>
              </tr>
            </ng-template>
            <tr class="table-border-top" *ngIf="otherFaults[state.mode].count !== 0">
              <td class="h-100">
                <div style="display: table; height: 100%; width: 100%;">
                  <div style="display: table-cell; height: 100%;">
                    <div class="colorcode h-100" [ngClass]="state.mode"></div>
                  </div>
                </div>
              </td>
              <td>{{'components.insight.mpu-report.main_faults_common.other_faults' | translate}}</td>
              <td class="text-right">{{otherFaults[state.mode].count}}</td>
              <td class="text-right">{{otherFaults[state.mode].duration | formatDuration}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr style="font-weight: bold;">
              <td></td>
              <td class="text-right" style="padding: 0px;">
                {{ 'components.insight.mpu-report.main_faults_common.total' | translate }}
              </td>
              <td class="text-right" style="padding: 0px;">
                {{ lossesTotalSum[state.mode].count + otherFaults[state.mode].count }}
              </td>
              <td class="text-right" style="padding: 0px;">
                {{ (lossesTotalSum[state.mode].duration + otherFaults[state.mode].duration) | formatDuration }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-template>
</div>