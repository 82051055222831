<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 confirmation-question">
      <h1 translate>global.components.confirmation-dialog.title</h1>
      <p>{{ question }}</p>
    </div>
  </div>
  <div class="row button-row">
    <div class="col-lg-6 button-field">
        <div class="btn" [ngClass]="getConfirmClass()" (click)="confirm()">
          <s2a-svg-icon path="global/actions/" name="{{ confirmIconName }}" class="confirmationButton"></s2a-svg-icon>
          <div class="confirm-text">{{ confirmText }}</div>
        </div>
    </div>
    <div class="col-lg-6 button-field">
        <div class="btn confirm-abort" (click)="decline()">
          <s2a-svg-icon path="global/actions/" name="cancel" class="confirmationButton"></s2a-svg-icon>
          <div class="confirm-text">{{ 'global.abort' | translate }}</div>
        </div>
    </div>
  </div>
</div>
