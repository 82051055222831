<s2a-platform-header title="{{ 'components.incidents.title' | translate }}"></s2a-platform-header>

<div class="container">
  <div class="tab-nav">

    <div class="title-dropdown" dropdown>
      <div class="title-wrapper" dropdownToggle (click)="false">
        {{ 'components.incidents.title' | translate }}
        <svg-icon class="dropdown-icon" src="assets/icons/down-arrow.svg"></svg-icon>
      </div>
      <ul class="dropdown-menu" *dropdownMenu role="menu">
        <li class="dropdown-item">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/incidents']">
            <span *ngIf="auth.hasGlobalRight('questionnaire-account-report') || auth.hasGlobalRight('questionnaire-detailed-report')" translate>components.incidents.title</span>
            <span *ngIf="!auth.hasGlobalRight('questionnaire-account-report') && !auth.hasGlobalRight('questionnaire-detailed-report')" translate>components.incidents.title_operator</span>
          </a>
        </li>
        <li class="dropdown-item active">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/scenario-statistics']">
            <span translate>components.scenario_statistics.title</span>
          </a>
        </li>
        <li class="dropdown-item" *ngIf="auth.hasGlobalRight('questionnaire-manage-scenarios')">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/scenario-editor']">
            <span translate>components.scenario_editor.title</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="nav-list list-unstyled list-inline">
      <li class="list-inline-item">
        <a class="btn btn-link" [routerLink]="['/incidents/incidents']">
          <span *ngIf="auth.hasGlobalRight('questionnaire-account-report') || auth.hasGlobalRight('questionnaire-detailed-report')" translate>components.incidents.title</span>
          <span *ngIf="!auth.hasGlobalRight('questionnaire-account-report') && !auth.hasGlobalRight('questionnaire-detailed-report')" translate>components.incidents.title_operator</span>
        </a>
      </li>
      <li class="list-inline-item active">
        <a class="btn btn-link" [routerLink]="['/incidents/scenariostatistics']">
          <span translate>components.scenario_statistics.title</span>
        </a>
      </li>
      <li class="list-inline-item" *ngIf="auth.hasGlobalRight('questionnaire-manage-scenarios')">
        <a class="btn btn-link" [routerLink]="['/incidents/scenario-editor']">
          <span translate>components.scenario_editor.title</span>
        </a>
      </li>
    </ul>

  </div>

  <hr class="transparent small">

  <div [formGroup]="formGroup" class="container">

    <div class="row">
      <!-- FROM DATEPICKER -->
      <div class="col-md-6">
        <div><label class="form-label" for="fromDate" translate>components.incidents.from_date_and_time</label></div>
        <div class="row">
          <div class="col-6 col-sm-5">
            <input type="text" bsDatepicker formControlName="fromDate" class="form-control" (bsValueChange)="onFromDateChange($event)">
          </div>
          <div class="col-6 col-sm-7">
            <timepicker id="dateOfOccurence" formControlName="fromTime" [showMeridian]="showMeridian" [showSpinners]="false"  (change)="loadFromFields()"></timepicker>
          </div>
        </div>
      </div>
      <!-- TO DATEPICKER -->
      <div class="col-md-6">
        <div><label class="form-label" for="toDate" translate>components.incidents.to_date_and_time</label></div>
        <div class="row">
          <div class="col-6 col-sm-5">
            <input type="text" bsDatepicker formControlName="toDate" class="form-control" (bsValueChange)="onToDateChange($event)">
          </div>
          <div class="col-6 col-sm-7">
            <timepicker id="dateOfOccurence" formControlName="toTime" [showMeridian]="showMeridian" [showSpinners]="false" (change)="loadFromFields()"></timepicker>
          </div>
        </div>
      </div>
    </div>
    <hr class="small d-md-none">
    <div class="row">
      <!-- SEARCH BAR -->
      <div class="col-sm-6">
        <input type="text" class="form-control"  formControlName="searchString" placeholder="{{ 'global.search' | translate }}"/>
      </div>
    </div>

  </div>
</div>

<div *ngIf="isLoading">
  <s2a-spinner></s2a-spinner>
</div>

<section *ngIf="!isLoading" class="container">
  <div *ngIf="!this.statistics || this.statistics.length === 0 || !this.statisticsAvailable()">
    <table class="table incidents-table">
      <tr>
        <td (click)="addScenario()">
          <div class="equipment-item">
            <div class="text-wrapper">
              <div translate class="info-text">components.scenario_statistics.no_statistics_available</div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</section>

<div class="container" *ngIf="!isLoading">

  <div *ngFor="let line of statistics">
    <section class="statisics-group" *ngIf="getLineIncidents(line) > 0">
      <header>
        <div class="row">
          <div class="col-sm">
            <h5>{{line.description}}</h5>
          </div>
          <div class="col-sm-12 col-md-auto">Incidents: {{getLineIncidents(line)}}</div>
        </div>
      </header>
      <div class="row">
        <div class="col-sm">
          <div *ngFor="let machine of line.children">
            <accordion>
              <accordion-group *ngIf="getEquipmentIncidents(machine) > 0">
                <button class="btn btn-link btn-block clearfix" accordion-heading>
                  <div class="pull-left float-left">
                    <div class="equipment-item">
                      <div class="icon">
                        <svg-icon [src]="getIconUrl(machine)"></svg-icon>
                      </div>
                      <div class="text-wrapper">
                        <p class="title">{{machine.description}}</p>
                        <p class="subline">{{getEquipmentIncidents(machine)}} Incident(s)</p>
                      </div>
                    </div>
                  </div>
                  <span class="float-right pull-right">
                    <svg-icon class="dropdown-icon" src="assets/icons/down-arrow.svg"></svg-icon>
                  </span>
                </button>
                <ul class="incidents-list">
                  <li class="list-item" *ngFor="let scenario of machine.sumary">
                    <div class="row">
                      <div class="col-sm">
                        <p>{{scenario.title}}</p>
                      </div>
                      <div class="col-sm-12 col-md-auto">{{scenario.numIncidents}}</div>
                    </div>
                  </li>
                </ul>
              </accordion-group>
            </accordion>
          </div>
        </div>
      </div>
    </section>
  </div>

</div>
