<div class="modal-header d-flex align-items-stretch">
  <h4 class="title-ellipsis">{{ file.data.fileName }}</h4>
  <span class="flex-grow-1"></span>
  <button type="button" class="title-button" (click)="download()" *ngIf="type !== 'image'" [hash]="random"
    [s2aHasRole]="['admin', 'maintenance-manager', 'production-manger', 'scenario-editor']">
    <span class="k-icon k-i-download" style="font-size: 20px;" title="{{ 'components.timeline.download' | translate }}"></span>
  </button>
  <button type="button" class="title-button" (click)="download()" *ngIf="type === 'image'">
    <span class="k-icon k-i-download" style="font-size: 20px;" title="{{ 'components.timeline.download' | translate }}"></span>
  </button>
  <button type="button" class="title-button ml-2 pr-0" aria-label="Close" (click)="modalRef.hide()">
    <svg-icon class="notification-icon" src="assets/icons/cancel.svg"></svg-icon>
  </button>
</div>
<div class="text-center mb-5" [ngSwitch]="type">

  <div class="d-flex align-items-center">
    <div class="btn-container d-none d-sm-inline-flex mr-2" *ngIf="previous">
      <a class="btn btn-primary btn-leaf btn-navigate" (click)="showPrevious()"><</a>
    </div>

    <div *ngSwitchCase="'image'" class="big-attachment" [ngStyle]="thumbnail()">
    </div>

    <div *ngSwitchCase="'video'">
      <video [src]="file.data.url" controls controlsList="nodownload"></video>
    </div>

    <div *ngSwitchCase="'pdf'" class="big-attachment d-flex align-items-stretch">
      <iframe class="flex-grow-1" [src]="iframeSrc"></iframe>
    </div>

    <div *ngSwitchCase="'generic'">
      <div class="generic-icon" [ngStyle]="thumbnail()"></div>
      <div translate>components.timeline.no_preview</div>
    </div>

    <div class="btn-container d-none d-sm-inline-flex ml-2">
      <a class="btn btn-primary btn-leaf btn-navigate" (click)="showNext()" *ngIf="next">></a>
    </div>
  </div>
</div>
<!--<div class="d-flex justify-content-end">-->
  <!--<button class="btn btn-leaf btn-primary" (click)="download()" translate>components.timeline.download</button>-->
<!--</div>-->
