<div class="performance-value-container">
  <div class="row">
    <div class="col-auto">
      <div class="icon">
        <img [src]="icon">
      </div>
    </div>
    <div class="col d-flex align-items-center">
      <div>
        <div class="title">
          {{ title }}
        </div>
        <div class="value">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</div>