import { Component, Input, OnInit } from '@angular/core';

import { Shift } from '@s2a/ng-shifts';

import { LossDetailed } from '../../../../../model/loss-detailed';
import { GridHelper } from '../grid-helper';
import { MappedOpMode, MergedMappedOpMode } from '../../../../../model/mapped-op-mode';
import { Losses } from 'src/app/model/losses';

@Component({
  selector: 's2a-losses',
  templateUrl: './losses.component.html',
  styleUrls: ['./losses.component.scss']
})
export class LossesComponent implements OnInit {
  public losses = [];
  public gridHelper: GridHelper;
  public tooltipLosses: Losses = {};

  private _losses_detailed: LossDetailed[] = [];
  private _shift: Shift;
  private hourInMilliseconds = 60 * 60 * 1000;
  private shiftDuration;
  private _siteLocation: string;

  @Input() set siteLocation(value: string) {
    if (value === undefined) {
      return;
    }
    this._siteLocation = value;
  }

  @Input() set shift(value: Shift) {
    this._shift = value;
    this.gridHelper = new GridHelper(value, this._siteLocation);
  }

  @Input() set losses_detailed(value) {
    this._losses_detailed = value;
  }

  get shiftHours(): undefined[] {
    if (this._shift === undefined) {
      return [];
    }
    return Array(this._shift.duration + 1);
  }

  constructor() {
  }

  ngOnInit(): void {
    this.shiftDuration = this.getShiftDuration();
    this.losses = this._losses_detailed;
    this.tooltipLosses = this._losses_detailed
      .map((lossDetailed: LossDetailed) => lossDetailed.mapped_op_mode)
      .reduce((tooltipLosses: Losses, mappedOpMode: string) => {
        tooltipLosses[mappedOpMode] = 0;
        return tooltipLosses;
      }, {});
  }

  public getLossWidth(loss): string {
    const faultDuration = (loss.end - loss.start);
    return `${faultDuration / this.shiftDuration * 100}%`;
  }

  public getLossOffset(loss): string {
    return `${((loss.start - this._shift.from) / this.shiftDuration) * 100}%`;
  }

  public getLossColorClass(loss: LossDetailed): string {
    const possibleFaults = [
      MappedOpMode.ORG_FAULT,
      MappedOpMode.LACK,
      MappedOpMode.TAILBACK,
      MappedOpMode.TECH_FAULT,
      MappedOpMode.PREPARED,
      MergedMappedOpMode.PRODUCTIVE
    ];
    if (possibleFaults.includes(loss.mapped_op_mode)) {
      return loss.mapped_op_mode;
    }
    return 'non_productive';
  }

  private getShiftDuration(): number {
    if (this._shift === undefined) {
      return 0;
    }
    return this._shift.duration * this.hourInMilliseconds;
  }
}
