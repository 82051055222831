import { merge, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';

import { Equipment, MachineEquipment } from '@s2a/ng-equipment';

import { CreatedIncidentResult } from '../../../../model/created-incident-data';
import { LineKpis } from '../../../../model/line-kpis';
import { MachineData, MachineDataResult } from '../../../../model/machine-data';
import { MachineHistory } from '../../../../model/machine-history';
import { PerformanceService } from '../../../../services/performance.service';

@Component({
  selector: 's2a-line-dashboard',
  templateUrl: './line-dashboard.component.html',
  styleUrls: ['./line-dashboard.component.scss']
})
export class LineDashboardComponent implements OnInit, OnDestroy {
  public lineIdFromRoute$: Observable<string>;
  public lineIdFromDropdown$: Subject<string>;
  public lineId$: Observable<string>;
  public isLoadingMachines: boolean;
  public lineKpis: LineKpis;
  public machines$: Observable<MachineEquipment[]>;
  public leadMachine$: Observable<MachineEquipment>;
  public machineData: { [machineId: string]: MachineDataResult };
  public machineCreatedIncidents: { [machineId: string]: CreatedIncidentResult[] };
  public leadMachineHistory$: Observable<MachineHistory>;
  public isLoadingLeadMachineHistory = true;
  public timezone: string;
  public isLoadingTimezone = true;
  public errorMessageKey: string;
  public lines: [string, string][];

  private liveMachineDataSubscription: Subscription;
  private liveCreatedIncidentResultSubscription: Subscription;

  constructor(
    private performanceService: PerformanceService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.lineIdFromRoute$ = this.activatedRoute.params.pipe(
      filter((params: Params) => 'lineId' in params),
      map((params: Params) => params['lineId'])
    );
    this.lineIdFromDropdown$ = new Subject<string>();
    this.lineId$ = merge(
      this.lineIdFromRoute$,
      this.lineIdFromDropdown$
    )
      .pipe(
        shareReplay(1)
      );

    this.machines$ = this.lineId$.pipe(
      tap(() => {
        this.isLoadingMachines = true;
        this.machineData = {};
        this.machineCreatedIncidents = {};
      }),
      switchMap((lineId: string) => this.performanceService.getMachines(lineId)),
      tap(() => {
        this.isLoadingMachines = false;
      }),
      shareReplay(1)
    );

    this.leadMachine$ = this.machines$.pipe(
      map((machines: MachineEquipment[]) => {
        const leadingMachines: MachineEquipment[] = machines.filter((machine: MachineEquipment) =>
          machine.properties.filter((property: object) =>
            (property['propertyName'] === 'leadMachine' && property['propertyValue'] === true)
          ).length > 0
        );
        return leadingMachines.shift();
      }),
      shareReplay(1)
    );

    const noLeadMachineHistory$: Observable<MachineHistory> = this.leadMachine$.pipe(
      filter((leadMachine: MachineEquipment) => !leadMachine),
      tap(() => {
        this.errorMessageKey = 'no_lead_machine';
      }),
      map(() => null)
    );

    const leadMachineHistoryLoaded$: Observable<MachineHistory> = this.leadMachine$.pipe(
      filter((leadMachine: MachineEquipment) => !!leadMachine),
      tap(() => {
        this.isLoadingLeadMachineHistory = true;
        this.errorMessageKey = null;
      }),
      switchMap((leadMachine: MachineEquipment) => this.performanceService.getMachineHistoryInInterval$(leadMachine.equipmentId)),
      tap(() => {
        this.isLoadingLeadMachineHistory = false;
      }),
      shareReplay(1)
    );

    this.leadMachineHistory$ = merge(
      noLeadMachineHistory$,
      leadMachineHistoryLoaded$
    );

    const timezone$: Observable<string> = this.lineId$.pipe(
      tap(() => {
        this.isLoadingTimezone = true;
      }),
      switchMap((lineId: string) => this.performanceService.getTimezone(lineId)),
      tap(() => {
        this.isLoadingTimezone = false;
      }),
      shareReplay(1)
    );

    timezone$.subscribe((timezone: string) => {
      this.timezone = timezone;
    });

    this.machineData = {};
    this.machineCreatedIncidents = {};
  }

  ngOnInit(): void {
    this.lineId$
      .subscribe((lineId: string) => {
        this.lineKpis = null;

        this.unsubscribeLiveData();
        this.performanceService.mqttConnect(lineId);
        this.getLiveMachineData(lineId);
        this.getLiveCreatedIncidentData(lineId);
      });

    this.lineIdFromDropdown$
      .subscribe((lineId: string) => this.location.go('/performance/line/' + lineId));


    this.performanceService.getLines()
      .subscribe((equipments: Equipment[]) => {
        this.lines = equipments.map( (equipment: Equipment) => <[string, string]> [equipment.equipmentId, equipment.description] );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeLiveData();
  }

  private unsubscribeLiveData(): void {
    if (this.liveMachineDataSubscription) {
      this.liveMachineDataSubscription.unsubscribe();
    }

    if (this.liveCreatedIncidentResultSubscription) {
      this.liveCreatedIncidentResultSubscription.unsubscribe();
    }
  }

  getLiveMachineData(lineId: string): void {
    if (this.liveMachineDataSubscription) {
      this.liveMachineDataSubscription.unsubscribe();
    }

    const liveMachineData$: Observable<MachineData> = this.performanceService.getLiveMachineData$(lineId);
    this.liveMachineDataSubscription = liveMachineData$.pipe(
      withLatestFrom(this.leadMachine$)
    )
      .subscribe(([machineData, leadMachine]: [MachineData, MachineEquipment]) => {
        const machineId: string = machineData.task.machine_id;
        this.machineData[machineId] = machineData.result;
        if (machineId === leadMachine.equipmentId) {
          this._setLineKpis(machineData.result);
        }
      });
  }

  getLiveCreatedIncidentData(lineId: string): void {
    if (this.liveCreatedIncidentResultSubscription) {
      this.liveCreatedIncidentResultSubscription.unsubscribe();
    }

    const liveCreatedIncidentResult$: Observable<CreatedIncidentResult> = this.performanceService.getLiveCreatedIncidentResultData$(lineId);
    this.liveCreatedIncidentResultSubscription = liveCreatedIncidentResult$.subscribe((createdIncidentResult: CreatedIncidentResult) => {
      console.log(createdIncidentResult);
      const machineId: string = createdIncidentResult.machine_id;
      if (!(machineId in this.machineCreatedIncidents)) {
        this.machineCreatedIncidents[machineId] = [];
      }
      this.machineCreatedIncidents[machineId].push(createdIncidentResult);
    });
  }

  getMachineStatus(machine: MachineEquipment): string {
    if (machine.equipmentId in this.machineData) {
      const machineDataResult: MachineDataResult = this.machineData[machine.equipmentId];
      const machineMode = machineDataResult.mapped_op_mode || machineDataResult.op_mode;
      if (machineMode) {
        return machineMode;
      }
      const externalFaultStates = [8, 16, 24, 2048];
      const productionState = 128;
      if (machineDataResult.machine_state === productionState) {
        return 'running';
      } else {
        if (externalFaultStates.includes(machineDataResult.machine_state)) {
          return 'external-fault';
        } else {
          return 'internal-fault';
        }
      }
    } else {
      return 'unknown';
    }
  }

  getMachineStatusStartTime(machine: MachineEquipment): number {
    if (machine.equipmentId in this.machineData) {
      const machineDataResult: MachineDataResult = this.machineData[machine.equipmentId];
      return machineDataResult.machine_state_time_stamp;
    } else {
      return -1;
    }
  }

  onMachineIncidentClicked(incident: CreatedIncidentResult): void {
    if (incident.machine_id in this.machineCreatedIncidents) {
      this.machineCreatedIncidents[incident.machine_id] = this.machineCreatedIncidents[incident.machine_id]
                                                            .filter((obj) => obj !== incident);
    }
  }

  private _setLineKpis(machineDataResult: MachineDataResult): void {
    const downtimeDuration = Object.keys(machineDataResult.machine_state_time_distribution)
      .filter((state: string) => state !== '128')
      .map((state: string) => machineDataResult.machine_state_time_distribution[state])
      .reduce((sum: number, current: number) => sum + current, 0);
    this.lineKpis = {
      unitsProduced: machineDataResult.units_produced,
      hectoliterProduced: 0,
      downtimeDuration: downtimeDuration
    };
  }
}
