import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Logger } from '@s2a/ng-common';
const logger = Logger('app');

if (environment.production) {
    enableProdMode();
}

logger.info(`S2A application started`);

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
