<div class="kpi-container">
  <!--
  <div kendoTooltip [tooltipTemplate]="tooltip" position="bottom" class="info-icon">
    <svg-icon  title="" src="assets/icons/icon-info.svg"></svg-icon>
  </div>
  -->
  <kendo-chart class="pb-2 donut-chart" [chartArea]="{background: 'none'}">
    <ng-template kendoChartDonutCenterTemplate>
      <div class="text-center pt-2 percentage-value">
        {{percentAvailability | number:'1.0-1'}}
      </div>
      <div class="percentage-unit">
        %
      </div>
    </ng-template>
    <kendo-chart-series>
      <kendo-chart-series-item type="donut" [data]="availabilityChartData" field="value" categoryField="kind">
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>
  <div *ngFor="let title of titles" class="col-12 mx-auto text-center kpi-title">
    {{ title }}
  </div>
  <div class="col-12 mx-auto text-center kpi-subtitle">
    {{ subtitle }}
  </div>
</div>