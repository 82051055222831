import * as moment from 'moment';
import { combineLatest, EMPTY, from, Observable, Subscription } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { AuthService } from '@s2a/ng-auth';
import { Shift, ShiftModelResponseObject, ShiftService } from '@s2a/ng-shifts';

import { Mpu } from 'src/app/model/mpu';
import { ProductionSite } from 'src/app/model/production-site';
import { SiteService } from 'src/app/services/site.service';
import { EquipmentInsight } from '../../../../model/equipment-insight';
import { MpuKpiService } from '../../../../services/mpu-kpi.service';

const initialKpiValues: EquipmentInsight = {
  eq_id: '',
  kpis: {
    tech_availability: 0,
    machine_availability: 0,
    operator_interventions: 0,
    mtbf: 0,
    mttr: 0
  },
  losses: {
    lack: 0,
    tech_fault: 0,
    org_fault: 0,
    non_productive: 0,
    tailback: 0,
    oee: 0
  },
  losses_detailed: [],
  productTypeIntervals: [],
  speedIntervals: []
};

@Component({
  selector: 's2a-mpu-report',
  templateUrl: './mpu-report.component.html',
  styleUrls: ['./mpu-report.component.scss']
})
export class MpuReportComponent implements OnInit, OnDestroy {

  private lineId$: Observable<string>;
  private machineId$: Observable<string>;
  private date$: Observable<string>;
  private shiftTime$: Observable<string>;
  private shift$: Observable<Shift>;

  lineName = 'undefined';
  siteLocation = 'Etc/UTC';
  mpuInfo = '';
  isLoading: boolean;
  kpiValues = initialKpiValues;
  availability_chart_data: any[] = [];

  machineId: string;

  shift: Shift;

  public rightsSubscription: Subscription;
  public showAdditionalReport: boolean;

  get tech_availability(): number {
    if (this.kpiValues === undefined) {
      return undefined;
    }
    return this.kpiValues.kpis.tech_availability;
  }

  get machine_availability(): number {
    if (this.kpiValues === undefined) {
      return undefined;
    }
    return this.kpiValues.kpis.machine_availability;
  }

  get operator_interventions(): number {
    if (this.kpiValues === undefined) {
      return undefined;
    }
    return this.kpiValues.kpis.operator_interventions;
  }

  get mtbf(): string {
    if (this.kpiValues === undefined) {
      return 'error';
    }
    return this.millisecondsToTimeString(this.kpiValues.kpis.mtbf);
  }

  get mttr(): string {
    if (this.kpiValues === undefined) {
      return 'error';
    }
    return this.millisecondsToTimeString(this.kpiValues.kpis.mttr);
  }

  get tech_fault(): number {
    if (this.kpiValues === undefined) {
      return undefined;
    }
    return this.kpiValues.losses.tech_fault;
  }

  get org_fault(): number {
    if (this.kpiValues === undefined) {
      return undefined;
    }
    return this.kpiValues.losses.org_fault;
  }

  get org_fault_mappings(): {mapped_org_faults: string} {
    if (this.kpiValues === undefined || this.kpiValues.op_mappings === undefined) {
      return { mapped_org_faults: '---' };
    }
    return { mapped_org_faults: this.kpiValues.op_mappings.org_fault.toString() };
  }

  get tech_fault_mappings(): {mapped_tech_faults: string} {
    if (this.kpiValues === undefined || this.kpiValues.op_mappings === undefined) {
      return { mapped_tech_faults: '---' };
    }
    return { mapped_tech_faults: this.kpiValues.op_mappings.tech_fault.toString() };
  }

  get availability_widget_param(): {tech_fault: number, org_fault: number, tech_availability?: number} {
    if (this.kpiValues === undefined) {
      return {
        tech_fault: -1,
        org_fault: -1,
        tech_availability: -1,
      };
    }

    return {
      tech_fault: parseFloat((this.tech_fault * 100).toFixed(1)),
      org_fault: parseFloat((this.org_fault * 100).toFixed(1)),
      tech_availability: null
    };
  }

  constructor(
    private route: ActivatedRoute,
    private kpiService: MpuKpiService,
    private siteService: SiteService,
    private shiftService: ShiftService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.lineId$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('lineId'))
    );
    this.machineId$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('machineId'))
    );
    this.date$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('date'))
    );
    this.shiftTime$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('shift'))
    );

    this.shift$ = this.prepareShiftObservable();
    this.shift$.subscribe(shift => this.shift = shift);

    this.loadSite();
    this.loadKpiValues();

    this.machineId$.subscribe((machineId: string) => {
      this.machineId = machineId;
    });

    this.rightsSubscription = this.authService.hasGlobalRights$.subscribe(rights => {
      this.showAdditionalReport = this.authService.hasGlobalRight('insight-additional-report', rights);
    });
  }

  ngOnDestroy(): void {
    if (this.rightsSubscription) {
      this.rightsSubscription.unsubscribe();
    }
  }

  /**
   * Retrieves the current shift model from the shiftservice and converts it to a specific shift.
   * Fast consecutive results from the shiftservice will be discarded and only the last value will
   * be evaluated. This method caches the result so that multiple subscriptions don't call the API
   * multiple times.
   */
  private prepareShiftObservable(): Observable<Shift> {
    const shiftModel$: Observable<ShiftModelResponseObject> = this.lineId$.pipe(
      switchMap(lineId => this.shiftService.getShiftModel(lineId))
    );
    return combineLatest(
        shiftModel$,
        this.date$,
        this.shiftTime$
    )
      .pipe(
        map(([shiftModel, date, shiftTime]: [ShiftModelResponseObject, string, string]) => {
          return <Shift>{
            from: moment(date + ' ' + shiftTime, 'YYYY-MM-DD HH:mm').utc(true).unix() * 1000,
            duration: shiftModel.duration,
            name: shiftModel.shifts.find(shiftElement => shiftElement.time === shiftTime).name
          };
        }),
        shareReplay(1)
      );
  }

  /**
   * loads all production site and machine relevant data. Fast consecutive results from the
   * siteservice will be discarded and only the last value will be evaluated.
   */
  private loadSite(): void {
    const site$: Observable<ProductionSite> = this.lineId$.pipe(
      switchMap((lineId: string) => this.siteService.getSite(lineId))
    );

    combineLatest(
      site$,
      this.machineId$
    )
      .subscribe(([site, machineId]: [ProductionSite, string]) => {
        this.lineName = site.lineName;
        this.siteLocation = site.location;

        const mpu = this.findMpu(site, machineId);
        const representative = mpu.equipments.find(eq => eq.equipmentId === mpu.representativeId);
        this.mpuInfo = `${representative.type} | ${representative.serialNumber}`;
      });
  }

  /**
   * loads kpi values. Fast consecutive results from the equipmentservice will be discarded and
   * only the last value will be evaluated.
   */
  private loadKpiValues(): void {
    combineLatest(
      this.machineId$,
      this.shift$
    )
      .pipe(
        switchMap(([mpuId, shift]: [string, Shift]) => this.loadData(mpuId, shift.from))
      )
      .subscribe((kpiValues: EquipmentInsight) => {
        this.kpiValues = kpiValues;
        this.availability_chart_data = [
          { kind: 'machine_availability', value: this.machine_availability },
          { kind: 'organisational_fault', value: this.kpiValues.losses.org_fault },
          { kind: 'technical_fault', value: this.kpiValues.losses.tech_fault }
        ];

        this.isLoading = false;
      });
  }

  private findMpu(site: ProductionSite, representativeId: string): Mpu {
    return [site.filler]
      .concat(site.containerStream, site.finalStream, site.productStream)
      .find(mpu => mpu.representativeId === representativeId);
  }

  private millisecondsToTimeString(value: number): string {
    const secondsDivisor = 1000;
    const minutesDivisor = secondsDivisor * 60;
    const hoursDivisor = minutesDivisor * 60;

    const seconds = Math.floor((value / secondsDivisor) % 60);
    const minutes = Math.floor(((value / minutesDivisor) % 60));
    const hours = Math.floor(((value / hoursDivisor) % 24));

    return `${this.getValueWithLeadingZero(hours)}:${this.getValueWithLeadingZero(minutes)}:${this.getValueWithLeadingZero(seconds)}`;
  }

  private getValueWithLeadingZero(value: number): string {
    return `0${value}`.slice(-2);
  }

  private loadData(mpuId: string, shiftFrom: number): Observable<EquipmentInsight> {
    if (shiftFrom === undefined || mpuId === undefined) {
      return EMPTY;
    }
    const skipLossesDetailed = false;
    const includeSpeeds = true;
    return from(this.kpiService.getEquipmentPerformanceIndicators(mpuId, shiftFrom, skipLossesDetailed, includeSpeeds));
  }

  showChart(): boolean {
    if ((this.kpiValues.speedIntervals === null || this.kpiValues.speedIntervals === undefined)
      && (this.kpiValues.productTypeIntervals === null || this.kpiValues.productTypeIntervals === undefined)) {
      return false;
    }
    return true;
  }

}
