import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BasePageComponent } from '@s2a/ng-common';
import { Equipment, IncidentEquipment } from '@s2a/ng-equipment';
import { AuthService } from '@s2a/ng-auth';
import {
  IncidentsService, Scenario, Incident, ScenarioState, CreateIncident, IncidentCategory, IncidentStatisticEquipment
} from '@s2a/ng-incidents';

enum ComponentState {
  loadingData,
  chooseLine,
  chooseMachine,
  chooseComponent,
  chooseScenario,
  chooseSubScenario,
  createIncident,
  thankYou,
  chooseCategory
}

enum ProblemButton {
  problemNotInList,
  iDontKnow
}
@Component({
  selector: 's2a-incident-reporter',
  templateUrl: './incident-reporter.component.html',
  styleUrls: ['./incident-reporter.component.scss']
})
export class IncidentReporterComponent extends BasePageComponent implements OnInit {
  public ComponentState = ComponentState;
  public ProblemButton = ProblemButton;
  public state: ComponentState = ComponentState.loadingData;
  public lines: Equipment[] = [];
  public selectedLine: Equipment;
  public machines: IncidentEquipment[] = [];
  public selectedMachine: IncidentEquipment;
  public components: IncidentEquipment[] = [];
  public selectedComponent: IncidentEquipment;
  public scenarios: Scenario[] = [];
  public selectedScenario: Scenario;
  public finalScenario: Scenario;
  public btnState?: ProblemButton = null;
  public originalIncidentId: string = null;
  public originalIncident: Incident = null;
  public originalEquipment: IncidentEquipment = null;
  public categories: IncidentCategory[] = null;

  constructor(
    private incidentsService: IncidentsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params['incidentId']) {
        this.originalIncidentId = params['incidentId'] || null;
        this.loadOriginalIncident();
      } else {
        this.loadLines();
      }
    });
  }

  public stepBack(): void {
    if (this.state === ComponentState.chooseMachine) {
      this.machines = [];
      this.selectedLine = null;
      this.btnState = null;
      this.state = ComponentState.chooseLine;
    } else if (this.state === ComponentState.chooseComponent) {
      this.components = [];
      this.selectedMachine = null;
      this.btnState = null;
      if (this.originalIncident) {
        this.state = ComponentState.chooseCategory;
      } else {
        this.state = ComponentState.chooseMachine;
      }
    } else if (this.state === ComponentState.chooseScenario) {
      this.scenarios = [];
      this.selectedComponent = null;
      this.btnState = null;
      this.state = ComponentState.chooseComponent;
      if (this.components.length === 0) {
        this.stepBack();
      }
    } else if (this.state === ComponentState.chooseSubScenario) {
      this.selectedScenario = null;
      this.btnState = null;
      this.state = ComponentState.chooseScenario;
    } else if (this.state === ComponentState.createIncident) {
      this.selectedScenario = null;
      this.finalScenario = null;
      this.btnState = null;
      this.state = ComponentState.chooseScenario;
    }
  }

  private loadOriginalIncident(): void {
    (async () => {
      this.state = ComponentState.loadingData;
      this.originalIncident = await this.incidentsService.getIncident(this.originalIncidentId);
      if (this.originalIncident) {
        this.originalEquipment = await this.incidentsService.getEquipment(this.originalIncident.equipmentId);
        this.categories = await this.incidentsService.getIncidentCategories();
        console.log(this.originalIncident);
        console.log(this.originalEquipment);
        console.log(this.categories);
        this.state = ComponentState.chooseCategory;
      }
    })();
  }

  public chooseCategory(cat: IncidentCategory): void {
    if (cat.isIncident) {
      this.originalIncident.category = cat.categoryId;
      this.loadComponents(this.originalEquipment);
    } else {
      this.originalIncident.category = cat.categoryId;
      this.saveCategoryIncident();
    }
  }

  public cancelCategorize(): void {
    this.router.navigate(['/incidents/reporter']);
  }

  public getIconUrl(machine: IncidentStatisticEquipment): string {
    if (machine.iconUrl && machine.iconUrl !== null) {
      return machine.iconUrl;
    } else {
      return 'assets/apps/equipments/eq-' + machine.level.toLowerCase() + '.svg';
    }
  }

  private loadLines(): void {
    (async () => {
      this.selectedLine = null;
      this.isLoading = true;
      this.state = ComponentState.loadingData;
      this.lines = await this.incidentsService.getOperatorLines();
      this.machines = [];
      this.state = ComponentState.chooseLine;
      this.isLoading = false;
    })();
  }

  public loadMachines(line: Equipment): void {
    (async () => {
      this.selectedLine = line;
      this.isLoading = true;
      this.state = ComponentState.loadingData;
      this.machines = await this.incidentsService.getOperatorMachines(line.equipmentId);
      this.state = ComponentState.chooseMachine;
      this.isLoading = false;
    })();
  }

  public loadComponents(machine: IncidentEquipment): void {
    (async () => {
      this.selectedMachine = machine;
      this.isLoading = true;
      this.state = ComponentState.loadingData;
      this.components = await this.incidentsService.getOperatorComponents(machine.equipmentId);
      if (this.components.length === 0) {
        this.loadScenarios();
      } else {
        this.state = ComponentState.chooseComponent;
      }
      this.isLoading = false;
    })();
  }

  private getEquipmentTypeProperty(eq?: IncidentEquipment): string | null {
    /*let eqType: string = null;
    console.log(eq);
    if (eq && eq.properties) {
      for (const prop of eq.properties) {
        if (prop.propertyName === 'type') {
          eqType = prop.propertyValue;
        }
      }
    }
    return eqType;*/
    return (eq) ? eq.type : null;
  }

  public loadScenarios(component?: IncidentEquipment): void {
    (async () => {
      this.selectedComponent = component;
      this.isLoading = true;
      this.state = ComponentState.loadingData;
      this.scenarios = await this.incidentsService.getMatchingScenarios(
        this.getEquipmentTypeProperty(this.selectedMachine),
        this.getEquipmentTypeProperty(this.selectedComponent)
      );
      this.state = ComponentState.chooseScenario;
      this.isLoading = false;
    })();
  }

  public selectScenario(scenario: Scenario): void {
    (async () => {
      this.selectedScenario = scenario;
      if (this.selectedScenario.children && this.selectedScenario.children.length > 0) {
        this.selectedScenario = scenario;
        this.isLoading = true;
        this.state = ComponentState.chooseSubScenario;
        this.isLoading = false;
      } else {
        this.createIncident(scenario);
      }
    })();
  }

  public createIncident(scenario: Scenario, btn?: ProblemButton): void {
    (async () => {
      if (!scenario) {
        // this.saveIncident(null);
        this.finalScenario = null;
        this.btnState = btn;
        this.state = ComponentState.createIncident;
      } else {
        this.finalScenario = scenario;
        this.state = ComponentState.createIncident;
      }
    })();
  }

  public saveCategoryIncident(): void {
    (async () => {
      // this.originalIncident.status = 'pending';
      this.state = ComponentState.loadingData;
      const result = await this.incidentsService.updateIncident(this.originalIncident);
      this.state = ComponentState.thankYou;
      setTimeout(() => {
        this.router.navigate(['/incidents/reporter']);
      }, 4000);
    })();
  }

  public saveIncident(scenario?: Scenario, solved?: boolean): void {
    (async () => {
      console.log(this.finalScenario);
      console.log(solved);
      this.state = ComponentState.loadingData;

      let scenarioId: string = null;
      if (this.finalScenario) {
        scenarioId = this.finalScenario.scenarioId;
      } else {
        if (this.selectedScenario) {
          scenarioId = this.selectedScenario.scenarioId;
        }
      }
      let scenarioStatus: ScenarioState = 'available';
      if (this.finalScenario) {
        scenarioStatus = 'available';
      } else {
        if (this.btnState === ProblemButton.iDontKnow) {
          scenarioStatus = 'unknown';
        } else if (this.btnState === ProblemButton.problemNotInList) {
          scenarioStatus = 'unavailable';
        }
      }

      if (!this.originalIncident) {
        const incident: CreateIncident = {
          status: (solved) ? 'solved' : 'pending',
          dateOfOccurence: new Date().toISOString(),
          equipmentId: (this.selectedComponent) ? this.selectedComponent.equipmentId : this.selectedMachine.equipmentId,
          scenarioId,
          scenarioStatus
        };

        const result = await this.incidentsService.createIncident(incident);

        this.state = ComponentState.thankYou;

        setTimeout(() => {
          this.machines = [];
          this.selectedLine = null;
          this.components = [];
          this.selectedMachine = null;
          this.scenarios = [];
          this.selectedComponent = null;
          this.selectedScenario = null;
          this.finalScenario = null;
          this.btnState = null;
          this.state = ComponentState.chooseLine;
          this.originalEquipment = null;
          this.originalIncident = null;
          this.originalIncidentId = null;
          this.categories = null;
          this.loadLines();
        }, 4000);
      } else {
        this.originalIncident.status = (solved) ? 'solved' : 'pending';
        this.originalIncident.equipmentId = (this.selectedComponent) ?
          this.selectedComponent.equipmentId : this.selectedMachine.equipmentId;
        this.originalIncident.scenarioId = scenarioId;
        this.originalIncident.scenarioStatus = scenarioStatus;
        // this.originalIncident['scenario_id'] = scenarioId;
        const result = await this.incidentsService.updateIncident(this.originalIncident);
        this.state = ComponentState.thankYou;
        setTimeout(() => {
          this.router.navigate(['/incidents/reporter']);
        }, 4000);
      }
    })();
  }
}
