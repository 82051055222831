import * as moment from 'moment-timezone';

import { Shift } from '@s2a/ng-shifts';

export class GridHelper {

    constructor(private readonly _shift: Shift, private readonly _siteLocation: string) {
    }

    public getHourLabelOffset(index: number): string {
        if (index === 0) {
            return '0';
        }
        if (index === this._shift.duration) {
            return '95%';
        }
        const offset = (index / this._shift.duration) - 0.02;
        return `${offset * 100}%`;
    }

    public getHourOffset(index: number): string {
        return `${(index / this._shift.duration) * 100}%`;
    }

    public getHourLabel(j: number): string {
        const shiftBegin = moment.unix(this._shift.from / 1000).utc().add(j, 'hours').tz(this._siteLocation);
        const i = parseInt(shiftBegin.format('HH'), 10);
        if (i >= 10) {
            return `${i}:00`;
        }
        return `0${i}:00`;
    }
}
