import * as moment from 'moment-timezone';

import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { Shift, ShiftModel, ShiftSlot } from '@s2a/ng-shifts';

@Component({
  selector: 's2a-shift-selection',
  templateUrl: './shift-selection.component.html',
  styleUrls: ['./shift-selection.component.scss']
})
export class ShiftSelectionComponent implements OnInit {

  private _shift: Shift;
  private _timezone: string;

  @Input() set shift(shift: Shift) {
    this._shift = shift;
    this.updateShift();
  }

  @Input() set timezone(timezone: string) {
    this._timezone = timezone;
    this.updateShift();
  }
  @Input() shiftModel: ShiftModel;

  @Output() shiftChanged = new EventEmitter<Shift>();

  maxDate: Date = new Date();
  selectedDate: Date;
  selectedShiftSlot: ShiftSlot;
  selectedFrom: number;

  constructor() { }

  ngOnInit(): void { }

  updateShift(): void {
    if (this._timezone === undefined || this._shift === undefined) {
      return;
    }
    if (this._shift.from === this.selectedFrom) {
      return;
    }
    this.selectedShiftSlot = {
      name: this._shift.name,
      time: moment(this._shift.from).utc().format('HH:mm')
    };
    const shiftFrom = moment(this._shift.from).tz(this._timezone);
    this.selectedDate = new Date(Date.UTC(shiftFrom.year(), shiftFrom.month(), shiftFrom.date()));
    this.selectedFrom = this._shift.from;
  }

  getSlotDisplayText(slot: ShiftSlot): string {
    if (this.shiftModel == null || slot === undefined) {
      return '---';
    }
    const timeSegments = slot.time.split(':');
    const hour = parseInt(timeSegments[0], 10);
    const minute = parseInt(timeSegments[1], 10);

    const start = moment().utc().hour(hour).minute(minute).second(0).millisecond(0).tz(this._timezone);
    const end = moment(start).add(this.shiftModel.duration, 'h');
    return `${slot.name} -- ${start.format('h:mm a')} - ${end.format('h:mm a')}`;
  }

  public onDateChange(date: Date): void {
    if (date.getTime() === this.selectedDate.getTime()) {
      return;
    }
    this.selectedDate = date;
    if (this.selectedShiftSlot !== undefined && this.shiftModel !== undefined) {
      this.selectedSlotChanged(this.selectedShiftSlot);
    }
  }

  public selectedSlotChanged(value: ShiftSlot): void {
    this.selectedShiftSlot = value;
    const localBeginTime = moment.utc(value.time, 'HH:mm').tz(this._timezone);
    const shiftBegin = moment()
      .tz(this._timezone)
      .year(this.selectedDate.getFullYear())
      .month(this.selectedDate.getMonth())
      .date(this.selectedDate.getDate())
      .hour(localBeginTime.hour())
      .minute(localBeginTime.minute())
      .seconds(0)
      .milliseconds(0);

    this.emitShift(shiftBegin.unix());
  }

  private emitShift(dateUtc: number): void {
    if (dateUtc !== this.selectedFrom) {
      this.selectedFrom = dateUtc * 1000;
      this.shiftChanged.emit({
        name: this.selectedShiftSlot.name,
        from: dateUtc * 1000,
        duration: this.shiftModel.duration
      });
    }
  }
}
