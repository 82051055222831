import { Component, Input, OnInit } from '@angular/core';

import { Theme, ThemeService } from '@s2a/ng-common';
import { Shift } from '@s2a/ng-shifts';
import {
  compareProductTypeIntervals, getDuration, ProductTypeInterval,
  Sorts, SortService
} from '@s2a/ng-sorts';

import { SpeedInterval, compareSpeedIntervals } from './../../../../model/speed-interval';

import { GridHelper } from '../grid-helper';
import { KendoHelper } from '../kendo-helper';

@Component({
  selector: 's2a-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {

  public sortChanges: SortChange[] = [];
  public currentSpeeds = [];
  public gridHelper: GridHelper;
  public productIdsToSortNumber: { [productId: number]: number } = {};

  speedsAvailable: Boolean = true;
  productAvailable: Boolean = true;

  private _shift: Shift;
  private _speedIntervals;
  private _productTypeIntervals: ProductTypeInterval[];
  private _hourInMilliseconds = 60 * 60 * 1000;
  private _shiftDuration = 0;
  private _siteLocation: string;
  private _machineId: string;
  private _availableSortNames: Sorts = undefined;
  kendoHelper = new KendoHelper();

  sortNames: Map<string, string> = undefined;
  sortNumberToProductIds = [];

  @Input() set siteLocation(value: string) {
    if (value === undefined) {
      return;
    }
    this._siteLocation = value;
  }

  @Input() set machineId(value: string) {
    if (value === undefined) {
      return;
    }
    this._machineId = value;
  }

  get productIds(): string[] {
    return Object.keys(this.productIdsToSortNumber);
  }

  get usedSorts(): Map<string, string> {
    return this.sortNames;
  }

  get shift(): Shift {
    return this._shift;
  }

  get shiftDuration(): number {
    return this._shiftDuration;
  }

  get numberOfShiftDurationHours(): number {
    if (this._shift === undefined) {
      return 0;
    }
    return this._shift.duration;
  }

  getArrayOfSize(size: number): Array<void> {
    return new Array(size);
  }

  public getSortChangeLabel = (e: any): string => {
    const limitToSetBrakets = 15;
    const limitToShowLabel = 20;
    const ratio = this._shiftDuration / this.sortChanges[e.series.index].value;
    let label = '';
    if (ratio < limitToShowLabel) {
      if (this.sortNumberToProductIds !== undefined && this.sortNumberToProductIds.length > 0) {
        const sortNumber = this.sortNumberToProductIds.find(s => (s.sortNr === this.sortChanges[e.series.index].id));
        label = sortNumber.prodId;
      }
      if (ratio < limitToSetBrakets) {
        label = this.formatSortId(parseInt(label, 10));
      }
    }
    return label;
  }

  @Input() set shift(value: Shift) {
    if (value === undefined) {
      return;
    }
    this._shift = value;
    this.gridHelper = new GridHelper(value, this._siteLocation);
    this._shiftDuration = value.duration * this._hourInMilliseconds;
    this.calculateAll();
  }

  @Input() set productTypeIntervals(value: ProductTypeInterval[]) {
    if (value === undefined || value === null) {
      this.productAvailable = false;
      return;
    }
    this._productTypeIntervals = value.sort((a, b) => compareProductTypeIntervals(a, b));
    this.calculateAll();
  }

  @Input() set speedIntervals(value: SpeedInterval[]) {
    if (value === undefined || value === null) {
      this.speedsAvailable = false;
      return;
    }
    this._speedIntervals = value.sort((a, b) => compareSpeedIntervals(a, b));
    this.calculateAll();
  }

  constructor(private theme: ThemeService, private sortService: SortService) {
  }

  ngOnInit(): void {
    this.loadSortNames(this._machineId);
  }

  private calculateAll(): void {
    if (this._shift === undefined) {
      return;
    }

    if (this._speedIntervals !== undefined) {
      this.currentSpeeds = this.calculateCurrentSpeeds(this._speedIntervals);
    }

    if (this._productTypeIntervals !== undefined) {
      this.sortChanges = this.calculateSortChanges(this._productTypeIntervals);
    }
    this.mapToolTipText();
  }

  private calculateCurrentSpeeds(speedIntervals: SpeedInterval[]): Array<number> {
    const currentSpeeds = [];
    let lastValidSpeed = 0;
    for (let i = 0; i < speedIntervals.length; i++) {
      let currentSpeed = speedIntervals[i].current_speed;
      if (currentSpeed === undefined) {
        currentSpeed = lastValidSpeed;
      } else {
        lastValidSpeed = currentSpeed;
      }

      currentSpeed = currentSpeed / 1000;
      const startOfIntervalInShift = parseInt(speedIntervals[i].start, 10) - this._shift.from;

      currentSpeeds.push([startOfIntervalInShift, currentSpeed]);
      currentSpeeds.push([this.calculateEndOfInterval(i, speedIntervals), currentSpeed]);
    }

    return currentSpeeds;
  }

  private calculateEndOfInterval(i: number, speedIntervals: SpeedInterval[]): number {
    if (i + 1 < speedIntervals.length) {
      return parseInt(speedIntervals[i + 1].start, 10) - this._shift.from;
    }
    return this._shiftDuration;
  }

  private calculateSortChanges(productTypeIntervals: ProductTypeInterval[]): SortChange[] {
    const sortChanges: SortChange[] = [];
    if (this.hasIdleTimeAtShiftStart(productTypeIntervals)) {
      const idleDuration = parseInt(productTypeIntervals[0].start, 10) - this._shift.from;
      const idle = { value: idleDuration, opacity: 0, id: 0 };
      sortChanges.push(idle);
    }

    for (let i = 0; i < productTypeIntervals.length; i++) {
      const productTypeItem = productTypeIntervals[i];
      const nextProductTypeItem = productTypeIntervals[i + 1];
      const sortNumber = this.getSortNumber(productTypeItem);
      this.productIdsToSortNumber[productTypeItem.product_id] = sortNumber;
      sortChanges.push(this.calculateSortChange(nextProductTypeItem, productTypeItem, sortNumber));
    }

    if (this.productIdsToSortNumber !== undefined) {
      this.sortNumberToProductIds = Object.entries(this.productIdsToSortNumber).map(([prodId, sortNr]) => ({ sortNr, prodId }));
    }

    return sortChanges;
  }

  private calculateSortChange(nextProductTypeItem: ProductTypeInterval,
    productTypeItem: ProductTypeInterval,
    sortNumber: number): SortChange {
    if (nextProductTypeItem !== undefined) {
      return { value: getDuration(productTypeItem, nextProductTypeItem), opacity: 1, id: sortNumber };
    }
    return { value: (this._shift.from + this._shiftDuration - parseInt(productTypeItem.start, 10)), opacity: 1, id: sortNumber };
  }

  private getSortNumber(productTypeInterval: ProductTypeInterval): number {
    if (this.productIdsToSortNumber[productTypeInterval.product_id] === undefined) {
      return Object.keys(this.productIdsToSortNumber).length + 1;
    }
    return this.productIdsToSortNumber[productTypeInterval.product_id];
  }

  private hasIdleTimeAtShiftStart(productTypeInterval: ProductTypeInterval[]): boolean {
    return this.shift && this.shift.from !== parseInt(productTypeInterval[0].start, 10);
  }

  getAxisColor(): string {
    if (this.theme.theme === Theme.Light) {
      return '#74818e';
    }
    return '#e0e0e0';
  }

  formatSortId(value: number): string {
    let label: string;
    label = '[' + value + ']';
    while (label.length < 6) {
      label = label.substr(0, 1) + '0' + label.substr(1, label.length);
    }
    return label;
  }

  loadSortNames(machineId: string): void {
    this.sortService.getSortNames(machineId).subscribe(
      (data: Sorts) => {
        this._availableSortNames = data;
        this.mapToolTipText();
      },
      (errors: any) => {
        console.log('No sorts available!');
      }
    );
  }

  mapToolTipText(): void {
    if (!this._availableSortNames || !this._productTypeIntervals) {
      return;
    }
    this.sortNames = new Map();
    let currentProductId: number;
    for (let prodId = 0; prodId < this._productTypeIntervals.length; prodId++) {
      currentProductId = this._productTypeIntervals[prodId].product_id;
      this.sortNames.set(currentProductId.toString(), this.getNameForProductId(currentProductId));
    }
  }

  getNameForProductId(id: number): string {
    const sortNameLine = this._availableSortNames[id.toString()];
    if (sortNameLine === undefined || sortNameLine.length < 1) {
      return undefined;
    }
    return sortNameLine;
  }

}

interface SortChange {
  value: number;
  opacity: number;
  id: number;
}
