import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService, BsModalService } from 'ngx-bootstrap';

import { AuthService } from '@s2a/ng-auth';
import { BasePageComponent, OnDataChanged } from '@s2a/ng-common';
import { Incident, IncidentCategory, IncidentsService } from '@s2a/ng-incidents';

import { EditIncidentsComponent, OnDataChangedObject } from '../edit-incidents/edit-incidents.component';

@Component({
  selector: 's2a-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent extends BasePageComponent implements OnInit {
  public incidents: Incident[] = [];
  public formGroup: FormGroup;
  public searchControl: FormControl = new FormControl();
  public searchControlSubscription: Subscription;
  public showMeridian = false;
  public timeReadOnly = false;
  private categories: IncidentCategory[] = null;
  public page = 1;
  public maximumPages = 10;
  public pageSize = 10;

  @ViewChild('scrollSection') private myScrollContainer: ElementRef;

  constructor(
    private incidentsService: IncidentsService,
    public auth: AuthService,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private translate: TranslateService,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit(): void {
    const fromDate = new Date();
    fromDate.setHours(fromDate.getHours() - 1, fromDate.getMinutes(), fromDate.getSeconds());

    const toDate = new Date();

    this.formGroup = this.formBuilder.group({
      fromDate: [fromDate],
      fromTime: [fromDate],
      toDate: [toDate],
      toTime: [toDate],
      searchString: this.searchControl,
      useLast24Hours: []
    });

    this.searchControlSubscription = this.searchControl.valueChanges
      .debounceTime(500)
      .subscribe(searchString => {
        if (this.formGroup.controls.useLast24Hours.value === true) {
          this.onUse24HoursChange(true);
        } else {
          this.loadFromFields();
        }
      });

    this.translate.onLangChange.subscribe(() => {
      this.localeService.use(this.translate.instant('global.locale'));
      if (this.translate.instant('components.edit_incidents.meridian') === '12H') {
        this.showMeridian = true;
      } else {
        this.showMeridian = false;
      }
    });
    if (this.translate.instant('components.edit_incidents.meridian') === '12H') {
      this.showMeridian = true;
    }

    this.loadFromFields();
  }

  public async invokeIncidentsBackend(from: string, to: string, searchString: string, lastId?: string): Promise<Incident[]> {
    if (!this.categories) {
      this.categories = await this.incidentsService.getIncidentCategories();
    }
    if (this.auth.hasGlobalRight('questionnaire-account-report')) {
      // ADMIN
      return await this.incidentsService.getAccountIncidents(from, to, searchString, this.pageSize, lastId);
    } else if (this.auth.hasGlobalRight('questionnaire-detailed-report')) {
      // PRODUCTION MANAGER
      return await this.incidentsService.getLinesIncidents(from, to, searchString, this.pageSize, lastId);
    } else {
      // OPERATOR
      return await this.incidentsService.getLinesIncidents(from, to, searchString, this.pageSize, lastId);
    }
  }

  public loadIncidents(from: string, to: string, searchString: string): void {
    (async () => {
      this.isLoading = true;
      this.incidents = await this.invokeIncidentsBackend(from, to, searchString);
      this.myScrollContainer.nativeElement.scrollTop = 0;
      this.page = 1;
      this.isLoading = false;
    })();
  }

  public appendIncidents(from: string, to: string, searchString: string): void {
    (async () => {
      this.isLoading = true;
      if (this.incidents.length === 0) {
        this.loadIncidents(from, to, searchString);
      } else {
        const lastId = this.incidents[this.incidents.length - 1].incidentId;
        this.incidents = this.incidents.concat(await this.invokeIncidentsBackend(from, to, searchString, lastId));
      }
      this.isLoading = false;
    })();
  }

  public onScrollingDown(): void {
    let from: string;
    let to: string;

    if (this.page !== this.maximumPages) {
      if (!this.timeReadOnly) {
        const fromDateAndTime = this.mergeDateAndTime(this.formGroup.controls.fromDate.value, this.formGroup.controls.fromTime.value);
        const toDateAndTime = this.mergeDateAndTime(this.formGroup.controls.toDate.value, this.formGroup.controls.toTime.value);
        from = fromDateAndTime.utc().format();
        to = toDateAndTime.utc().format();
      } else {
        const dateDayAgo = new Date();
        dateDayAgo.setDate(dateDayAgo.getDate() - 1);
        from = moment(dateDayAgo).utc().format();
        to = moment(new Date()).utc().format();
      }
      this.appendIncidents(from, to, this.formGroup.controls.searchString.value);
      this.page = this.page + 1;
    }
  }

  public onUse24HoursChange(value: boolean): void {
    if (value) {
      this.timeReadOnly = true;
      this.formGroup.controls.fromDate.disable();
      this.formGroup.controls.toDate.disable();

      const dateDayAgo = new Date();
      dateDayAgo.setDate(dateDayAgo.getDate() - 1);
      const dayAgo: moment.Moment = moment(dateDayAgo);
      const now: moment.Moment = moment(new Date());
      this.loadIncidents(dayAgo.utc().format(), now.utc().format(), this.formGroup.controls.searchString.value);
    } else {
      this.timeReadOnly = false;
      this.formGroup.controls.fromDate.enable();
      this.formGroup.controls.toDate.enable();
      this.loadFromFields();
    }
  }

  public onFromDateChange(value: Date): void {
    const fromDateAndTime = this.mergeDateAndTime(value, this.formGroup.controls.fromTime.value);
    const toDateAndTime = this.mergeDateAndTime(this.formGroup.controls.toDate.value, this.formGroup.controls.toTime.value);
    this.loadIncidents(fromDateAndTime.utc().format(), toDateAndTime.utc().format(), this.formGroup.controls.searchString.value);
  }

  public onToDateChange(value: Date): void {
    const fromDateAndTime = this.mergeDateAndTime(this.formGroup.controls.fromDate.value, this.formGroup.controls.fromTime.value);
    const toDateAndTime = this.mergeDateAndTime(value, this.formGroup.controls.toTime.value);
    this.loadIncidents(fromDateAndTime.utc().format(), toDateAndTime.utc().format(), this.formGroup.controls.searchString.value);
  }

  public loadFromFields(): void {
    const fromDateAndTime = this.mergeDateAndTime(this.formGroup.controls.fromDate.value, this.formGroup.controls.fromTime.value);
    const toDateAndTime = this.mergeDateAndTime(this.formGroup.controls.toDate.value, this.formGroup.controls.toTime.value);
    this.loadIncidents(fromDateAndTime.utc().format(), toDateAndTime.utc().format(), this.formGroup.controls.searchString.value);
  }

  public mergeDateAndTime(dateValue: Date, timeValue: string): moment.Moment {
    const date: moment.Moment = moment(dateValue);
    const time: moment.Moment = moment(timeValue);
    date.hour(time.hour());
    date.minute(time.minute());
    return date;
  }

  public formatDateToTime(date: string): string {
    const time: moment.Moment = moment(date);
    return time.format(this.translate.instant('global.datetime_format'));
  }

  public getDuration(incident: Incident): string {
    if (incident.startTime && incident.endTime) {
      const duration = moment.duration(moment(incident.endTime).diff(moment(incident.startTime)));
      return Math.round(duration.asMinutes()).toString() + ' Min';
    } else {
      return '-';
    }
  }

  public getIconUrl(incident: Incident): string {
    if (incident.iconUrl && incident.iconUrl !== null) {
      return incident.iconUrl;
    } else if (incident.equipmentLevel) {
      return 'assets/apps/equipments/eq-' + incident.equipmentLevel.toLowerCase() + '.svg';
    } else {
      return '';
    }
  }

  public getCategoryTitle(incident: Incident): string {
    if (this.categories && incident.category) {
      const category = this.categories.find(c => c.categoryId === incident.category);
      return category ? category.name : incident.category;
    } else {
      return '-';
    }
  }

  public getStartTime(incident: Incident): string {
    if (incident.startTime) {
      return this.formatDateToTime(incident.startTime);
    } else {
      return this.formatDateToTime(incident.dateOfOccurence);
    }
  }

  public editIncident(incident: Incident): void {
    console.log('EDIT INCIDENT: ', incident);
    const config = {
      class: 'modal-lg',
      initialState: {
        incident,
        onDataChanged: this.onUpdatedIncident
      }
    };
    this.modalRef = this.modalService.show(EditIncidentsComponent, config);
  }

  public onUpdatedIncident: OnDataChanged = (savedObject: OnDataChangedObject) => {
    this.loadFromFields();
  }
}
