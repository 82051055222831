<s2a-platform-header title="{{ 'apps.insight' | translate }}"></s2a-platform-header>

<div *ngIf="isLoading" class="mt-3">
  <s2a-spinner></s2a-spinner>
</div>

<div *ngIf="!isLoading">
  <div class="container-fluid">
    <div class="tab-nav">

      <ul class="nav-list list-unstyled list-inline">
        <li class="list-inline-item active">
          <a class="btn btn-link" [routerLink]="['/insight']">
            <span translate>components.insight.structure-whiteboard.title</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="container">
    <s2a-shift-header class="col align-self-center" [firstHeading]="lineName" [secondHeading]="mpuInfo" [shift]="shift" [timezone]="siteLocation"></s2a-shift-header>
    <div class="row">
      <div class="col-2 d-print-none" ></div>
      <div class="col d-flex justify-content-between">
        <div class="widget">
          <div kendoTooltip [tooltipTemplate]="tooltip" position="bottom" class="info-icon">
            <svg-icon  title="" src="assets/icons/icon-info.svg"></svg-icon>
          </div>
          <kendo-chart class="pb-2" style="height:140px" [seriesColors]="['#7A7F8C', '#E34805', '#B3282E']" [chartArea]="{background: 'none'}">
            <ng-template kendoChartDonutCenterTemplate>
              <div class="text-center percentage-value pt-2">
                {{machine_availability * 100 | number:'1.0-1'}}
              </div>
              <div class="percentage-unit">
                %
              </div>
            </ng-template>
            <kendo-chart-series>
              <kendo-chart-series-item [padding]="0" type="donut" [data]="availability_chart_data" field="value" category_field="kind">
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
          <div class="mx-auto text-center widget-title">
            {{'components.insight.mpu-report.availability_tech_faults' | translate: availability_widget_param }}
          </div>
          <div class="mx-auto text-center widget-title">
            {{'components.insight.mpu-report.availability_org_faults' | translate: availability_widget_param }}
          </div>
          <div class="mx-auto text-center widget-subtext">
            {{'components.insight.mpu-report.availability' | translate }}
          </div>
        </div>
        <div class="widget">
          <div class="col-12 d-flex justify-content-center mb-2">
            <div class="row value-billboard align-items-center">
              <div class="col p-0">
                <div class="value">{{operator_interventions}}</div>
                <div class="unit">times</div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center widget-title px-1">
            {{'components.insight.mpu-report.operator_interventions' | translate}}
          </div>
          <div class="col-12 text-center widget-subtext px-1">
          </div>
        </div>
        <div class="widget">
          <div class="col-12 d-flex justify-content-center mb-2">
            <div class="row value-billboard align-items-center">
              <div class="col p-0">
                <div class="value">{{mtbf}}</div>
                <div class="unit">hh:mm:ss</div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center widget-title px-1">
            {{'components.insight.mpu-report.mean_time_between_failures' | translate}}
          </div>
          <div class="col-12 text-center widget-subtext px-1">
            {{'components.insight.mpu-report.mtbf' | translate}}
          </div>
        </div>
        <div class="widget">
          <div class="col-12 d-flex justify-content-center mb-2">
            <div class="row value-billboard align-items-center">
              <div class="col p-0">
                <div class="value">{{mttr}}</div>
                <div class="unit">hh:mm:ss</div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center widget-title px-1">
            {{'components.insight.mpu-report.mean_time_to_repair' | translate}}
          </div>
          <div class="col-12 text-center widget-subtext px-1">
            {{'components.insight.mpu-report.mttr' | translate}}
          </div>
        </div>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row d-none d-print-block">
      <div class="col-2 d-print-none"></div>
      <div class="col">
        <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row">
      <div class="col-2 d-print-none"></div>
      <div class="col">
        <s2a-losses [losses_detailed]="kpiValues.losses_detailed" [shift]="shift" [siteLocation]="siteLocation"></s2a-losses>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row" *ngIf="showChart()">
      <div class="col-2 d-print-none"></div>
      <div class="col">
        <s2a-conditions [productTypeIntervals]="kpiValues.productTypeIntervals"
                        [speedIntervals]="kpiValues.speedIntervals"
                        [shift]="shift"
                        [siteLocation]="siteLocation"
                        [machineId]="machineId"></s2a-conditions>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row">
      <div class="col-2 d-print-none"></div>
      <div class="col">
        <s2a-losses-waterfall [losses]="kpiValues.losses" [duration]="shift?.duration"></s2a-losses-waterfall>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row" id="divisible">
      <div class="col-2 d-print-none"></div>
        <div class="col">
        <s2a-main-faults-summary [losses_detailed]="kpiValues.losses_detailed"></s2a-main-faults-summary>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row" id="divisible">
      <div class="col-2 d-print-none"></div>
      <div class="col">
        <s2a-main-faults-per-hour [siteLocation]="siteLocation" [shift]="shift" [losses_detailed]="kpiValues.losses_detailed"></s2a-main-faults-per-hour>
      </div>
      <div class="col-2 d-print-none"></div>
    </div>
    <div class="row d-print-none" *ngIf="showAdditionalReport">
      <div class="col-2"></div>
      <div class="col">
        <s2a-states-for-validation [lossesDetailed]="kpiValues.losses_detailed"></s2a-states-for-validation>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div>

<ng-template #tooltip><div class="mx-3 mt-3 mode_tooltip" style="color: #333;">
  <div class="mb-2" style="width: 100%;
  border-top: 2px solid;
  font-size: 0.8rem;
  border-top-color: #333;">
    {{'components.insight.mpu-report.fault_model' | translate}}
  </div>
  <div class="info_tooltip">
    <ul class="p-0 ">
      <li>
        <div class="d-flex flex-row align-items-center">
          <div class="mr-2 color-field tech_fault"></div>{{'components.insight.mpu-report.op_mappings_tech_fault' | translate:tech_fault_mappings}}
        </div>
      </li>
      <li>
        <div class="d-flex flex-row align-items-center">
          <div class="mr-2 color-field org_fault"></div>{{'components.insight.mpu-report.op_mappings_org_fault' | translate:org_fault_mappings}}
        </div>
      </li>
    </ul>
  </div>
</div>
</ng-template>
