import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { AuthService } from '@s2a/ng-auth';
import { BasePageComponent } from '@s2a/ng-common';
import { IncidentStatisticEquipment, IncidentStatisticLine, IncidentsService } from '@s2a/ng-incidents';

@Component({
  selector: 's2a-scenario-statistics',
  templateUrl: './scenario-statistics.component.html',
  styleUrls: ['./scenario-statistics.component.scss']
})
export class ScenarioStatisticsComponent extends BasePageComponent implements OnInit {
  public statistics: IncidentStatisticLine[];

  public formGroup: FormGroup;
  public searchControl: FormControl = new FormControl();
  public searchControlSubscription: Subscription;
  public showMeridian = false;
  public firstCall = true;

  constructor(
    public auth: AuthService,
    private formBuilder: FormBuilder,
    private incidentsService: IncidentsService,
    private localeService: BsLocaleService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const fromDate = new Date();
    fromDate.setHours(fromDate.getHours() - 1, fromDate.getMinutes(), fromDate.getSeconds());
    const toDate = new Date();

    this.formGroup = this.formBuilder.group({
      fromDate: [fromDate],
      fromTime: [fromDate],
      toDate: [toDate],
      toTime: [toDate],
      searchString: this.searchControl,
      useLast24Hours: []
    });

    this.searchControlSubscription = this.searchControl.valueChanges
      .debounceTime(500)
      .subscribe(searchString => {
        this.loadFromFields();
    });

    this.translate.onLangChange.subscribe(() => {
      this.localeService.use(this.translate.instant('global.locale'));
      if (this.translate.instant('components.edit_incidents.meridian') === '12H') {
        this.showMeridian = true;
      } else {
        this.showMeridian = false;
      }
    });
    if (this.translate.instant('components.edit_incidents.meridian') === '12H') {
      this.showMeridian = true;
    }
  }

  public getLineIncidents(line: IncidentStatisticLine): number {
    let numIncidents = 0;
    for (const eq of line.children) {
      numIncidents += this.getEquipmentIncidents(eq);
    }
    return numIncidents;
  }

  public getIconUrl(machine: IncidentStatisticEquipment): string {
    if (machine.iconUrl && machine.iconUrl !== null) {
      return machine.iconUrl;
    } else {
      return 'assets/apps/equipments/eq-' + machine.level.toLowerCase() + '.svg';
    }
  }

  public statisticsAvailable(): boolean {
    for (const line of this.statistics) {
      if (this.getLineIncidents(line) > 0) {
        return true;
      }
    }
    return false;
  }

  public getEquipmentIncidents(equipment: IncidentStatisticEquipment): number {
    return equipment.incidents.length;
  }

  private loadStatistics(fromDate: string, toDate: string, searchString: string): void {
    if (!this.firstCall) {
    (async () => {
      this.isLoading = true;
      this.statistics = await this.incidentsService.getStatistics(fromDate, toDate, searchString);
      console.log(this.statistics);
      this.isLoading = false;
    })();
    } else {
      this.firstCall = false;
    }
  }

  /*private loadIncidents = (date: string) => {
    (async () => {
      this.isLoading = true;
      let incidents: Incident[] = [];
      if (this.auth.hasGlobalRight('questionnaire-account-report')) {
        incidents = await this.incidentsService.getAccountIncidentsForDate(date);
      } else {
        incidents = await this.incidentsService.getUserIncidentsForDate(date);
      }
      this.report = {
        lines: []
      };

      for (const inc of incidents) {
        // TODO find line
        if (this.report.lines.length === 0) {
          this.report.lines.push({
            equipmentId: '-',
            techDesc: 'Line 1',
            machines: []
          });
        }
        const matchingLine = this.report.lines[0];
        // Find the machine or create it
        let matchingMachine: IncidentReportMachine = null;
        console.log(matchingMachine);
        if (matchingLine.machines.filter(m => m.equipmentId === inc.equipmentId).length > 0) {
          matchingMachine = matchingLine.machines.filter(m => m.equipmentId === inc.equipmentId)[0];
        } else {
          matchingMachine = {
            equipmentId: inc.equipmentId,
            techDesc: inc.techDesc,
            equipmentLevel: inc.equipmentLevel,
            scenarios: []
          };
          matchingLine.machines.push(matchingMachine);
        }
        let matchingScenario: IncidentReportScenario = null;
        if (inc.scenario.scenarioId) {
          if (matchingMachine.scenarios.filter(s => s.scenarioId === inc.scenario.scenarioId).length > 0) {
            matchingScenario = matchingMachine.scenarios.filter(s => s.scenarioId === inc.scenario.scenarioId)[0];
          } else {
            matchingScenario = {
              scenarioId: inc.scenario.scenarioId,
              machineType: inc.scenario.machineType,
              componentType: inc.scenario.componentType,
              title: inc.scenario.title,
              description: inc.scenario.description,
              incidents: []
            };
            matchingMachine.scenarios.push(matchingScenario);
          }
        } else {
          if (matchingMachine.scenarios.filter(s => s.scenarioId === inc.scenarioStatus).length > 0) {
            matchingScenario = matchingMachine.scenarios.filter(s => s.scenarioId === inc.scenarioStatus)[0];
          } else {
            matchingScenario = {
              scenarioId: inc.scenarioStatus,
              machineType: '-',
              componentType: '-',
              title: 'Scenario: ' + inc.scenarioStatus,
              description: '-',
              incidents: []
            };
            matchingMachine.scenarios.push(matchingScenario);
          }
        }
        const newIncident: IncidentReportIncident = {
          incidentId: inc.incidentId,
          dateOfOccurence: inc.dateOfOccurence,
          status: inc.status
        };
        matchingScenario.incidents.push(newIncident);
      }
      console.log(this.report);
      this.isLoading = false;
    })();
  }*/

  public onFromDateChange(value: Date): void {
    const fromDateAndTime = this.mergeDateAndTime(value, this.formGroup.controls.fromTime.value);
    const toDateAndTime = this.mergeDateAndTime(this.formGroup.controls.toDate.value, this.formGroup.controls.toTime.value);
    this.loadStatistics(fromDateAndTime.utc().format(), toDateAndTime.utc().format(), this.formGroup.controls.searchString.value);
  }

  public onToDateChange(value: Date): void {
    const fromDateAndTime = this.mergeDateAndTime(this.formGroup.controls.fromDate.value, this.formGroup.controls.fromTime.value);
    const toDateAndTime = this.mergeDateAndTime(value, this.formGroup.controls.toTime.value);
    this.loadStatistics(fromDateAndTime.utc().format(), toDateAndTime.utc().format(), this.formGroup.controls.searchString.value);
  }

  public loadFromFields(): void {
    const fromDateAndTime = this.mergeDateAndTime(this.formGroup.controls.fromDate.value, this.formGroup.controls.fromTime.value);
    const toDateAndTime = this.mergeDateAndTime(this.formGroup.controls.toDate.value, this.formGroup.controls.toTime.value);
    this.loadStatistics(fromDateAndTime.utc().format(), toDateAndTime.utc().format(), this.formGroup.controls.searchString.value);
  }

  public mergeDateAndTime(dateValue: Date, timeValue: string): moment.Moment {
    const date: moment.Moment = moment(dateValue);
    const time: moment.Moment = moment(timeValue);
    date.hour(time.hour());
    date.minute(time.minute());
    return date;
  }

}
