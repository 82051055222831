<s2a-modal-header [title]="title" [modalRef]="modalRef"></s2a-modal-header>

<div class="modal-body">
  <span>{{this.error}}</span>
  <hr class="transparent small">
  <div class="text-right">
    <button class="btn btn-primary btn-leaf" (click)="close();" translate>global.close</button>
  </div>
  <hr class="transparent small">
</div>
