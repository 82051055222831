import { Component, OnInit, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { KpiValueType } from 'src/app/model/kpi-value-type';

@Component({
  selector: 's2a-text-kpi',
  templateUrl: './text-kpi.component.html',
  styleUrls: ['./text-kpi.component.scss']
})
export class TextKpiComponent implements OnInit {
  @Input() rawValue1: number;
  @Input() type1: KpiValueType;
  @Input() unit1: string;

  @Input() rawValue2: number;
  @Input() type2: KpiValueType;
  @Input() unit2: string;

  @Input() title: string;
  @Input() subtitle: string;

  constructor(private dp: DecimalPipe) { }

  ngOnInit(): void {
  }

  get value1(): string {
    return this.getValue(this.rawValue1, this.type1);
  }

  get value2(): string {
    return this.getValue(this.rawValue2, this.type2);
  }

  private getValue(rawValue: number, type: KpiValueType): string {
    if (rawValue !== undefined) {
      if (type === KpiValueType.time) {
        return this.millisecondsToTimeString(rawValue);
      } else if (type === KpiValueType.percentage) {
        return this.dp.transform(rawValue * 100, '1.0-1');
      } else {
        return this.dp.transform(rawValue);
      }
    } else {
      return undefined;
    }
  }

  private millisecondsToTimeString(value: number): string {
    const secondsDivisor = 1000;
    const minutesDivisor = secondsDivisor * 60;
    const hoursDivisor = minutesDivisor * 60;

    const seconds = Math.floor((value / secondsDivisor) % 60);
    const minutes = Math.floor(((value / minutesDivisor) % 60));
    const hours = Math.floor(((value / hoursDivisor) % 24));

    return `${this.getValueWithLeadingZero(hours)}:${this.getValueWithLeadingZero(minutes)}:${this.getValueWithLeadingZero(seconds)}`;
  }

  private getValueWithLeadingZero(value: number): String {
    return `0${value}`.slice(-2);
  }
}
