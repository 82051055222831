import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { MachineWidgetInfos } from 'src/app/model/machine-widget-infos';
import { MergedMappedOpMode } from 'src/app/model/mapped-op-mode';
import { Losses } from 'src/app/model/losses';
import { lossesConfig } from 'src/app/components/global/charts/losses-waterfall/losses-config';

interface KpiInfo {
  source: string;
  type?: string;
  unit?: string;
  value?: number;
  losses?: Losses[];
}

interface KpiRepoItem {
  title: string;
  kpiInfo1: KpiInfo;
  kpiInfo2?: KpiInfo;
  subtitle?: string;
  lossesSource?: MergedMappedOpMode[];
}

// TODO also move me to a better place, along with the KPI repo array
const KPI_OEE_TITLE = 'oee';

@Component({
  selector: 's2a-machine-report-widgets',
  templateUrl: './machine-report-widgets.component.html',
  styleUrls: ['./machine-report-widgets.component.scss']
})
export class MachineReportWidgetsComponent {

  @Input() set widgetInfos(widgetInfos: MachineWidgetInfos) {
    this.kpiList = this.kpisRepo
      .map(kpi => {
        // TODO refactor this along with the way the repo is combined with the widgetInfos input data
        // Switches OEE to OEE or OPI based on model key
        if (kpi.title === KPI_OEE_TITLE && widgetInfos.kpi_model_key) {
          return {
            title: widgetInfos.kpi_model_key,
            kpiInfo1: {
              source: widgetInfos.kpi_model_key,
              type: 'percentage',
              unit: '%',
            }
          };
        }
        return kpi;
      })
      .filter(kpi => (widgetInfos.hasOwnProperty(kpi.kpiInfo1.source) && widgetInfos[kpi.kpiInfo1.source] != null))
      .map(kpi => {
        kpi.kpiInfo1.value = widgetInfos[kpi.kpiInfo1.source] != null ? widgetInfos[kpi.kpiInfo1.source] : 0;
        if (kpi.lossesSource) {
          kpi.kpiInfo1.losses = [];
          Object.keys(widgetInfos.losses)
            .filter(k => (Object.values(kpi.lossesSource) as string[]).includes(k))
            .forEach(k => kpi.kpiInfo1.losses[k] = widgetInfos.losses[k]);
        }
        if (kpi.kpiInfo2 && widgetInfos[kpi.kpiInfo2.source] !== undefined) {
          kpi.kpiInfo2.value = widgetInfos[kpi.kpiInfo2.source];
        }
        return kpi;
      });
  }

  private kpisRepo: KpiRepoItem[] = [ // TODO move me to a better place
    {
      title: KPI_OEE_TITLE,
      kpiInfo1: {
        source: 'oee',
        type: 'percentage',
        unit: '%',
      }
    },
    {
      title: 'quality',
      kpiInfo1: {
        source: 'quality',
        type: 'percentage',
        unit: '%',
      }
    },
    {
      title: 'performance',
      kpiInfo1: {
        source: 'performance',
        type: 'percentage',
        unit: '%',
      }
    },
    {
      title: 'availability',
      kpiInfo1: {
        source: 'availability',
        type: 'percentage',
        unit: '%',
      }
    },
    {
      title: '',
      kpiInfo1: {
        source: 'machine_availability',
        type: 'donut',
      },
      lossesSource: [MergedMappedOpMode.TECH_FAULT, MergedMappedOpMode.ORG_FAULT]
    },
    {
      title: '',
      kpiInfo1: {
        source: 'tech_availability',
        type: 'donut',
      },
      lossesSource: [MergedMappedOpMode.TECH_FAULT]
    },
    {
      title: 'meantime_between_failures',
      kpiInfo1: {
        source: 'mtbf',
        type: 'time',
        unit: 'hh:mm:ss',
      },
      subtitle: 'mtbf'
    },
    {
      title: 'meantime_to_repair',
      kpiInfo1: {
        source: 'mttr',
        type: 'time',
        unit: 'hh:mm:ss',
      },
      subtitle: 'mttr'
    },
    {
      title: 'produced',
      kpiInfo1: {
        source: 'units_produced',
        type: 'value',
        unit: 'units',
      },
      subtitle: 'good_product'
    },
    {
      title: 'rejected',
      kpiInfo1: {
        source: 'units_defect',
        type: 'value',
        unit: 'units',
      },
      subtitle: 'bad_product'
    },
    {
      title: 'operator_interventions',
      kpiInfo1: {
        source: 'operator_interventions',
        type: 'value',
        unit: 'times',
      }
    },
    {
      title: 'breakdowns',
      kpiInfo1: {
        source: 'breakdown_times',
        type: 'value',
        unit: 'times',
      },
      kpiInfo2: {
        source: 'breakdown_duration',
        type: 'time',
        unit: 'hh:mm:ss'
      }
    }
  ];

  public kpiList: KpiRepoItem[];

  constructor(private translate: TranslateService, private dp: DecimalPipe) { }

  private getLossColor(loss: string): string {
    const lossConfig = lossesConfig.find(lC => lC.mappedOpMode === loss);
    return lossConfig ? lossConfig.style.color : '#FFFFFF';
  }

  public getDonutChartData(kpiInfo: KpiInfo): { kind: string, value: number, color?: string }[] {
    return [{
      kind: kpiInfo.source, value: kpiInfo.value, color: '#7A7F8C'
    }].concat(Object.keys(kpiInfo.losses)
      .map(lossKey => {
        return {
          kind: lossKey, value: kpiInfo.losses[lossKey], color: this.getLossColor(lossKey)
        };
      })
    );
  }

  public getDonutFaults(kpi: KpiRepoItem): string[] {
    let faultVal: number;
    const faults = kpi.lossesSource
                    .filter(lossKey => lossKey === MergedMappedOpMode.ORG_FAULT || lossKey === MergedMappedOpMode.TECH_FAULT);
    if (faults.length > 0) {
      return faults.map(lossKey => {
        faultVal = 0;
        if (lossKey in kpi.kpiInfo1.losses) {
          faultVal = kpi.kpiInfo1.losses[lossKey];
        }
        const faultValString: string = this.dp.transform(faultVal * 100, '1.0-1');
        const lossString: string = this.translate.instant('components.performance.machine_report.machine_kpis.' + lossKey);
        return `- ${faultValString} % ${lossString}`;
      });
    } else {  // TODO: delete if & else after backend isn't returning availability anymore (just tech_availability / machine_availability)
      return [
        `- 0 % ${this.translate.instant('components.performance.machine_report.machine_kpis.tech_fault')}`,
        `- 0 % ${this.translate.instant('components.performance.machine_report.machine_kpis.org_fault')}`
      ];
    }
  }

  private getTranslation(key: string): string {
    return this.translate.instant(key);
  }

  public getUnitText(unit: string): string {
    switch (unit) {
      case '%':
      case 'hh:mm:ss':
        return unit;
      default:
        return this.getTranslation('components.performance.machine_report.machine_kpis.' + unit);
    }
  }
}
