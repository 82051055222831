<div class="machine-widget-container" [ngClass]="{ 'is-lead-machine': isLeadMachine }" [routerLink]="['/performance', 'line', lineId, 'machine-report', id]">

  <div class="visible-mobile container">
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="icon-container">
          <div class="icon" [ngClass]="[bgColorClass, iconSizeClass]">
            <svg-icon [src]="icon" [svgStyle]="{ 'fill': textColor, 'width': '100%', 'height': '100%' }" class="inline-icon"></svg-icon>
          </div>
        </div>
      </div>
      <div class="col d-flex align-items-center">
        <div>
          <div class="title">
            {{ name }} | {{ techDesc }}
          </div>
          <div class="status" *ngIf="isFault">
            {{ currentStatus }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="visible-desktop machine-widget-card">
    <div class="title">
      {{ name }} | {{ techDesc }}
    </div>
    <div class="icon-container">
      <div class="icon" [ngClass]="[bgColorClass, iconSizeClass]">
        <svg-icon [src]="icon" [svgStyle]="{ 'fill': textColor, 'width': '100%', 'height': '100%' }" class="inline-icon"></svg-icon>
      </div>
    </div>
    <div class="status">
      <span *ngIf="isFault">{{ currentStatus }}</span>
    </div>
  </div>

  <s2a-incident-badge [incidents]="incidents"
                      (clickedIncident)="clickedIncident.emit($event)"></s2a-incident-badge>

</div>
