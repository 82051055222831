<div class="row mt-3 mb-5 flex-wrap mx-0">
    <div class="step-tile col-xl-2 col-md-3 col-sm-4" *ngFor="let step of sopData.steps; let i = index" (click)="openStep(step, i)">
      <div class="step-number">{{i + 1}}</div>
      <div class="main-container">
        <div class="step-img-container">
             <!--[ngStyle]="{'background-image': stepImgs[step.imageId] ? 'url(\''+stepImgs[step.imageId]+'\')' : 'none'}">-->
            <img [src]="stepImgs[step.imageId] ? stepImgs[step.imageId] : ''" *ngIf="!stepVideoMimes[step.imageId]" />
            <video *ngIf="stepImgs[step.imageId] && stepVideoMimes[step.imageId]" playsinline autoplay loop
                   (canplay)="$event.target.muted = 'muted'" class="w-100 h-100" style="object-fit: cover;">
                <source [src]="stepImgs[step.imageId]" [type]="stepVideoMimes[step.imageId]">
            </video>
        </div>
        <div class="text-container">
            <strong>{{step.title}}</strong>
            <br />
            <p class="step-description" [innerHTML]="step.description | nl2br"></p>
            <ul class="step-description" *ngIf="step.subtasks.length">
                <li *ngFor="let subtask of step.subtasks">{{subtask}}</li>
            </ul>
        </div>
      </div>
    </div>
    <div *ngIf="sopData.steps.length === 0" translate>
        components.sop.no_steps
    </div>
</div>
