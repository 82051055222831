import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Shift, isEqual, ShiftModel } from '@s2a/ng-shifts';

import { ProductionSite } from '../model/production-site';
import { Mpu } from '../model/mpu';

@Injectable({
  providedIn: 'root'
})
export class ActiveSiteDatastoreService {

  private activeSite: BehaviorSubject<ProductionSite> = new BehaviorSubject<ProductionSite>(undefined);
  private selectedMpu: Mpu = undefined;
  private sites: BehaviorSubject<ProductionSite[]> = new BehaviorSubject<ProductionSite[]>(undefined);
  private shift: BehaviorSubject<Shift> = new BehaviorSubject<Shift>(undefined);
  private shiftModel: BehaviorSubject<ShiftModel> = new BehaviorSubject<ShiftModel>(undefined);

  constructor() {
  }

  setSites(sites: ProductionSite[]): void {
    this.sites.next(sites);
  }

  getSites(): ProductionSite[] {
    return this.sites.value;
  }

  getSitesAsObservable(): Observable<ProductionSite[]> {
    return this.sites.asObservable();
  }

  setSelectedMpu(mpu: Mpu): void {
    this.selectedMpu = mpu;
  }

  setCurrentShiftModel(model: ShiftModel): void {
    this.shiftModel.next(model);
  }

  getCurrentShiftModelAsObservable(): Observable<ShiftModel> {
    return this.shiftModel.asObservable();
  }

  getCurrentShiftModel(): ShiftModel {
    return this.shiftModel.value;
  }

  setActiveSite(site: ProductionSite): void {
    this.activeSite.next(site);
  }

  getActiveSite(): ProductionSite {
    return this.activeSite.value;
  }

  getActiveSiteAsObservable(): Observable<ProductionSite> {
    return this.activeSite.asObservable();
  }

  getSelectedMpu(): Mpu {
    return this.selectedMpu;
  }

  getShift(): Shift {
    return this.shift.value;
  }

  getShiftAsObservable(): Observable<Shift> {
    return this.shift.asObservable();
  }

  setShift(shift: Shift): void {
    if (isEqual(shift, this.shift.value)) {
      return;
    }
    this.shift.next(shift);
  }
}
