import { Component, Input, OnInit } from '@angular/core';
import { LossDetailed } from '../../../../../model/loss-detailed';
import { MappedOpMode } from '../../../../../model/mapped-op-mode';

const otherFaults = 'other_faults';

@Component({
  selector: 's2a-main-faults-summary',
  templateUrl: './main-faults-summary.component.html',
  styleUrls: ['./main-faults-summary.component.scss']
})
export class MainFaultsSummaryComponent implements OnInit {

  private _losses_grouped: { [key: string]: LossSummary };

  @Input() set losses_detailed(value) {
    if (value !== undefined || value !== null) {
      this._losses_grouped = this.getLossesGroupedByMessageKey(value);
    }
  }

  get losses_grouped(): { [key: string]: LossSummary } {
    return this._losses_grouped;
  }

  get main_fault_keys(): string[] {
    return Object.keys(this.losses_grouped).filter(k => k !== otherFaults);
  }

  get overall_fault_count(): number {
    let count = 0;
    Object.keys(this.losses_grouped).forEach(k => {
      count += this.losses_grouped[k].count_total;
    });
    return count;
  }

  get overall_fault_duration(): number {
    let duration = 0;
    Object.keys(this.losses_grouped).forEach(k => {
      duration += this.losses_grouped[k].duration_total;
    });
    return duration;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  public get_losses_subset_keys(losses_key): string[] {
    return Object.keys(this.losses_grouped[losses_key].losses_subset);
  }

  private getInitialGroupedLosses(): { [id: string]: LossSummary } {
    const losses_grouped = {};
    losses_grouped[otherFaults] = { count_total: 0, duration_total: 0, info: 'Other faults' };
    return losses_grouped;
  }

  private getLossesGroupedByMessageKey(losses: LossDetailed[]): { [id: string]: LossSummary } {
    const mainLossesGrouped = this.getInitialGroupedLosses();
    losses.forEach(loss => {
      if (this.isNotMainFault(loss)) {
        return;
      }
      const key = this.getKeyOrDefault(loss);
      const duration = parseInt(loss.end, 10) - parseInt(loss.start, 10);
      if (mainLossesGrouped[key] === undefined) {
        mainLossesGrouped[key] = {
          info: loss.message.info,
          duration_total: 0,
          count_total: 0,
          mapped_op_mode: loss.mapped_op_mode,
          losses_subset: {}
        };
      }
      mainLossesGrouped[key].duration_total += duration;
      mainLossesGrouped[key].count_total++;

      if (this.isOtherFault(loss) || this.isKeywordMissing(loss)) {
        return;
      }
      if (mainLossesGrouped[key].losses_subset[loss.message.keyword] === undefined) {
        mainLossesGrouped[key].losses_subset[loss.message.keyword] = {
          keyword: loss.message.keyword,
          count_total: 0,
          duration_total: 0
        };
      }
      mainLossesGrouped[key].losses_subset[loss.message.keyword].duration_total += duration;
      mainLossesGrouped[key].losses_subset[loss.message.keyword].count_total++;
    });
    return mainLossesGrouped;
  }

  private isKeywordMissing(loss: LossDetailed): boolean {
    if (loss.message.keyword === undefined || loss.message.keyword === '') {
      return true;
    }
    return false;
  }

  private getKeyOrDefault(loss: LossDetailed): string {
    if (this.isOtherFault(loss)) {
      return otherFaults;
    }
    return `[${loss.message.id}]-${loss.message.subsystem}`;
  }

  private isOtherFault(loss): boolean {
    if (loss.message === undefined) {
      return true;
    }
    if (loss.message.id === undefined || loss.message.subsystem === undefined) {
      return true;
    }
    if (loss.mapped_op_mode !== MappedOpMode.TECH_FAULT && loss.mapped_op_mode !== MappedOpMode.ORG_FAULT) {
      return true;
    }
    return false;
  }

  private isNotMainFault(loss: LossDetailed): boolean {
    if (loss.mapped_op_mode !== MappedOpMode.ORG_FAULT && loss.mapped_op_mode !== MappedOpMode.TECH_FAULT) {
      return true;
    }
    return false;
  }
}

interface LossSummary {
  info: string;
  count_total: number;
  mapped_op_mode: string;
  duration_total: number;
  losses_subset: { [keyword: string]: LossSubset };
}

interface LossSubset {
  keyword: string;
  count_total: number;
  duration_total: number;
}
