import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap';

import { BaseModalPageComponent } from '@s2a/ng-common';
import { EquipmentService, EqupipmentLevel } from '@s2a/ng-equipment';
import { Scenario, IncidentsService } from '@s2a/ng-incidents';

@Component({
  selector: 's2a-edit-scenario',
  templateUrl: './edit-scenario.component.html',
  styleUrls: ['./edit-scenario.component.scss']
})
export class EditScenarioComponent extends BaseModalPageComponent implements OnInit {
  public parentScenario: Scenario;
  public formGroup: FormGroup;
  public scenario: Scenario;
  public machineTypes: string[] = [];
  public componentTypes: string[] = [];

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private incidentsService: IncidentsService,
    private equipmentService: EquipmentService
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    if (!this.scenario) {
      this.scenario = {
        title: null,
        description: null,
        machineType: null,
        componentType: null,
        equipmentLevel: null,
        editable: true,
        account: null
      };
    }
    this.formGroup = this.formBuilder.group({
      title: [this.scenario.title, Validators.required],
      description: [this.scenario.description, Validators.required]/*,
      machineType: [this.scenario.machineType, Validators.required],
      componentType: [this.scenario.componentType]*/
    });
    if (!this.parentScenario) {
      this.formGroup.addControl('machineType', new FormControl(this.scenario.machineType, Validators.required));
      this.formGroup.addControl('componentType', new FormControl(this.scenario.componentType));
    }
    this.loadData();
  }

  private loadData(): void {
    (async () => {
      this.isLoading = true;
      await this.loadLevels();
      this.isLoading = false;
    })();
  }

  private async loadLevels(): Promise<any> {
    const eqLevel: EqupipmentLevel[] = await this.equipmentService.getLevels();
    if (!eqLevel) {
      return;
    }
    for (const lvl of eqLevel) {
      if (lvl.level === 'machine' && lvl.validProperties) {
        for (const prop of lvl.validProperties) {
          if (prop.key === 'type' && prop.type === 'enum' && prop.enum) {
            for (const entry of prop.enum) {
              this.machineTypes.push(entry);
            }
          }
        }
      } else if (lvl.level === 'component' && lvl.validProperties) {
        for (const prop of lvl.validProperties) {
          if (prop.key === 'type' && prop.type === 'enum' && prop.enum) {
            for (const entry of prop.enum) {
              this.componentTypes.push(entry);
            }
          }
        }
      }
    }
  }

  public async delete(): Promise<void> {
    const result = await this.incidentsService.deleteScenario(this.scenario);
    if (this.onDataChanged) {
      this.onDataChanged(result);
    }
    this.modalRef.hide();
  }

  public async save(params): Promise<void> {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.scenario.title = params.title;
      this.scenario.description = params.description;
      if (!this.parentScenario) {
        this.scenario.machineType = params.machineType;
        this.scenario.componentType = (params.componentType) ? params.componentType : null;
        this.scenario.equipmentLevel = (this.scenario.componentType) ? 'component' : 'machine';
      } else {
        this.scenario.machineType = this.parentScenario.machineType;
        this.scenario.componentType = this.parentScenario.componentType;
        this.scenario.equipmentLevel = this.parentScenario.equipmentLevel;
      }
      if (this.parentScenario && !this.scenario.scenarioId) {
        this.scenario.parentScenario = this.parentScenario.scenarioId;
      }
      let result: Scenario = null;
      if (this.scenario.scenarioId) {
        result = await this.incidentsService.updateScenario(this.scenario);
      } else {
        console.log(this.scenario);
        result = await this.incidentsService.addScenario(this.scenario);
      }
      if (this.onDataChanged) {
        this.onDataChanged(result);
      }
      this.modalRef.hide();
      this.isLoading = false;
    } else {
      this.formGroup.controls.title.markAsTouched();
      this.formGroup.controls.description.markAsTouched();
      this.formGroup.controls.machineType.markAsTouched();
      this.formGroup.controls.componentType.markAsTouched();
    }
  }
}
