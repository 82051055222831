import { Component } from '@angular/core';
import { TranslateHelperService } from '@s2a/ng-common';

@Component({
  selector: 's2a-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  constructor(private translateHelper: TranslateHelperService) {
    this.translateHelper.setup();
  }
}
