<div class="widget-container">
  <div *ngIf="mpu === undefined" class="mpu-headline">
    <p>{{'components.insight.structure-whiteboard.mpu-widget.not_configured' | translate}}</p>
  </div>

  <div *ngIf="mpu !== undefined">
    <div class="mpu-headline">
      {{title}}
    </div>

    <div *ngIf="kpi === undefined || !kpisAvailable">
      <div class="warning-container">
        <img class="warning-icon" src="assets/icons/warning.svg" alt="">
      </div>
      <b class="mpu-bottom-text warning-text">
        <ng-container *ngIf="kpi === undefined">
          {{ 'components.insight.structure-whiteboard.mpu-widget.no_data_available_for_selected_shift' | translate }}
        </ng-container>
        <ng-container *ngIf="!kpisAvailable && kpi !== undefined">
          {{ 'components.insight.structure-whiteboard.mpu-widget.no_kpis_available_for_selected_shift' | translate }}
        </ng-container>
      </b>
    </div>

    <div *ngIf="kpi !== undefined && kpisAvailable">
      <div kendoTooltip [tooltipTemplate]="mputooltip" position="bottom" class="info-icon">
        <svg-icon title="" src="assets/icons/icon-info.svg"></svg-icon>
      </div>

      <kendo-chart style="position: absolute; width: 100%;" [chartArea]="{background: 'none'}">
        <kendo-chart-series>
          <ng-template kendoChartDonutCenterTemplate>
            <svg-icon [src]="iconUrl" class="center-icon"></svg-icon>
          </ng-template>
          <kendo-chart-series-item type="donut" [padding]="0" field="share" [data]="data" colorField="color"
            categoryField="kind">
          </kendo-chart-series-item>
        </kendo-chart-series>
        <kendo-chart-legend [visible]="false"></kendo-chart-legend>
      </kendo-chart>
      <div class="mpu-bottom-text container d-none d-xl-block">
        <div class="row" *ngIf="kpi?.kpis?.machine_availability || kpi?.kpis?.machine_availability === 0">
          <div class="col-3 mpu-bottom-text-value">
            {{formatPercentage(kpi?.kpis.machine_availability * 100)}}&nbsp;%
          </div>
          <div class="col-7 mpu-bottom-text-description">
            {{'components.insight.structure-whiteboard.mpu-widget.machine_availability' | translate}}
          </div>
        </div>
        <div class="row" *ngIf="!kpi?.kpis?.machine_availability && kpi?.kpis?.machine_availability !== 0">
          <div class="col mpu-bottom-text-description">
            {{ 'components.insight.structure-whiteboard.mpu-widget.no_machine_availability' | translate }}
          </div>
        </div>
        <div class="row" *ngIf="kpi?.kpis?.tech_availability || kpi?.kpis?.tech_availability === 0">
          <div class="col-3 mpu-bottom-text-value">
            {{formatPercentage(kpi?.kpis.tech_availability * 100)}}&nbsp;%
          </div>
          <div class="col-9 mpu-bottom-text-description">
            {{'components.insight.structure-whiteboard.mpu-widget.technical_availability' | translate}}
          </div>
        </div>
        <div class="row" *ngIf="!kpi?.kpis?.tech_availability && kpi?.kpis?.tech_availability !== 0">
          <div class="col mpu-bottom-text-description">
            {{ 'components.insight.structure-whiteboard.mpu-widget.no_technical_availability' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mputooltip>
  <div class="mx-3 mt-3 mode_tooltip">
    <div class="mb-2" style="width: 100%;
  border-top: 2px solid;
  font-size: 0.8rem;">
      {{'components.insight.structure-whiteboard.mpu-widget.tooltip.heading' | translate}}
    </div>
    <table class="table table-borderless" style="font-size: 0.8rem;">
      <tbody>
        <tr *ngFor="let mpuPart of mpuParts">
          <td class="pt-0 pl-0 align-middle">
            <svg-icon [src]="mpuPart.iconUrl" class="pr-0 center-icon"></svg-icon>
          </td>
          <td class="pt-0 px-0 align-middle">{{mpuPart.serialNumber}}</td>
          <td class="p-0 ">|</td>
          <td class="pt-0 pl-0 align-middle">
            <div class="ellipsis">{{mpuPart.description}}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>