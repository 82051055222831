<div class="row">
  <div class="col text-center pt-3">
    {{ firstHeading }}
  </div>
</div>
<div class="row">
  <div class="col text-center">
    <h3 class="font-weight-bold" *ngIf="machine !== undefined">
      {{ machineName }} | {{ techDesc }}
    </h3>
    <h3 class="font-weight-bold" *ngIf="machine === undefined">
      {{ secondHeading }}
    </h3>
  </div>
</div>
<div class="row">
  <div class="col text-center pb-3">
    <h3>
      {{ shiftDescription }}
    </h3>
  </div>
</div>
