<ng-template #tooltip>
  <s2a-state-info-tooltip [stateItems]="stateItems"></s2a-state-info-tooltip>
</ng-template>
<div class="mt-5 pb-4 p-3 report-item-container">
  <div class="row mx-0">
    <div class="col px-0"></div>
    <div kendoTooltip [tooltipTemplate]="tooltip" class="col-auto px-0 pb-1 mr-2 ">
      <svg-icon title="" src="assets/icons/icon-info.svg"></svg-icon>
    </div>
  </div>
  <h4 class="header">{{'components.performance.machine_report.main_faults_summary.heading' | translate}}</h4>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th style="width: 20%;" scope="col">
            {{'components.performance.machine_report.main_faults_summary.message_number' | translate}}
          </th>
          <th style="width: 45%;" scope="col">
            {{'components.performance.machine_report.main_faults_summary.message' | translate}}
          </th>
          <th style="width: 10%;" scope="col" class="text-right">
            {{'components.performance.machine_report.main_faults_summary.count' | translate}}
          </th>
          <th style="width: 25%;" scope="col" class="text-right">
            {{'components.performance.machine_report.main_faults_summary.duration' | translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let key of mainFaultKeys">
          <tr class="table-border-top">
            <td class="h-100">
              <div style="display: table; height: 100%; width: 100%;">
                <div style="display: table-cell; height: 100%;">
                  <div class="colorcode h-100" [ngClass]="{
                    'org': lossesGrouped[key].mapped_op_mode === 'org_fault',
                    'tech': lossesGrouped[key].mapped_op_mode === 'tech_fault', 
                    'incident': lossesGrouped[key].mapped_op_mode === 'incident',
                    'breakdown': lossesGrouped[key].mapped_op_mode === 'breakdown',
                    'external': lossesGrouped[key].mapped_op_mode === 'external'
                  }"></div>
                </div>
                <div class="text-left" style="display: table-cell; vertical-align: top; height: 100%;">{{key}}</div>
              </div>
            </td>
            <td class="align-middle">{{lossesGrouped[key].info}}</td>
            <td class="text-right losses-grouped-count-total">{{lossesGrouped[key].count_total}}</td>
            <td class="text-right">{{lossesGrouped[key].duration_total | formatDuration}}</td>
          </tr>
          <tr *ngFor="let subsetKey of getLossesSubsetKeys(key)">
            <td class="h-100">
              <div style="display: table; height: 100%; width: 100%;">
                <div style="display: table-cell; height: 100%;">
                  <div class="colorcode h-100" [ngClass]="{
                    'org': lossesGrouped[key].mapped_op_mode === 'org_fault',
                    'tech': lossesGrouped[key].mapped_op_mode === 'tech_fault', 
                    'incident': lossesGrouped[key].mapped_op_mode === 'incident',
                    'external': lossesGrouped[key].mapped_op_mode === 'external'}"></div>
                </div>

              </div>
            </td>
            <td>{{lossesGrouped[key].losses_subset[subsetKey].keyword}}</td>
            <td class="text-right">{{lossesGrouped[key].losses_subset[subsetKey].count_total}}</td>
            <td class="text-right">{{lossesGrouped[key].losses_subset[subsetKey].duration_total | formatDuration}}</td>
          </tr>
        </ng-container>
        <tr class="table-border-top">
          <td></td>
          <td class="align-middle">{{lossesGrouped['other_faults'].info}}</td>
          <td class="text-right">{{lossesGrouped['other_faults'].count_total}}</td>
          <td class="text-right">{{lossesGrouped['other_faults'].duration_total | formatDuration}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr style="font-weight: bold;">
          <td></td>
          <td class="text-right" style="padding: 0px;">
            {{'components.insight.mpu-report.main_faults_common.total' | translate}}
          </td>
          <td class="text-right" style="padding: 0px;">
            {{overallFaultCount}}
          </td>
          <td class="text-right" style="padding: 0px;">
            {{overallFaultDuration | formatDuration}}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>