export class KendoHelper {
    public addViewBoxToSvg(event): void {
        const chartElement = event.sender.element.nativeElement;
        const positionInfo = chartElement.getBoundingClientRect();
        const height = positionInfo.height;
        const width = positionInfo.width;

        this.scanNode(chartElement, Math.round(width), Math.round(height));
    }

    private scanNode(node, width, height): void {
        if (node.nodeName.toLowerCase() === 'svg') {
            node.setAttribute('viewBox', '0 0 ' + width + ' ' + height);
            node.setAttribute('preserveAspectRatio', 'xMidYMin slice');
            return;
        }
        Array.from(node.children).forEach(child => this.scanNode(child, width, height));
    }
}
