import { Component, OnInit, Input } from '@angular/core';
import { TooltipStateItem } from '../../../../../model/tooltip-state-item';

@Component({
  selector: 's2a-state-info-tooltip',
  templateUrl: './state-info-tooltip.component.html',
  styleUrls: ['./state-info-tooltip.component.scss']
})
export class StateInfoTooltipComponent implements OnInit {
  @Input() stateItems: TooltipStateItem[];

  constructor() { }

  ngOnInit(): void {
  }
}
