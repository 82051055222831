<div class="file-item">

  <div class="file-thumbnail-container">
    <span class="spinner" *ngIf="isLoading()">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </span>
    <div class="file-thumbnail cursor-pointer" [ngStyle]="thumbnail()"
      *ngIf="!isLoading()" (click)="openFile($event)"></div>
  </div>



  <div class="file-details">
    <div>
      <strong class="ellipsis cursor-pointer" (click)="openFile($event)">{{ _file && _file.data && _file.data.fileName }}</strong>
    </div>

    <!-- TODO: add context for transclusion -->
    <ng-content select=".file-item-details">
    </ng-content>
  </div>
  <div class="file-actions ml-2">

    <div class="app-dropdown" dropdown [s2aHasRole]="['admin', 'maintenance-manager', 'production-manger', 'scenario-editor']">
      <div class="title-wrapper" dropdownToggle (click)="false">
        <span class="k-icon k-i-more-vertical"></span>
      </div>
      <ul class="dropdown-menu dropdown-menu-right ignore" *dropdownMenu role="menu">

        <!-- TODO: add context for transclusion -->
        <ng-content select=".file-item-actions"></ng-content>

      </ul>
    </div>

  </div>



</div>
