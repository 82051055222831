<div class="step-detail mb-5">

  <div class="btn-container mr-md-3 d-md-flex d-none">
    <a class="btn btn-primary btn-leaf btn-navigate" (click)="showLastStep()" style="left: 0"><</a>
  </div>

  <div class="d-flex flex-grow-1 flex-column flex-md-row">
    <div *ngIf="!hasStepImg" class="d-none d-md-block flex-grow-1 order-0"></div>

    <div class="step-description-container mr-3 order-1 order-md-1">
      <div class="step-number d-none d-md-inline-block">{{stepIndex + 1}}</div>
      <strong class="description-header d-block mb-3">{{stepData.title}}</strong>
      <div [innerHTML]="nl2br(stepData.description)"></div>
      <ul class="step-subtask mt-3" *ngIf="stepData.subtasks.length">
        <li *ngFor="let subtask of stepData.subtasks">{{subtask}}</li>
      </ul>
    </div>

    <div *ngIf="!hasStepImg" class="d-none d-md-block flex-grow-1 order-md-2"></div>

    <div class="step-img-container m-md-0 ml-md-4 mt-4 mx-3 order-0 order-md-3 position-relative"
         [ngClass]="{noImage: !hasStepImg}">
      <div class="btn-container d-md-none">
        <a class="btn btn-primary btn-leaf btn-navigate" (click)="showLastStep()" style="left: -1rem"><</a>
      </div>

      <div class="step-number d-md-none">{{stepIndex + 1}}</div>


      <img [src]="stepImg ? stepImg : ''" *ngIf="hasStepImg && !stepImgVideoMime" />
      <video *ngIf="hasStepImg && stepImgVideoMime" playsinline controls class="mw-100 mh-100" autoplay
             (canplay)="$event.target.muted = 'muted'">
        <source [src]="stepImg" [type]="stepImgVideoMime">
      </video>

      <div class="btn-container d-md-none">
        <a class="btn btn-primary btn-leaf btn-navigate" (click)="showNextStep()" style="right: -1rem">></a>
      </div>
    </div>
  </div>

  <div class="btn-container ml-md-3 d-md-flex d-none">
    <a class="btn btn-primary btn-leaf btn-navigate" (click)="showNextStep()" style="right: 0">></a>
  </div>

  <a href='#' (click)="showSopStepsView()" class="k-icon k-icon-32 k-i-grid show-sop-steps-view"></a>
</div>
