<ng-template #tooltip>
    <s2a-state-info-tooltip [stateItems]="stateItems"></s2a-state-info-tooltip>
</ng-template>

<div class="mt-5 pb-5 p-3 report-item-container">
    <div class="row mx-0">
        <div class="col px-0">
            <div>
                {{ 'components.performance.machine_report.losses.heading' | translate }}
            </div>
        </div>
        <div kendoTooltip [tooltipTemplate]="tooltip" position="bottom" class="col-auto px-0 mr-2">
            <svg-icon title="" src="assets/icons/icon-info.svg"></svg-icon>
        </div>
    </div>
    <div class="d-flex flex-row">
        <div class="chart-icon-container mt-4 pl-1">
            <s2a-svg-icon path="global/losses/" name="losses"></s2a-svg-icon>
        </div>
        <div class="chart-container mt-4">
            <s2a-loss-chart [shift]="shift" [lossesDetailed]="lossesDetailed" [timezone]="timezone"></s2a-loss-chart>
        </div>
    </div>
</div>