<div class="table-container p-3 mt-5" style="width: 100%; height: auto;">
  <div class="row mx-0">
    <div class="col px-0">
      <div>{{'components.insight.mpu-report.conditions.heading' | translate}}</div>
    </div>
    <div kendoTooltip [tooltipTemplate]="tooltip" position="bottom" class="col-auto px-0 mr-2 info-icon">
      <svg-icon title="" src="assets/icons/icon-info.svg"></svg-icon>
    </div>
  </div>
  <div class="row mt-4 chart-container">
    <div class="col">

      <svg-icon class="bottles-icon" src="assets/icons/bottles.svg" alt="sort icon"></svg-icon>
      <div class="grid-container">
        <small *ngFor="let verticalHourRuler of getArrayOfSize(numberOfShiftDurationHours + 1); let i = index"
          [style.margin-left]="gridHelper?.getHourLabelOffset(i)" style="width: 10px;" class="shift-index float-left mr-0">
          {{gridHelper?.getHourLabel(i)}}
        </small>
      </div>
      <div class="grid-container">
        <div *ngIf="speedsAvailable; else onlyProducts">
          <div *ngFor="let verticalHourRuler of getArrayOfSize(numberOfShiftDurationHours + 1); let i = index"
            [style.margin-left]="gridHelper?.getHourOffset(i)" class="grid-lines float-left mr-0">
          </div>
        </div>
      </div>

      <kendo-chart [chartArea]="{background: 'none'}" class="mt-2 sortChange" style="margin-bottom: -20px;"
        (render)="kendoHelper.addViewBoxToSvg($event)">
        <kendo-chart-axis-defaults [visible]="false" [majorGridLines]="{visible: false}">
        </kendo-chart-axis-defaults>
        <kendo-chart-x-axis>
          <kendo-chart-x-axis-item [majorGridLines]="{width: 0}"></kendo-chart-x-axis-item>
        </kendo-chart-x-axis>
        <kendo-chart-series>
          <ng-template ngFor let-sortChange [ngForOf]="sortChanges">
            <kendo-chart-series-item [border]="{width: 2, color: '#fff'}" color="#d8d8d8" type="bar" [stack]="{type: '100%'}"
              [opacity]="sortChange.opacity" [data]="[sortChange.value]">
              <kendo-chart-series-item-labels [visible]="sortChange.opacity !== 0" [content]="getSortChangeLabel"
                position="center" color="#000" background="none">
              </kendo-chart-series-item-labels>
            </kendo-chart-series-item>
          </ng-template>
        </kendo-chart-series>
      </kendo-chart>

      <kendo-chart *ngIf="shift && speedsAvailable" [chartArea]="{background: 'none'}" class="mt-2 speedChange" (render)="kendoHelper.addViewBoxToSvg($event)">
        <kendo-chart-x-axis>
          <kendo-chart-x-axis-item [majorGridLines]="{visible: false}" [max]="shiftDuration" [labels]="{visible: false}"></kendo-chart-x-axis-item>
        </kendo-chart-x-axis>
         <kendo-chart-y-axis>
          <kendo-chart-y-axis-item [majorGridLines]="{width: 3}" [color]="getAxisColor()"></kendo-chart-y-axis-item>
        </kendo-chart-y-axis>
        <kendo-chart-series>
          <kendo-chart-series-item [style]="'step'"
                                   width="4"
                                   [missingValues]="'gap'"
                                   type="scatterLine"
                                   [border]="{width: 4}"
                                   color="#66b0e2"
                                   [data]="currentSpeeds"
                                   [markers]="{ visible: false }">
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
  </div>
  <div class="row mx-0 d-none d-print-block">
    <div class="col px-0">
      <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
    </div>
  </div>
  <div class="row">
    <div class="col" style="background-color: transparent; width: 100%;">
    </div>
  </div>
</div>

<ng-template #tooltip>
  <div class="mx-3 mt-3">
    <div class="tooltip-headline">
      {{'components.insight.mpu-report.conditions.sorts' | translate}}
    </div>
    <div class="container my-3">
      <div *ngFor="let sortName of sortNames | keyvalue" class="row my-2">
        <div class="float-right"><b>{{ formatSortId(sortName.key) }}</b> {{ sortName.value === undefined ? ('components.insight.mpu-report.conditions.sort_name' | translate) : sortName.value  }}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #onlyProducts>
    <div *ngFor="let verticalHourRuler of getArrayOfSize(numberOfShiftDurationHours + 1); let i = index" [style.margin-left]="gridHelper?.getHourOffset(i)"
     class="grid-lines-small float-left">
  </div>
</ng-template>
