import { Injectable } from '@angular/core';
import { S2AHttpClient } from '@s2a/ng-common';
import { EquipmentInsight } from '../model/equipment-insight';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MpuKpiService {

  constructor(private s2aHttpClient: S2AHttpClient) {
  }

  async getEquipmentPerformanceIndicators(equipmentId: string, shiftBegin: number, skipDetails: boolean, includeSpeeds: boolean): Promise<EquipmentInsight> {
    const options = {params: new HttpParams().set('skip_losses_detailed', String(skipDetails)).set('kpis', 'true').set('speeds', String(includeSpeeds))};

    const result: any = await this.s2aHttpClient.get(`readykit/kpis/${equipmentId}/${shiftBegin}`, options).toPromise();
    return await {
      eq_id: result.eq_id,
      kpis: result.kpis,
      losses: result.losses,
      losses_detailed: result.losses_detailed,
      productTypeIntervals: result.product_type,
      op_mappings: result.op_mappings,
      speedIntervals: result.speeds
    };
  }
}
