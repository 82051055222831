<s2a-platform-header title="{{ 'components.incidents.title' | translate }}"></s2a-platform-header>

<div class="container">
  <div class="tab-nav">

    <div class="title-dropdown" dropdown>
      <div class="title-wrapper" dropdownToggle (click)="false">
        {{ 'components.incidents.title' | translate }}
        <svg-icon class="dropdown-icon" src="assets/icons/down-arrow.svg"></svg-icon>
      </div>
      <ul class="dropdown-menu" *dropdownMenu role="menu">
        <li class="dropdown-item">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/incidents']">
            <span *ngIf="auth.hasGlobalRight('questionnaire-account-report') || auth.hasGlobalRight('questionnaire-detailed-report')"
              translate>components.incidents.title</span>
            <span *ngIf="!auth.hasGlobalRight('questionnaire-account-report') && !auth.hasGlobalRight('questionnaire-detailed-report')"
              translate>components.incidents.title_operator</span>
          </a>
        </li>
        <li class="dropdown-item">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/scenario-statistics']">
            <span translate>components.scenario_statistics.title</span>
          </a>
        </li>
        <li class="dropdown-item" *ngIf="auth.hasGlobalRight('questionnaire-manage-scenarios')">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/scenario-editor']">
            <span translate>components.scenario_editor.title</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="nav-list list-unstyled list-inline">
      <li class="list-inline-item">
        <a class="btn btn-link" [routerLink]="['/incidents/incidents']">
          <span *ngIf="auth.hasGlobalRight('questionnaire-account-report') || auth.hasGlobalRight('questionnaire-detailed-report')"
            translate>components.incidents.title</span>
          <span *ngIf="!auth.hasGlobalRight('questionnaire-account-report') && !auth.hasGlobalRight('questionnaire-detailed-report')"
            translate>components.incidents.title_operator</span>
        </a>
      </li>
      <li class="list-inline-item">
        <a class="btn btn-link" [routerLink]="['/incidents/scenario-statistics']">
          <span translate>components.scenario_statistics.title</span>
        </a>
      </li>
      <li class="list-inline-item active" *ngIf="auth.hasGlobalRight('questionnaire-manage-scenarios')">
        <a class="btn btn-link" [routerLink]="['/incidents/scenario-editor']">
          <span translate>components.scenario_editor.title</span>
        </a>
      </li>
    </ul>

  </div>
</div>

<section class="container">
  <div class="row">
    <div class="col page-header">
      <div class="row">
        <div class="col-sm">
          <h2 *ngIf="selectedScenario && !isLoading">
            <a class="btn back-btn" (click)="unselectScenario()">
              <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
            </a> {{selectedScenario.title}}
          </h2>
        </div>
        <div *ngIf="!selectedScenario" class="col-auto">
          <input type="text" class="form-control" name="Search" placeholder="{{ 'global.search' | translate }}" [formControl]="searchControl"
          />
        </div>
        <div class="col-sm-auto text-right-sm">
          <a class="btn btn-primary btn-leaf" (click)="addScenario()" translate>components.scenario_editor.add_scenario</a>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="isLoading">
  <s2a-spinner></s2a-spinner>
</div>

<section *ngIf="!isLoading" class="container">

  <div *ngIf="!listScenarios || listScenarios.length === 0">
    <table class="table incidents-table">
      <tr>
        <td (click)="addScenario()">
          <div class="equipment-item">
            <div class="icon">
              <svg-icon src="assets/icons/add.svg"></svg-icon>
            </div>
            <div class="text-wrapper">
              <div translate class="info-text">components.scenario_editor.no_scenarios_available</div>
            </div>
          </div>

        </td>
      </tr>
    </table>

  </div>

  <div class=" table-responsive " *ngIf="listScenarios && listScenarios.length> 0">
    <table class="table incidents-table">
      <thead>
        <tr class="d-none d-md-table-row">
          <th scope="col" translate>components.scenario_editor.scenario_title</th>
          <th scope="col" translate>components.scenario_editor.scenario_context</th>
          <th *ngIf="!selectedScenario" scope="col" translate>components.scenario_editor.scenario_children</th>
          <th>

          </th>
        </tr>
      </thead>
      <tr *ngFor="let scenario of listScenarios">
        <td (click)="selectScenario(scenario)">
          <div class="equipment-item">
            <div class="icon">
              <svg-icon [src]="getIconUrl(scenario)"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{scenario.title}}</h2>
              <p class="subline">{{scenario.description}}</p>
              <div class="d-sm-none">
                <div>{{'components.scenario_editor.machine_type' | translate}}: {{scenario.machineType}}</div>
                <div>{{'components.scenario_editor.component_type' | translate}}: {{scenario.componentType}}</div>
                <div *ngIf="!selectedScenario">{{'components.scenario_editor.scenario_children' | translate}}: {{(scenario.children) ? scenario.children?.length
                  : 0}}</div>
              </div>
            </div>
          </div>
        </td>
        <td class="d-none d-sm-table-cell" (click)="selectScenario(scenario)">
          <div class="part-wrapper">{{scenario.machineType}}</div>
          <div class="problem-wrapper">{{scenario.componentType}}</div>
        </td>
        <td *ngIf="!selectedScenario" class="d-none d-sm-table-cell" (click)="selectScenario(scenario)">{{(scenario.children) ? scenario.children?.length : 0}}</td>
        <td class="fit-content-col">
          <a class="icon-link" *ngIf="scenario.editable" (click)="editScenario(scenario)">
            <svg-icon class="edit-icon" src="assets/icons/edit.svg"></svg-icon>
          </a>
        </td>
      </tr>
    </table>
  </div>
</section>
