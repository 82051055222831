<div class="container h-100">
  <div class="row h-100 ">
    <div class="col-12 col-md-6 offset-md-3">
      <div class="brand-section">
        <svg-icon class="brand" src="assets/img/brand-login.svg"></svg-icon>
      </div>
      <div *ngIf="lastUsers.length > 0" class="recent-users">
        <p class="form-label" translate>components.login.recent_users</p>
        <ul class="recent-user-selection">
          <li *ngFor="let user of lastUsers">
            <div class="user-img" (click)="selectLastUser(user)">
              <span class="text-placeholder">{{getUserLetters(user)}}</span>
            </div>
          </li>
        </ul>
      </div>
      <form class="form-signin clearfix" [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)">
        <p class="form-label" translate>components.login.login</p>
        <input type="text" class="form-control" name="Account" autocorrect="off" autocapitalize="none" placeholder="{{ 'components.login.account' | translate }}" autofocus="true"
          formControlName="account" [attr.disabled]="isLoading ? '' : null" />
        <input type="text" class="form-control" name="Username" autocorrect="off" autocapitalize="none" placeholder="{{ 'components.login.username' | translate }}" formControlName="login"
          [attr.disabled]="isLoading ? '' : null" />
        <input type="password" class="form-control" name="Password" autocorrect="off" autocapitalize="none" placeholder="{{ 'components.login.password' | translate }}" formControlName="password"
          [attr.disabled]="isLoading ? '' : null" #password />
        <div *ngIf="isLoading">
          <s2a-spinner></s2a-spinner>
        </div>
        <div class="form-error">
          {{ loginErrorMessages[loginError] }}
        </div>
        <a class="change-language" (click)="changeLanguage()" translate>components.login.change_language</a>
        <button class="btn btn-primary btn-leaf float-right" name="Submit" value="Login" type="Submit" [disabled]="" translate>components.login.login</button>
      </form>
    </div>
    <div class="col-0 col-md-3"></div>
  </div>
</div>
