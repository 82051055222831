<div class="table-container p-3 mt-5">
  <div>{{'components.insight.mpu-report.main_faults_per_hour.heading' | translate}}</div>
  <div *ngFor="let hourLoss of getHoursWithMainFaults()">
    <div class="header">
      {{'components.insight.mpu-report.main_faults_common.hour' | translate}} {{hourLoss.hourNumber}} | {{hourLoss.duration}}
    </div>
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th style="width: 20%;" scope="col">{{'components.insight.mpu-report.main_faults_common.message_no' | translate}}</th>
            <th style="width: 45%;" scope="col">{{'components.insight.mpu-report.main_faults_common.message' | translate}}</th>
            <th style="width: 10%;" scope="col" class="text-right">{{'components.insight.mpu-report.main_faults_common.count' | translate}}</th>
            <th style="width: 15%;" scope="col" class="text-right">{{'components.insight.mpu-report.main_faults_common.start_time' | translate}}</th>
            <th style="width: 10%;" scope="col" class="text-right">{{'components.insight.mpu-report.main_faults_common.duration' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let detail of getCompleteMainFaultsByHour(hourLoss.hourNumber)">
            <ng-container [ngTemplateOutlet]="detail.message.keyword ? rowsWithKeyword : rowWithoutKeyword"
              [ngTemplateOutletContext]="{detail: detail}"></ng-container>
          </ng-container>
          <tr class="table-border-top" *ngFor="let otherFaults of getSumOfOtherMainFaultsByHour(hourLoss.hourNumber)">
            <td>
            </td>
            <td>{{'components.insight.mpu-report.main_faults_common.other_faults' | translate}}</td>
            <td class="text-right">
              {{otherFaults.count}}
            </td>
            <td></td>
            <td class="text-right">{{otherFaults.duration | formatDuration}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="font-weight: bold;" *ngFor="let totals of getSumRowForHourNumber(hourLoss.hourNumber)">
            <td style="padding: 0px;"></td>
            <td class="text-right" style="padding: 0px;">
              {{'components.insight.mpu-report.main_faults_common.total' | translate}}
            </td>
            <td class="text-right" style="padding: 0px;">
              {{totals.count}}
            </td>
            <td style="padding: 0px;"></td>
            <td style="padding: 0px;" class="text-right">
              <div>{{totals.duration | formatDuration}}</div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #rowWithoutKeyword let-detail="detail">
  <tr class="table-border-top">
    <td class="h-100">
      <div class="tableConfig">
        <div class="cellConfig">
          <div class="colorcode h-100" [ngClass]="{'org': detail.mapped_op_mode === 'org_fault', 'tech': detail.mapped_op_mode === 'tech_fault'}"></div>
        </div>
        <div class="messageConfig">
          <span class="h-100">[{{detail.message.id}}]-{{detail.message.subsystem}}</span>
        </div>
      </div>
    </td>
    <td class="align-middle">{{detail.message.info}}</td>
    <td class="text-right">1</td>
    <td class="text-right">
      <div>{{formatTime(detail.start)}}</div>
    </td>
    <td class="text-right">{{(detail.end - detail.start) | formatDuration}}</td>
  </tr>
</ng-template>

<ng-template #rowsWithKeyword let-detail="detail">
  <tr class="table-border-top">
    <td class="h-100">
      <div class="tableConfig">
        <div class="cellConfig">
          <div class="colorcode h-100" [ngClass]="{'org': detail.mapped_op_mode === 'org_fault', 'tech': detail.mapped_op_mode === 'tech_fault'}"></div>
        </div>
        <div class="messageConfig">
          <span class="h-100">[{{detail.message.id}}]-{{detail.message.subsystem}}</span>
        </div>
      </div>
    </td>
    <td class="align-middle">{{detail.message.info}}</td>
    <td class="text-right"></td>
    <td class="text-right"></td>
    <td class="text-right"></td>
  </tr>
  <tr>
    <td class="h-100"></td>
    <td class="align-middle">{{detail.message.keyword}}</td>
    <td class="text-right">1</td>
    <td class="text-right">
      <div>{{formatTime(detail.start)}}</div>
    </td>
    <td class="text-right">{{(detail.end - detail.start) | formatDuration}}</td>
  </tr>
</ng-template>