import {
  Observable,
  Subscription,
  combineLatest as combineLatestObservable,
  of as ofObservable,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import * as moment from 'moment';
import * as _defaults from 'lodash.defaults';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ExcelService } from '@s2a/ng-common';
import { MachineEquipment } from '@s2a/ng-equipment';
import { ShiftModel, Shift, ShiftService, ShiftSlot } from '@s2a/ng-shifts';
import { IncidentsService } from '@s2a/ng-incidents';

import { LossDetailed } from 'src/app/model/loss-detailed';
import { Losses } from 'src/app/model/losses';
import { MachineWidgetInfos } from 'src/app/model/machine-widget-infos';
import { MachineReport } from '../../../../model/machine-report';
import { PerformanceService } from '../../../../services/performance.service';

const TIME_FORMATTER = 'DD.MM.YYYY HH:mm';

interface MachineReportParams extends Params {
  lineId: string;
  machineId: string;
  date: string;
  shift: string;
}

@Component({
  selector: 's2a-machine-report',
  templateUrl: './machine-report.component.html',
  styleUrls: ['./machine-report.component.scss']
})
export class MachineReportComponent implements OnInit, OnDestroy {

  // loading indicators
  isLoadingShifts = true;
  isLoadingLineInfo = true;
  isLoadingTimezone = true;
  isLoadingMachineEquipment = true;
  isLoadingReport = true;

  get isLoading(): boolean {
    return this.isLoadingShifts
      || this.isLoadingLineInfo
      || this.isLoadingTimezone
      || this.isLoadingMachineEquipment
      || this.isLoadingReport;
  }

  // report params
  lineId: string;
  machineId: string;
  date: Date;
  shiftTime: string;

  // report data - updated on change of params
  timezone: string;
  lineInfo: string;
  machine: MachineEquipment;
  widgetInfos: MachineWidgetInfos;
  losses: Losses;
  lossesDetailed: LossDetailed[];
  shift: Shift;
  shiftModel: ShiftModel;
  lastCompletedShift: Shift;

  private subscription: Subscription;

  constructor(
    private performanceService: PerformanceService,
    private shiftService: ShiftService,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    private incidentsService: IncidentsService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const params$: Observable<MachineReportParams> = this.activatedRoute.params.pipe(
      tap((params: MachineReportParams) => {
        // set local variables
        this.lineId = params.lineId;
        this.machineId = params.machineId;
        this.date = new Date(params.date);
        this.shiftTime = params.shift;
      }),
      // make it hot, so we don't re-subscribe everytime to the params in the next observables
      shareReplay(1),
    );

    const lineId$ = params$.pipe(
      map(p => p.lineId),
      distinctUntilChanged(),
    );
    const machineId$ = params$.pipe(
      map(p => p.machineId),
      distinctUntilChanged(),
    );

    // should load available shifts every time the line id param changes:
    const shiftModel$: Observable<ShiftModel> = lineId$.pipe(
      tap(() => this.isLoadingShifts = true),
      switchMap((lineId: string) => this.shiftService.getShiftModel(lineId)),
      tap((s => this.shiftModel = s)),
    );

    // this one should fire the last completed shift every time the line id param changes:
    const lastCompletedShift$: Observable<Shift> = lineId$.pipe(
      switchMap((lineId: string) => this.shiftService.getLastCompletedShift(lineId)),
      tap((s) => this.lastCompletedShift = s),
    );

    // this should only fires elements when the shift (date/time) is given by params:
    const shiftFromParams$: Observable<Shift> = combineLatestObservable(
      shiftModel$,
      params$,
    ).pipe(
      map(([shiftModel, params])=> {
        if (! params.date || ! params.shift) {
          return null;
        }

        const date = params.date;
        const shiftTime = params.shift;
        const shifts = shiftModel.shifts || [];
        const shift = shifts.find((shiftSlot: ShiftSlot) => shiftSlot.time === shiftTime);

        if (! shift) {
          return null;
        }

        // TODO investigate and potentially refactor how "from" is calculated
        return <Shift>{
          from: moment(date + ' ' + shiftTime, 'YYYY-MM-DD HH:mm').utc(true).unix() * 1000,
          duration: shiftModel.duration,
          name: shift ? shift.name : undefined,
        };
      }),
    );

    const selectedShift$: Observable<Shift> = combineLatestObservable(
      lastCompletedShift$,
      shiftFromParams$,
    )
      .pipe(
        map(([lastCompletedShift, shiftFromParams]) => {
          if (! shiftFromParams) {
            return lastCompletedShift;
          }
          if (shiftFromParams.from > lastCompletedShift.from) {
            // TODO show message to user that there is no such shift yet, so we selected the last one
            this.changedShift(lastCompletedShift);
            return lastCompletedShift;
          }
          return shiftFromParams;
        }),
        tap((s) => {
          this.isLoadingShifts = false;
          this.shift = s;
        }),
      );

    const lineInfo$: Observable<string> = machineId$.pipe(
      switchMap((machineId: string): Observable<string> => {
        this.isLoadingLineInfo = true;
        return this.performanceService.getPlantAndLineInfos(machineId);
      }),
      tap((lineInfo) => {
        this.isLoadingLineInfo = false;
        this.lineInfo = lineInfo;
      }),
    );

    const timezone$: Observable<string> = machineId$.pipe(
      switchMap((machineId: string): Observable<string> => {
        this.isLoadingTimezone = true;
        return this.performanceService.getTimezone(machineId);
      }),
      tap((timezone) => {
        this.isLoadingTimezone = false;
        this.timezone = timezone;
      }),
    );

    const machineEquipment$: Observable<MachineEquipment> = machineId$.pipe(
      switchMap((machineId: string): Observable<MachineEquipment> => {
        this.isLoadingMachineEquipment = true;
        return this.performanceService.getMachine(machineId);
      }),
      tap((machine) => {
        this.isLoadingMachineEquipment = false;
        this.machine = machine;
      }),
    );

    const machineReport$: Observable<MachineReport> = combineLatestObservable(
      params$,
      selectedShift$,
    ).pipe(
      tap(() => this.isLoadingReport = true),
      switchMap(([params, shift]) => this.performanceService.getMachineReport(params.machineId, shift.from)),
      tap((report: MachineReport) => {
        this.widgetInfos = this.getWidgetInfosFromMachineReport(report);
        this.losses = report.losses;
        this.lossesDetailed = report.losses_detailed;
        this.isLoadingReport = false;
      }),
      catchError(e => {
        // TODO proper error handling
        console.error('An error occurred loading the machine report', e);
        this.isLoadingReport = true;
        return ofObservable(undefined);
      })
    );

    this.subscription = combineLatestObservable(
      lineInfo$,
      timezone$,
      machineEquipment$,
      machineReport$, // should subscribe to the params & shift observables
    )
      .pipe(
        catchError(e => {
          // TODO proper error handling
          console.error('An error occurred in the machine report', e);
          this.isLoadingShifts = true;
          this.isLoadingLineInfo = true;
          this.isLoadingTimezone = true;
          this.isLoadingMachineEquipment = true;
          this.isLoadingReport = true;
          return ofObservable(undefined);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  changedShift(shift: Shift): void {
    if (! shift) {
      return;
    }
    this.router.navigate([
      '/performance',
      'line',
      this.lineId,
      'machine-report',
      this.machineId,
      moment(shift.from).utc().format('YYYY-MM-DD'),
      moment(shift.from).utc().format('HH:mm')
  ]);
  }

  exportReport(): void {
    this.excelService.addJSONToNewSheet(this.getShiftInfo(this.shift), 'Shift');
    this.excelService.addJSONToNewSheet(this.getLossesDetailedData(), 'Incidents');
    this.excelService.addJSONToNewSheet(this.getExcelData(this.widgetInfos, 'machine_report.machine_kpis'), 'KPIs');
    this.excelService.addJSONToNewSheet(this.getExcelData(this.losses, 'line_dashboard.interrupts'), 'Losses');

    const fileName: string = this.lineInfo.split(' ').reduce((a, b) => a + '_' + b);
    const date: string = moment(this.shift.from).tz(this.timezone).format('MM_DD_YYYY');
    const shiftOrTime: string = this.shift.name ? this.shift.name : moment(this.shift.from).tz(this.timezone).format('HH');
    this.excelService.saveAsExcelFile(`${date}_${shiftOrTime}_${fileName}_${this.machine.description}`);
  }

  private getExcelData(reportData: MachineWidgetInfos | Losses, translationKey: string): ExcelData[] {
    return Object.keys(reportData)
      .filter(key => (!reportData[key] && reportData[key] === 0 || !!reportData[key]) && typeof reportData[key] !== 'object')
      .map((key: string) => {
        return {
          name: this.translateService.instant(`components.performance.${translationKey}.${key}`),
          value: reportData[key]
        } as ExcelData;
      });
  }

  private getLossesDetailedData(): IncidentData[] {
    return this.lossesDetailed.map(loss => {
      return {
        Start: moment(loss.start).tz(this.timezone).format(TIME_FORMATTER),
        Stop: moment(loss.end).tz(this.timezone).format(TIME_FORMATTER),
        Duration: `${moment.duration(moment(loss.end).diff(moment(loss.start))).asMinutes().toFixed(2)} min`,
        Type: this.translateService.instant('components.performance.line_dashboard.interrupts.' + loss.mapped_op_mode),
        Machine: this.machine.description,
        Failure: loss.title ? loss.title : (loss.message ? loss.message.info : '') // #deprecated!
      } as IncidentData;
    });
  }

  private getShiftInfo(shift: Shift): ShiftInfo[] {
    const shiftStart = moment(shift.from).tz(this.timezone);
    return [{
      From: shiftStart.format(TIME_FORMATTER),
      To: shiftStart.add(shift.duration, 'h').tz(this.timezone).format(TIME_FORMATTER),
      Duration: shift.duration + ' h',
      Machine: `${this.machine.description} | ${this.machine.techDesc}`,
      Shift: shift.name,
      Site: this.lineInfo,
      Timezone: this.timezone
    } as ShiftInfo];
  }

  private getWidgetInfosFromMachineReport(machineReport: MachineReport): MachineWidgetInfos {
    machineReport.kpi_result = machineReport.kpi_result || {};
    machineReport.kpi_result.duration_losses = machineReport.kpi_result.duration_losses || {};
    machineReport.kpis = machineReport.kpis || {};

    return _defaults(
      {
        kpi_model_key: machineReport.kpi_model_key,
        availability: machineReport.kpis.availability,
        quality: machineReport.kpi_result.quality,
        performance: machineReport.kpi_result.performance,
        breakdown_duration: machineReport.kpis.breakdown_duration,
        breakdown_times: machineReport.kpis.breakdown_times,
        machine_availability: machineReport.kpis.machine_availability,
        tech_availability: machineReport.kpis.tech_availability,
        oee: machineReport.kpis.oee,
        opi: machineReport.kpis.opi,
        units_produced: machineReport.units_produced,
        units_defect: machineReport.units_defect,
        losses: machineReport.losses,
        mtbf: machineReport.kpi_result.mtbf,
        mttr: machineReport.kpi_result.mttr,
        operator_interventions: machineReport.kpis.operator_interventions
      },
      // fallback data from legacy properties - TODO should remove these when fully removed from API
      {

        availability: machineReport.kpi_result.duration_losses.operating_time,
        machine_availability: machineReport.kpi_result.duration_losses.machine_availability,
        tech_availability: machineReport.kpi_result.duration_losses.tech_availability,
        oee: machineReport.kpi_result.effectiveness,
        opi: machineReport.kpi_result.effectiveness,
        mtbf: machineReport.kpis.mtbf,
        mttr: machineReport.kpis.mttr,
      },
      {
        availability: machineReport.kpi_result.duration_losses.tech_availability,
      }
    );
  }
}

interface ExcelData {
  name: string;
  value: number;
}

// Properties capitalized due to the keys in the Excel header
interface IncidentData {
  Start: string;
  Stop: string;
  Duration: string;
  Type: string;
  Machine: string;
  Failure: string;
}

interface ShiftInfo {
  Site: string;
  Machine: string;
  From: string;
  To: string;
  Duration: string;
  Shift: string;
  Timezone: string;
}
