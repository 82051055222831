import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BsLocaleService, BsModalRef } from 'ngx-bootstrap';

import { BaseModalPageComponent } from '@s2a/ng-common';
import { IncidentEquipment } from '@s2a/ng-equipment';
import { Incident, IncidentCategory, Scenario, IncidentsService } from '@s2a/ng-incidents';

export interface OnDataChangedObject {
  operation: 'update';
  incident: Incident;
}

@Component({
  selector: 's2a-edit-incidents',
  templateUrl: './edit-incidents.component.html',
  styleUrls: ['./edit-incidents.component.scss']
})
export class EditIncidentsComponent  extends BaseModalPageComponent  implements OnInit {
  private incident: Incident;

  private categories: IncidentCategory[];
  private machines: IncidentEquipment[];
  private components: IncidentEquipment[];
  private scenarios: Scenario[];
  private subscenarios: Scenario[] = [];

  private selectedCategory: IncidentCategory;
  private selectedMachine: IncidentEquipment;
  private selectedComponent: IncidentEquipment;
  private selectedScenario: Scenario;
  private selectedSubscenario: Scenario;
  private selectedStartDate: Date;
  private selectedEndDate: Date;
  private selectedStatus: boolean;

  private isIncident: boolean;
  private isEditable: boolean;
  private showMeridian: boolean;
  private fullScenario: Scenario;

  constructor(
    private incidentsService: IncidentsService,
    private localeService: BsLocaleService,
    public translate: TranslateService,
    public modalRef: BsModalRef) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.loadLanguageData();
    this.loadIniData();
  }

  /**
   * Loads the data for the current language in order to display date and time correctly
   */
  public loadLanguageData(): void {
    this.translate.onLangChange.subscribe(() => {
      this.localeService.use(this.translate.instant('global.locale'));
      if (this.translate.instant('components.edit_incidents.meridian') === '12H') {
        this.showMeridian = true;
      } else {
        this.showMeridian = false;
      }
    });
    if (this.translate.instant('components.edit_incidents.meridian') === '12H') {
      this.showMeridian = true;
    }
  }

  /**
   * Loads all the data required to correctly display the dialogue
   */
  public async loadIniData(): Promise<void> {
    this.isLoading = true;
    // Load all independant data of the inicident
    await this.loadCategories();
    await this.loadMachines();
    if (!this.incident) {
      this.isLoading = false;
      return;
    }
    if (this.incident.equipmentLevel === 'machine') {
      await this.loadComponents(this.incident.equipmentId);
    } else {
      await this.loadComponents(this.incident.machine.equipmentId);
    }
    // Put the data into the form
    await this.loadIncidentDataIntoForm();

    // Load all dependant data of the incident
    await this.loadScenarios();
    if (this.incident.scenario) {
      if (this.incident.scenario.scenarioId !== null) {
        await this.loadInicidentScenarioIntoForm();
        await this.loadSubscenarios();
      }
    }
    this.isLoading = false;
  }

  /**
   * Function to load the form fields correspondingly to the values in the incidents object
   */
  public async loadIncidentDataIntoForm(): Promise<void> {
    // Set the right incident category
    const cat = this.categories.filter(c => c.categoryId === this.incident.category);
    if (cat.length > 0) {
      this.selectedCategory = cat[0];
      this.isIncident = this.selectedCategory.isIncident;
    }
    // Set the right machine and component
    if (this.incident.equipmentLevel === 'machine') {
      const machine = this.machines.filter(m => m.equipmentId === this.incident.equipmentId);
      if (machine.length > 0) {
        this.selectedMachine = machine[0];
      }
    } else {
      this.selectedMachine = this.incident.machine;
      const component = this.components.filter(comp => comp.equipmentId === this.incident.equipmentId);
      if (component.length > 0) {
        this.selectedComponent = component[0];
      }
    }
    // Set the right date(s)
    if (this.incident.startTime) {
      this.selectedStartDate = new Date(this.incident.startTime);
    } else {
      this.selectedStartDate = new Date(this.incident.dateOfOccurence);
    }

    if (this.incident.endTime) {
      this.selectedEndDate = new Date(this.incident.endTime);
    }

    // Set the right status
    if (this.incident.status === 'solved') {
      this.selectedStatus = true;
    } else {
      this.selectedStatus = false;
    }

    if (this.incident.editable !== undefined && this.incident.editable !== null) {
      this.isEditable = this.incident.editable;
    } else {
      this.isEditable = true;
    }
  }

  /**
   * Loads the Scenario from the incidents into the form
   */
  public async loadInicidentScenarioIntoForm(): Promise<void> {
    // Set scenarios
    // Check if scenario has a parent
    await this.loadFullScenario(this.incident.scenario.scenarioId);
    if (this.fullScenario.parentScenario) {
      // Scenario has a parent
      const parentScenario = this.scenarios.filter(sc => sc.scenarioId === this.fullScenario.parentScenario);
      if (parentScenario.length > 0) {
        this.selectedScenario = parentScenario[0];
      }
      const subScenario = this.selectedScenario.children.filter(sc => sc.scenarioId === this.incident.scenario.scenarioId);
      if (subScenario.length > 0) {
        this.selectedSubscenario = subScenario[0];
      }
    } else {
      // Scenario has no parent
      const scenario = this.scenarios.filter(sc => sc.scenarioId === this.incident.scenario.scenarioId);
      if (scenario.length > 0) {
        this.selectedScenario = scenario[0];
      }
    }
  }

  /*
  * EVENT HANDLERS
  */
  public chooseCategory(cat: IncidentCategory): void {
    this.selectedCategory = cat;
    this.isIncident = this.selectedCategory.isIncident;
    console.log('CHOSE CATEGORY: ', this.selectedCategory);
  }

  public chooseMachine(m: IncidentEquipment): void {
    this.selectedMachine = m;
    this.selectedComponent = null;
    this.selectedScenario = null;
    this.selectedSubscenario = null;
    this.subscenarios = [];
    this.loadComponents(this.selectedMachine.equipmentId);
    this.loadScenarios();
    console.log('CHOSE MACHINE: ', this.selectedMachine);
  }

  public chooseComponent(c: IncidentEquipment): void {
    this.selectedComponent = c;
    this.selectedScenario = null;
    this.selectedSubscenario = null;
    this.subscenarios = [];
    this.loadScenarios();
    console.log('CHOSE COMPONENT: ', this.selectedComponent);
  }

  public chooseScenario(s: Scenario): void {
    this.selectedScenario = s;
    this.selectedSubscenario = null;
    this.subscenarios = [];
    this.loadSubscenarios();
    console.log('CHOSE SCENARIO: ', this.selectedScenario);
  }

  public chooseSubscenario(s: Scenario): void {
    this.selectedSubscenario = s;
    console.log('CHOSE SUBSCENARIO: ', this.selectedSubscenario);
  }

  /*
  * LOAD FUNCTIONS
  */
  public async loadCategories(): Promise<void> {
    this.categories = await this.incidentsService.getIncidentCategories();
    console.log('LOADED CATEGORIES: ', this.categories);
  }

  public async loadMachines(): Promise<void> {
    if (!this.incident || !this.incident.line) {
      return;
    }
    this.machines = await this.incidentsService.getOperatorMachines(this.incident.line.equipmentId);
    console.log('LOADED MACHINES: ', this.machines);
  }

  public async loadComponents(machineId: string): Promise<void> {
    this.components = await this.incidentsService.getOperatorComponents(machineId);
    console.log('LOADED COMPONENTS FOR ' + machineId + ': ', this.components);
  }

  public async loadScenarios(): Promise<void> {
    this.scenarios = await this.incidentsService.getMatchingScenarios(this.selectedMachine.type,
      this.selectedComponent ? this.selectedComponent.type : null);
    console.log('LOADED SCENARIOS: ', this.scenarios);
  }

  public async loadSubscenarios(): Promise<void> {
    if (this.selectedScenario.children) {
      this.subscenarios = this.selectedScenario.children;
    } else {
      this.subscenarios = [];
    }
    console.log('LOADED SUBSCENARIOS: ', this.subscenarios);
  }

  public async loadFullScenario(scenarioId: string): Promise<void> {
    this.fullScenario = await this.incidentsService.getScenario(scenarioId);
    console.log('LOADED FULL SCENARIO: ', this.fullScenario);
  }

  /*
  * SAVE FUNCTION
  */
  public async save(): Promise<void> {
    // Set the category of the downtime object
    if (this.selectedCategory) {
      this.incident.category = this.selectedCategory.categoryId;
    }
    // Set these properties only if it is a incident
    if (this.isIncident) {
      if (this.selectedStatus === true) {
        this.incident.status = 'solved';
      } else {
        this.incident.status = 'pending';
      }
      if (this.subscenarios.length > 0) {
        if (this.selectedSubscenario) {
          this.incident.scenarioId = this.selectedSubscenario.scenarioId;
        }
      } else {
        if (this.selectedScenario) {
          this.incident.scenarioId = this.selectedScenario.scenarioId;
        }
      }
    }

    // Update start and endtime
    if (this.selectedStartDate) {
      const startTimeDate: moment.Moment = moment(this.selectedStartDate);
      this.incident.startTime = startTimeDate.utc().format();
    }

    if (this.selectedEndDate) {
      const endTimeDate: moment.Moment = moment(this.selectedEndDate);
      this.incident.endTime = endTimeDate.utc().format();
    }

    // Set the component as equipment if set or use the machine
    if (this.selectedComponent) {
      this.incident.equipmentId = this.selectedComponent.equipmentId;
    } else {
      this.incident.equipmentId = this.selectedMachine.equipmentId;
    }

    console.log(this.incident);

    // Save the object
    const result = await this.incidentsService.updateIncident(this.incident);
    if (this.onDataChanged) {
      const changedData: OnDataChangedObject = {
        operation: 'update',
        incident: result
      };
      this.onDataChanged(changedData);
    }
    this.modalRef.hide();
  }
}
