<div class="d-flex align-items-stretch mr-2" (click)="download()">

  <div class="mr-2">
    <img class="user-img" [src]="file && file.data && file.data.thumbnailDataUrl" />
  </div>

  <div class="content flex-grow-1">
    <div>
      <a href="#"><strong class="ellipsis">{{ file && file.data && file.data.fileName }}</strong></a>
    </div>
    <div></div>
  </div>

</div>
