<s2a-platform-header title="{{ 'components.home.title' | translate }}"></s2a-platform-header>
<main>
  <section class="content-main">
    <ul class="main-service-grid">
      <li class="grid-item" *ngFor="let app of apps" (click)="openApp(app)">
        <div class="img-container">
          <img src="assets/apps/service-icon-{{app.key}}.svg" alt="">
          <span *ngIf="app.key === 'timeline' && timelineOpenProblemsCount" class="problem-count">
            {{timelineOpenProblemsCount}}
          </span>
        </div>
        <label translate>{{app.translationKey}}</label>
        <form *ngIf="app.key === 'lunchlist'" ngNoForm #lunchlistForm method="POST" target="LunchlistWindow">
          <input type="hidden" name="id">
          <input type="hidden" name="token">
        </form>
      </li>
    </ul>
  </section>
  <!-- toggle class active -->
  <!-- <aside class="sidebar">
    TODO: Notifications-List
  </aside> -->
</main>
