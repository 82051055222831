<div class="kpi-container">
  <div class="col-12 d-flex justify-content-center mb-2">
    <div class="row align-items-center kpi-value">
      <div class="col p-0">
        <div class="value">{{ value1 }}</div>
        <div class="unit">{{ unit1 }}</div>
      </div>
      <div class="col p-0" *ngIf="value2">
          <div class="value">{{ value2 }}</div>
          <div class="unit">{{ unit2 }}</div>
        </div>
    </div>
  </div>
  <div class="col-12 text-center px-1 kpi-title">
    {{ title }}
  </div>
  <div class="col-12 text-center px-1 kpi-subtitle">
    {{ subtitle }}
  </div>
</div>