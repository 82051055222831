<s2a-modal-header *ngIf="!parentScenario" title="{{'components.edit_scenario.title' | translate}}" [modalRef]="modalRef"></s2a-modal-header>
<s2a-modal-header *ngIf="parentScenario" title="{{'components.edit_scenario.subtitle' | translate:{parent: parentScenario.title} }}" [modalRef]="modalRef"></s2a-modal-header>
<form validate [formGroup]="formGroup" (ngSubmit)="save(formGroup.value)">

  <div class="modal-body">
    <ng-container *ngIf="isLoading">
      <s2a-spinner></s2a-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <!-- <h5 translate>components.edit_equipment.equipment_data</h5> -->
      <div class="row">
        <div class="col-sm">
          <label class="form-label" translate>components.edit_scenario.scenario_title</label>
          <input type="text" class="form-control" placeholder="Title" autofocus="true" formControlName="title" id="title" />
          <div *ngIf="inputHasError(formGroup.controls.title)" class="form-error">
            <div *ngIf="formGroup.controls.title.errors.required" translate>
              components.edit_scenario.scenario_title_is_required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label class="form-label" translate>components.edit_scenario.scenario_description</label>
          <textarea class="form-control" id="description" rows="3" formControlName="description"></textarea>
          <div *ngIf="inputHasError(formGroup.controls.description)" class="form-error">
            <div *ngIf="formGroup.controls.description.errors.required" translate>
              components.edit_scenario.scenario_description_is_required
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!parentScenario">
        <div class="col-sm">
          <label class="form-label" translate>components.edit_scenario.machine_type</label>
          <select class="form-control" name="machineType" placeholder="MachineType" formControlName="machineType">
            <option *ngFor="let mt of machineTypes" value="{{mt}}">{{mt}}</option>
          </select>
          <div *ngIf="inputHasError(formGroup.controls.machineType)" class="form-error">
            <div *ngIf="formGroup.controls.machineType.errors.required" translate>
              components.edit_scenario.machine_type_is_required
            </div>
          </div>
        </div>
        <div class="col-sm">
          <label class="form-label" translate>components.edit_scenario.component_type</label>
          <select class="form-control" name="componentType" placeholder="ComponentType" formControlName="componentType">
            <option value="">-</option>
            <option *ngFor="let ct of componentTypes" value="{{ct}}">{{ct}}</option>
          </select>
        </div>
      </div>
      <hr class="transparent small">
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger btn-leaf float-right" type="button" *ngIf="scenario.scenarioId" (click)="delete()" translate>global.delete</button>
    <button class="btn btn-primary btn-leaf float-right" type="Submit" translate>global.save</button>
  </div>
</form>
