<s2a-platform-header title="{{ 'apps.performance' | translate }}"></s2a-platform-header>

<div class="container-fluid mt-3 px-desktop-5">
  <div class="row">
    <s2a-production-line-selection-performance class="mb-2 col-3" [selectedLineId]="lineIdFromRoute$ | async" (selectedLineIdChanged)="lineIdFromDropdown$.next($event)"></s2a-production-line-selection-performance>
  </div>

  <div class="row my-3">
    <div class="col-desktop-9 border-right-desktop col-mobile-12">
      <div *ngIf="!isLoadingMachines">
        <div class="row dashboard-item">
          <s2a-machine-state-widget *ngFor="let machine of machines$ | async" class="col-mobile-12 col col-not-growing"
            [lineId]="lineId$ | async"
            [id]="machine.equipmentId"
            [name]="machine.description"
            [techDesc]="machine.techDesc"
            [icon]="machine.iconUrl"
            [isLeadMachine]="machine.equipmentId == (leadMachine$ | async)?.equipmentId"
            [status]="getMachineStatus(machine)"
            [startTime]="getMachineStatusStartTime(machine)"
            [incidents]="machineCreatedIncidents[machine.equipmentId]"
            (clickedIncident)="onMachineIncidentClicked($event)"></s2a-machine-state-widget>
        </div>
      </div>
      <div *ngIf="isLoadingMachines" class="mt-3">
        <s2a-spinner></s2a-spinner>
      </div>
      <div *ngIf="errorMessageKey">
        <div class="alert alert-danger">
          {{ 'components.performance.line_dashboard.errors.' + errorMessageKey | translate }}
        </div>
      </div>
      <div *ngIf="leadMachineHistory$ | async as leadMachineHistory">
        <div class="row dashboard-item-x-padding">
          <s2a-interrupt-categories class="col-12"
            [start]="leadMachineHistory.time_from"
            [end]="leadMachineHistory.time_to"
            [rawLossesDetailed]="leadMachineHistory.losses_detailed"
            [timezone]="timezone"></s2a-interrupt-categories>
        </div>
        <div class="row dashboard-item dashboard-item-x-padding">
          <s2a-speedchart class="col-12"
            [start]="leadMachineHistory.time_from"
            [end]="leadMachineHistory.time_to"
            [rawSpeeds]="leadMachineHistory.speeds"
            [nominalSpeed]="leadMachineHistory.nominal_speed"
            [timezone]="timezone"></s2a-speedchart>
        </div>
      </div>
      <div *ngIf="isLoadingLeadMachineHistory || isLoadingTimezone" class="mt-3">
        <s2a-spinner></s2a-spinner>
      </div>
    </div>
    <div class="col-desktop-3 col-mobile-12">
      <div class="row">
        <s2a-performance-value class="col-12" icon="assets/apps/performance/pe-good-product.svg" [title]="'components.performance.line_dashboard.performance_values.units_produced' | translate" [value]="lineKpis ? (lineKpis.unitsProduced | number) : '-'"></s2a-performance-value>
        <!--<s2a-performance-value class="col-12" icon="assets/apps/performance/pe-liquid-product.svg" [title]="'components.performance.line_dashboard.performance_values.hectoliter_produced' | translate" [value]="lineKpis ? (lineKpis.hectoliterProduced | number:'.1') : '-'"></s2a-performance-value>-->
        <s2a-performance-value class="col-12" icon="assets/apps/performance/pe-downtime-management.svg" [title]="'components.performance.line_dashboard.performance_values.downtime_duration' | translate" [value]="lineKpis ? (lineKpis.downtimeDuration | formatDuration) : '-'"></s2a-performance-value>
      </div>
    </div>
  </div>
</div>
