<s2a-modal-header [title]="modalTitle | translate" [modalRef]="ref"></s2a-modal-header>
<form class="content-inverse-dark s2a-form" #form (submit)="save()" [ngClass]="{'validationStarted': validationStarted}">
  <div class="modal-body">
    <ng-container *ngIf="isLoading">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div class="row">
        <div class="col-sm-auto mb-3">
          <input type="file" class="d-none" #stepImg name="stepImg" (change)="onFileChosen()" />
          <div class="step-img" (click)="stepImg.click()" [ngStyle]="{'background-image': currentImgUrl && !loadingImgPromise ? 'url(\'' + currentImgUrl + '\')' : 'none'}">
            <span class="k-icon k-icon-32 k-i-image" *ngIf="!currentImgUrl && !loadingImgPromise"></span>
            <s2a-spinner *ngIf="loadingImgPromise || currentImgUrl === '__LOADING__'"></s2a-spinner>
            <video #video *ngIf="currentImgUrl && currentStepVideoMime && !loadingImgPromise" playsinline autoplay muted
                   class="mw-100 mh-100" (canplay)="video.muted = 'muted'">
              <source [src]="currentImgUrl" [type]="currentStepVideoMime">
            </video>
          </div>
        </div>
        <div class="col-sm-auto flex-grow-1">
          <div class="form-group">
            <label class="text-uppercase" translate>components.sop.step_modal.title</label>
            <input class="w-100 form-control"
                   [(ngModel)]="editedStep.title" name="title" required
                   [placeholder]="'components.sop.step_modal.add_title' | translate" type="text" />
          </div>
          <div class="form-group">
            <label class="text-uppercase"  translate>components.sop.step_modal.description</label>
            <textarea class="w-100 form-control"
                    [(ngModel)]="editedStep.description" name="description" required
                    [placeholder]="'components.sop.step_modal.add_description' | translate"></textarea>
          </div>
        </div>
      </div>
      <div class="sub-task mb-3">
        <div class="d-flex flex-wrap">
          <strong class="text-uppercase flex-grow-1 d-inline-flex align-items-center" translate>components.sop.step_modal.sub_task</strong>
          <button class="btn btn-link text-uppercase pr-0 pl-0" (click)="addSubtask()" type="button">
            <span class="plus-symbol mr-2">+</span>
            <span class="text-uppercase" translate>components.sop.step_modal.add_sub_task</span>
          </button>
        </div>

        <div *ngFor="let subTask of editedStep.subtasks; let i = index">
          <input class="w-100 form-control" [(ngModel)]="subtaskChanges[i]" name="sub_task_{{i}}"
                 [placeholder]="'components.sop.step_modal.sub_task_placeholder' | translate" type="text" />
        </div>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary btn-leaf float-right" [disabled]="saving">
      <span *ngIf="!saving">{{this.saveBtnTitle | translate}}</span>
      <s2a-spinner *ngIf="saving"></s2a-spinner>
    </button>
  </div>
</form>
