<s2a-platform-header title="{{ 'apps.insight' | translate }}"></s2a-platform-header>

<div class="container-fluid">
  <div class="tab-nav">
    <div class="title-dropdown" dropdown>
      <div class="title-wrapper" dropdownToggle (click)="false">
        {{ 'apps.insight' | translate }}
        <svg-icon class="dropdown-icon" src="assets/icons/down-arrow.svg"></svg-icon>
      </div>
      <ul class="dropdown-menu" *dropdownMenu role="menu">
        <li class="dropdown-item">
          <a class="btn btn-link btn-block" [routerLink]="['/insight']">
            <span translate>components.insight.structure-whiteboard.title</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="nav-list list-unstyled list-inline">
      <li class="list-inline-item active">
        <a class="btn btn-link" [routerLink]="['/insight']">
          <span translate>components.insight.structure-whiteboard.title</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<s2a-structure-whiteboard class="viewport-lg"></s2a-structure-whiteboard>


<s2a-viewport-warning class="viewport-sm" pageName="{{'components.insight.structure-whiteboard.title' | translate}}" [resolution]="1560"> </s2a-viewport-warning>