<div class="badge-container"
     *ngIf="incidents && incidents.length">
      <span class="badge badge-warning"
            [popover]="incidentsTemplate"
            container="body"
            containerClass="leaf-border-lg w-100"
            placement="bottom"
            outsideClick="true"
            viewport="body"
            (click)="openIncidents($event)">
        <span class="badge-content">{{ incidents.length }}</span>
      </span>
  <ng-template #incidentsTemplate class="incidents-popover">
    <ul class="bulletless-list">
      <li *ngFor="let incident of incidents">
        <a class="btn btn-leaf btn-secondary w-100"
           target="_blank"
           [routerLink]="['/incidents', 'reporter', incident.incident_id]"
           (click)="clickedIncident.emit(incident)">
          {{ getIncidentDate(incident) | date: 'EEE HH:mm:ss' }}
        </a>
      </li>
    </ul>
  </ng-template>
</div>

