import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BsModalService } from 'ngx-bootstrap';

import { AuthService } from '@s2a/ng-auth';
import { BasePageComponent, OnDataChanged } from '@s2a/ng-common';

import { Scenario, IncidentsService } from '@s2a/ng-incidents';
import { EditScenarioComponent } from '../edit-scenario/edit-scenario.component';

@Component({
  selector: 's2a-scenario-editor',
  templateUrl: './scenario-editor.component.html',
  styleUrls: ['./scenario-editor.component.scss']
})
export class ScenarioEditorComponent extends BasePageComponent implements OnInit {
  public scenarios: Scenario[] = [];
  public listScenarios: Scenario[] = [];
  public selectedScenario: Scenario = null;

  public searchControl: FormControl = new FormControl();
  public searchControlSubscription: Subscription;

  constructor(
    private incidentsService: IncidentsService,
    public auth: AuthService,
    private translate: TranslateService,
    private modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadScenarios();
    this.searchControlSubscription = this.searchControl.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe((searchString: string) => {
        this.loadScenarios(searchString);
      });
  }

  private loadScenarios(searchString?: string): void {
    (async () => {
      this.isLoading = true;
      if (searchString) {
        this.scenarios = await this.incidentsService.getScenarios(searchString);
      } else {
        this.scenarios = await this.incidentsService.getScenarios();
      }
      this.listScenarios = this.scenarios;
      console.log(this.scenarios);
      if (this.selectedScenario) {
        const foundScenarios = this.listScenarios.filter(s => s.scenarioId === this.selectedScenario.scenarioId);
        if (foundScenarios.length > 0) {
          this.selectScenario(foundScenarios[0], true);
        } else {
          this.unselectScenario();
        }
      }
      this.isLoading = false;
    })();
  }

  public selectScenario(scenario: Scenario, force: boolean = false): void {
    if (this.selectedScenario && !force) {
      return;
    }
    this.selectedScenario = scenario;
    this.listScenarios = this.selectedScenario.children;
  }

  public unselectScenario(): void {
    this.selectedScenario = null;
    this.listScenarios = this.scenarios;
  }

  public onScenarioChanged: OnDataChanged = (saveObject: any) => {
    this.loadScenarios();
  }

  public getIconUrl(scenario: Scenario): string {
    /*if (scenario.machineType && scenario.machineType !== null) {
      return `${environment.baseUrl}equipments/machines/${scenario.machineType}/\${theme}.svg`; //TODO maybe scenario.machineTechnology
    } else {
      return 'assets/apps/equipments/eq-'+scenario.equipmentLevel+'.svg';
    }*/
    return 'assets/apps/equipments/eq-' + scenario.equipmentLevel + '.svg';
  }

  public editScenario(scenario: Scenario): void {
    const config = {
      class: 'modal-lg',
      initialState: {
        scenario: JSON.parse(JSON.stringify(scenario)),
        parentScenario: this.selectedScenario,
        onDataChanged: this.onScenarioChanged
      }
    };
    this.modalRef = this.modalService.show(EditScenarioComponent, config);
  }

  public addScenario(): void {
    const config = {
      class: 'modal-lg',
      initialState: {
        parentScenario: this.selectedScenario,
        onDataChanged: this.onScenarioChanged
      }
    };
    this.modalRef = this.modalService.show(EditScenarioComponent, config);
  }
}
