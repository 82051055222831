export class LossesConfigElement {
    mappedOpMode: string;
    style: {
        color: string;
        text: string;
        border?: string;
    };
    mainFault: boolean;
    icon: string;
    translationKey: string;
    legendTranslationKey: string;
}

export const lossesConfig: LossesConfigElement[] = [
    {
        mappedOpMode: 'production', // deprecated
        style: {
            color: '#97C218',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'assets/icons/icon-productive.svg',
        translationKey: 'components.global.charts.losses_waterfall.productive',
        legendTranslationKey: 'components.global.charts.faults.productive'
    },
    {
        mappedOpMode: 'oee', // deprecated
        style: {
            color: '#97C218',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'assets/icons/icon-productive.svg',
        translationKey: 'components.global.charts.losses_waterfall.productive',
        legendTranslationKey: 'components.global.charts.faults.productive'
    },
    {
        mappedOpMode: 'productive',
        style: {
            color: '#97C218',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'assets/icons/icon-productive.svg',
        translationKey: 'components.global.charts.losses_waterfall.productive',
        legendTranslationKey: 'components.global.charts.faults.productive'
    },
    {
        mappedOpMode: 'tech_fault',
        style: {
            color: '#B3282E',
            text: '#FFFFFF'
        },
        mainFault: true,
        icon: 'assets/icons/icon-fault.svg',
        translationKey: 'components.global.charts.losses_waterfall.tech_faults',
        legendTranslationKey: 'components.global.charts.faults.tech_faults'
    },
    {
        mappedOpMode: 'org_fault',
        style: {
            color: '#E34805',
            text: '#FFFFFF'
        },
        mainFault: true,
        icon: 'assets/icons/icon-orgfault.svg',
        translationKey: 'components.global.charts.losses_waterfall.org_faults',
        legendTranslationKey: 'components.global.charts.faults.org_faults'
    },
    {
        mappedOpMode: 'tailback',
        style: {
            color: '#FF94EB',
            text: '#060526'
        },
        mainFault: false,
        icon: 'assets/icons/icon-tailback.svg',
        translationKey: 'components.global.charts.losses_waterfall.tailback',
        legendTranslationKey: 'components.global.charts.faults.tailback'
    },
    {
        mappedOpMode: 'lack',
        style: {
            color: '#66B0E2',
            text: '#060526'
        },
        mainFault: false,
        icon: 'assets/icons/icon-lack.svg',
        translationKey: 'components.global.charts.losses_waterfall.lack',
        legendTranslationKey: 'components.global.charts.faults.lack'
    },
    {
        mappedOpMode: 'non_productive',
        style: {
            color: '#FFFFFF',
            text: '#060526',
            border: '0.3'
        },
        mainFault: false,
        icon: 'assets/icons/icon-nonproductive.svg',
        translationKey: 'components.global.charts.losses_waterfall.non_productive',
        legendTranslationKey: 'components.global.charts.faults.non_productive'
    },
    {
        mappedOpMode: 'minor_stop',
        style: {
            color: '#FFDA5E',
            text: '#060526'
        },
        mainFault: false,
        icon: 'assets/icons/icon-minorStops.svg',
        translationKey: 'components.global.charts.losses_waterfall.minor_stop',
        legendTranslationKey: 'components.global.charts.faults.minor_stop'
    },
    {
        mappedOpMode: 'breakdown',
        style: {
            color: '#B3282E',
            text: '#FFFFFF'
        },
        mainFault: true,
        icon: 'assets/icons/icon-fault.svg',
        translationKey: 'components.global.charts.losses_waterfall.breakdown',
        legendTranslationKey: 'components.global.charts.faults.breakdown'
    },
    {
        mappedOpMode: 'incident',
        style: {
            color: '#303030',
            text: '#FFFFFF'
        },
        mainFault: true,
        icon: 'global/losses/fault.svg',
        translationKey: 'components.global.charts.losses_waterfall.incident',
        legendTranslationKey: 'components.global.charts.faults.incident'
    },
    {
        mappedOpMode: 'external',
        style: {
            color: '#FF4848',
            text: '#FFFFFF'
        },
        mainFault: true,
        icon: 'global/losses/external.svg',
        translationKey: 'components.global.charts.losses_waterfall.external',
        legendTranslationKey: 'components.global.charts.faults.external'
    },
    {
        mappedOpMode: 'changeover',
        style: {
            color: '#fcda5c',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'global/losses/changeover.svg',
        translationKey: 'components.global.charts.losses_waterfall.changeover',
        legendTranslationKey: 'components.global.charts.faults.changeover'
    },
    {
        mappedOpMode: 'planned',
        style: {
            color: '#fcda5c',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'global/losses/plannedDowntime.svg',
        translationKey: 'components.global.charts.losses_waterfall.planned',
        legendTranslationKey: 'components.global.charts.faults.planned'
    },
    {
        mappedOpMode: 'nona',
        style: {
            color: '#4C5C87',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'global/losses/nona.svg',
        translationKey: 'components.global.charts.losses_waterfall.nona',
        legendTranslationKey: 'components.global.charts.faults.nona'
    },
    {
        mappedOpMode: 'maintenance',
        style: {
            color: '#FFDD00',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'global/losses/maintenance.svg',
        translationKey: 'components.global.charts.losses_waterfall.maintenance',
        legendTranslationKey: 'components.global.charts.faults.maintenance'
    },
    {
        mappedOpMode: 'non_op_maintenance',
        style: {
            color: '#4C5C87',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'global/losses/non_op_maintenance.svg',
        translationKey: 'components.global.charts.losses_waterfall.non_op_maintenance',
        legendTranslationKey: 'components.global.charts.faults.non_op_maintenance'
    },
    {
        mappedOpMode: 'unused',
        style: {
            color: '#FDF1B3',
            text: '#FFFFFF'
        },
        mainFault: false,
        icon: 'global/losses/unused.svg',
        translationKey: 'components.global.charts.losses_waterfall.unused',
        legendTranslationKey: 'components.global.charts.faults.unused'
    },
    {
        mappedOpMode: 'prepared',
        style: {
            color: '#CDE1AF',
            text: '#060526'
        },
        mainFault: false,
        icon: 'assets/icons/icon-prepared.svg',
        translationKey: 'components.global.charts.losses_waterfall.prepared',
        legendTranslationKey: 'components.global.charts.faults.prepared'
    },
    {
        mappedOpMode: 'other_fault', // deprecated
        style: {
            color: '#FFFFFF',
            text: '#060526',
            border: '0.3'
        },
        mainFault: false,
        icon: 'assets/icons/icon-otherFault.svg',
        translationKey: 'components.global.charts.losses_waterfall.other_fault',
        legendTranslationKey: 'components.global.charts.faults.other_fault'
    }
];
