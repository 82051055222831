<s2a-platform-header title="{{ 'components.incidents.title' | translate }}"></s2a-platform-header>

<div class="container">
  <div class="tab-nav">

    <div class="title-dropdown" dropdown>
      <div class="title-wrapper" dropdownToggle (click)="false">
        {{ 'components.incidents.title' | translate }}
        <svg-icon class="dropdown-icon" src="assets/icons/down-arrow.svg"></svg-icon>
      </div>
      <ul class="dropdown-menu" *dropdownMenu role="menu">
        <li class="dropdown-item">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/incidents']">
            <span *ngIf="auth.hasGlobalRight('questionnaire-account-report') || auth.hasGlobalRight('questionnaire-detailed-report')"
              translate>components.incidents.title</span>
            <span *ngIf="!auth.hasGlobalRight('questionnaire-account-report') && !auth.hasGlobalRight('questionnaire-detailed-report')"
              translate>components.incidents.title_operator</span>
          </a>
        </li>
        <li class="dropdown-item">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/scenario-statistics']">
            <span translate>components.scenario_statistics.title</span>
          </a>
        </li>
        <li class="dropdown-item" *ngIf="auth.hasGlobalRight('questionnaire-manage-scenarios')">
          <a class="btn btn-link btn-block" [routerLink]="['/incidents/scenario-editor']">
            <span translate>components.scenario_editor.title</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="nav-list list-unstyled list-inline">
      <li class="list-inline-item active">
        <a class="btn btn-link" [routerLink]="['/incidents/incidents']">
          <span *ngIf="auth.hasGlobalRight('questionnaire-account-report') || auth.hasGlobalRight('questionnaire-detailed-report')"
            translate>components.incidents.title</span>
          <span *ngIf="!auth.hasGlobalRight('questionnaire-account-report') && !auth.hasGlobalRight('questionnaire-detailed-report')"
            translate>components.incidents.title_operator</span>
        </a>
      </li>
      <li class="list-inline-item">
        <a class="btn btn-link" [routerLink]="['/incidents/scenario-statistics']">
          <span translate>components.scenario_statistics.title</span>
        </a>
      </li>
      <li class="list-inline-item" *ngIf="auth.hasGlobalRight('questionnaire-manage-scenarios')">
        <a class="btn btn-link" [routerLink]="['/incidents/scenario-editor']">
          <span translate>components.scenario_editor.title</span>
        </a>
      </li>
    </ul>

  </div>

  <div>
    <hr class="transparent small">
    <div [formGroup]="formGroup" class="container">
      <div class="row" *ngIf="this.formGroup.controls.useLast24Hours.value !== true">
        <!-- FROM DATEPICKER -->
        <div class="col-md-6">
          <div>
            <label class="form-label" for="fromDate" translate>components.incidents.from_date_and_time</label>
          </div>
          <div class="row">
            <div class="col-6 col-sm-5">
              <input type="text" bsDatepicker formControlName="fromDate" class="form-control" (bsValueChange)="onFromDateChange($event)">
            </div>
            <div class="col-6 col-sm-7">
              <timepicker id="dateOfOccurence" formControlName="fromTime" [showMeridian]="showMeridian" [showSpinners]="false"
                (change)="loadFromFields()" [readonlyInput]="timeReadOnly"></timepicker>
            </div>
          </div>
        </div>
        <!-- TO DATEPICKER -->
        <div class="col-md-6">
          <div>
            <label class="form-label" for="toDate" translate>components.incidents.to_date_and_time</label>
          </div>
          <div class="row">
            <div class="col-6 col-sm-5">
              <input type="text" bsDatepicker formControlName="toDate" class="form-control" (bsValueChange)="onToDateChange($event)">
            </div>
            <div class="col-6 col-sm-7">
              <timepicker id="dateOfOccurence" formControlName="toTime" [showMeridian]="showMeridian" [showSpinners]="false"
                (change)="loadFromFields()" [readonlyInput]="timeReadOnly"></timepicker>
            </div>
          </div>
        </div>
      </div>
      <hr class="small d-md-none">
      <div class="row">
        <!-- SEARCH BAR -->
        <div class="col-sm-5 col-md-6">
          <input type="text" class="form-control" formControlName="searchString" placeholder="{{ 'global.search' | translate }}" />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLoading">
  <s2a-spinner></s2a-spinner>
</div>

<section *ngIf="!isLoading" class="container">
  <div *ngIf="!incidents || incidents.length === 0">
    <table class="table incidents-table">
      <tr>
        <td (click)="addScenario()">
          <div class="equipment-item">
            <div class="text-wrapper">
              <div translate class="info-text">components.incidents.no_incidents_available</div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</section>

<section #scrollSection class="container incidents-infinite-scroll" infiniteScroll
 [infiniteScrollThrottle]="1000" (scrolled)="onScrollingDown()" [scrollWindow]="false" >
  <div class="table-responsive" *ngIf="incidents && incidents.length > 0">
    <table class="table incidents-table">
      <thead>
        <tr class="d-none d-md-table-row">
          <th scope="col">{{incidents.length}}
            <span translate>components.incidents.problems_today</span>
          </th>
          <th scope="col" translate>components.incidents.category</th>
          <th scope="col" translate>components.incidents.part_problem</th>
          <th scope="col" translate>components.incidents.time</th>
          <th scope="col" translate>components.incidents.duration</th>
          <th scope="col" translate>components.incidents.state</th>
          <th></th>
        </tr>
      </thead>
      <tr *ngFor="let incident of incidents" [ngClass]="{'pending-incident': (incident.status === 'pending')}">
        <td>
          <div class="equipment-item">
            <div class="icon">
              <svg-icon [src]="getIconUrl(incident)"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{incident.equipmentName}}</h2>
              <div class="d-sm-none">
                <div>
                  <span translate>components.incidents.part</span>: {{incident.equipmentName}}</div>
                <div>
                  <span translate>components.incidents.category</span>: {{getCategoryTitle(incident)}}</div>
                <div>
                  <span translate>components.incidents.problem</span>: {{incident.scenario.description}}</div>
                <div>
                  <span translate>components.incidents.time</span>: {{ getStartTime(incident) }}</div>
                <div>
                  <span translate>components.incidents.duration</span>: {{ getDuration(incident) }}</div>
                <div>
                  <span translate>components.incidents.state</span>: {{incident.status}}</div>
              </div>
            </div>
          </div>
        </td>
        <td class="d-none d-sm-table-cell">{{ getCategoryTitle(incident) }}</td>
        <td class="d-none d-sm-table-cell">
          <div class="part-wrapper">{{incident.scenario.title}}</div>
          <div class="problem-wrapper">{{incident.scenario.description}}</div>
        </td>
        <td class="d-none d-sm-table-cell">{{ getStartTime(incident) }}</td>
        <td class="d-none d-sm-table-cell">{{ getDuration(incident) }}</td>
        <td class="d-none d-sm-table-cell incident-status">{{incident.status}}</td>
        <td class="fit-content-col">
          <a class="icon-link" (click)="editIncident(incident)">
            <svg-icon class="edit-icon" src="assets/icons/edit.svg"></svg-icon>
          </a>
        </td>
      </tr>
    </table>
  </div>
</section>
<section class="container">
<div *ngIf="page < maximumPages && pageSize <= incidents.length">
  <span translate>components.incidents.infinite_scroll.scroll</span>
</div>
<div *ngIf="page === maximumPages">
  <span translate>components.incidents.infinite_scroll.maximum</span>
</div>
</section>