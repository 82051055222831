<s2a-platform-header title="{{ 'components.incident_reporter.title' | translate }}"></s2a-platform-header>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3">

      <div class="content-wrapper" *ngIf="state === ComponentState.loadingData">
        <s2a-spinner></s2a-spinner>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.chooseLine">
        <div class="question-header" translate>components.incident_reporter.select_line</div>
        <p *ngIf="lines.length === 0" class="error mt-3" translate>components.incident_reporter.no_lines_available</p>
        <ul *ngIf="lines.length > 0" class="list-unstyled selection-list">
          <li *ngFor="let line of lines" (click)="loadMachines(line)">
            <div class="list-icon">
              <svg-icon src="assets/apps/equipments/eq-line.svg"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{line.description}}</h2>
              <p class="subline">{{line.techDesc}}&nbsp;</p>
              <div class="list-indicator">
                <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.chooseCategory">
        <div class="question-header">{{'components.incident_reporter.specify_category' | translate:{machine: originalEquipment.description} }}</div>
        <ul class="list-unstyled selection-list">
          <li *ngFor="let cat of categories" (click)="chooseCategory(cat)">
            <div class="list-icon">
              <svg-icon src="assets/apps/equipments/eq-machine.svg"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{cat.name}}</h2>
              <p class="subline">&nbsp;</p>
              <div class="list-indicator">
                <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
              </div>
            </div>
          </li>
        </ul>
        <section>
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg " (click)="cancelCategorize()">
                <span translate>global.cancel</span>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.chooseMachine">
        <div class="question-header">
          <a class="btn back-btn" (click)="stepBack()">
            <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
          </a>{{selectedLine.description}}
        </div>
        <p *ngIf="machines.length === 0" class="error mt-3" translate>components.incident_reporter.no_machines_available</p>
        <ul *ngIf="machines.length > 0" class="list-unstyled selection-list">
          <li *ngFor="let machine of machines" (click)="loadComponents(machine)">
            <div class="list-icon">
              <svg-icon [src]="getIconUrl(machine)"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{machine.description}}</h2>
              <p class="subline">{{machine.techDesc}}&nbsp;</p>
              <div class="list-indicator">
                <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.chooseComponent">
        <div class="question-header">
          <a class="btn back-btn" (click)="stepBack()">
            <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
          </a>{{selectedMachine.description}}
        </div>
        <!-- <p *ngIf="components.length === 0" class="error mt-3" translate>components.incident_reporter.no_components_available</p> -->
        <ul *ngIf="components.length > 0" class="list-unstyled selection-list">
          <li *ngFor="let component of components" (click)="loadScenarios(component)">
            <div class="list-icon">
              <svg-icon [src]="getIconUrl(component)"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{component.description}}</h2>
              <p class="subline">{{component.techDesc}}&nbsp;</p>
              <div class="list-indicator">
                <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
              </div>
            </div>
          </li>
        </ul>
        <section *ngIf="components.length > 0">
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg " (click)="loadScenarios()">
                <span translate>components.incident_reporter.unknown_component_button</span>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.chooseScenario">
        <div class="question-header" *ngIf="selectedComponent">
          <a class="btn back-btn" (click)="stepBack()">
            <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
          </a>{{selectedMachine.description}} - {{selectedComponent.description}}
        </div>
        <div class="question-header" *ngIf="!selectedComponent">
          <a class="btn back-btn" (click)="stepBack()">
            <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
          </a>{{selectedMachine.description}}
        </div>
        <p *ngIf="scenarios.length === 0" class="error mt-3" translate>components.incident_reporter.no_scenarios_available</p>
        <ul *ngIf="scenarios.length > 0" class="list-unstyled selection-list">
          <li *ngFor="let scenario of scenarios" (click)="selectScenario(scenario)">
            <div class="list-icon">
              <svg-icon src="assets/apps/equipments/eq-component.svg"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{scenario.title}}</h2>
              <p class="subline">{{scenario.techDesc}}&nbsp;</p>
              <div class="list-indicator">
                <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
              </div>
            </div>
          </li>
        </ul>
        <section>
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg " (click)="createIncident(null, ProblemButton.problemNotInList)">
                <span translate>components.incident_reporter.scenario_not_in_list_button</span>
              </a>
            </div>
          </div>
        </section>
        <br>
        <section>
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg " (click)="createIncident(null, ProblemButton.iDontKnow)">
                <span translate>components.incident_reporter.unknown_component_button</span>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.chooseSubScenario">
        <div class="question-header">
          <a class="btn back-btn" (click)="stepBack()">
            <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
          </a>{{selectedScenario.title}}
        </div>
        <ul class="list-unstyled selection-list">
          <li *ngFor="let scenario of selectedScenario.children" (click)="createIncident(scenario)">
            <div class="list-icon">
              <svg-icon src="assets/apps/equipments/eq-component.svg"></svg-icon>
            </div>
            <div class="text-wrapper">
              <h2 class="title">{{scenario.title}}</h2>
              <p class="subline">{{scenario.techDesc}}&nbsp;</p>
              <div class="list-indicator">
                <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
              </div>
            </div>
          </li>
        </ul>
        <section>
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg " (click)="createIncident(null, ProblemButton.problemNotInList)">
                <span translate>components.incident_reporter.scenario_not_in_list_button</span>
              </a>
            </div>
          </div>
        </section>
        <br>
        <section>
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg " (click)="createIncident(null, ProblemButton.iDontKnow)">
                <span translate>components.incident_reporter.unknown_component_button</span>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.createIncident">
        <div class="question-header">
          <a class="btn back-btn" (click)="stepBack()">
            <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
          </a>{{'components.incident_reporter.create_incident' | translate}}
        </div>
        <ul class="list-unstyled selection-list">
          <li>
            <div class="list-icon">
              <svg-icon src="assets/apps/equipments/eq-component.svg"></svg-icon>
            </div>
            <div class="text-wrapper" *ngIf="finalScenario">
              <h2 class="title">{{finalScenario.title}}</h2>
              <p class="subline">{{finalScenario.techDesc}}&nbsp;</p>
            </div>
            <div class="text-wrapper" *ngIf="btnState === ProblemButton.problemNotInList">
              <h2 class="title" translate>components.incident_reporter.scenario_not_in_list_button</h2>
              <p class="subline">-</p>
            </div>
            <div class="text-wrapper" *ngIf="btnState === ProblemButton.iDontKnow">
              <h2 class="title" translate>components.incident_reporter.unknown_component_button</h2>
              <p class="subline">-</p>
            </div>
          </li>
        </ul>
        <section>
          <p>{{'components.incident_reporter.problem_solved' | translate}}</p>
          <div class="row">
            <div class="col">
              <a class="btn btn-danger btn-block btn-leaf btn-lg" (click)="saveIncident(finalScenario, false)">
                <span translate>global.no</span>
              </a>
            </div>
            <div class="col">
              <a class="btn btn-success btn-block btn-leaf btn-lg" (click)="saveIncident(finalScenario, true)" translate>
                <span translate>global.yes</span>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div class="content-wrapper" *ngIf="state === ComponentState.thankYou">
        <div class="question-header">
          {{'components.incident_reporter.thank_you' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
