import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthService, LoginComponent } from '@s2a/ng-auth';

import { HomeComponent } from '@s2a/ng-platform';
// import { IncidentsComponent } from './components/apps/questionnaire/incidents/incidents.component';
import { ScenarioEditorComponent } from './components/apps/incidents/scenario-editor/scenario-editor.component';
import { IncidentReporterComponent } from './components/apps/incidents/incident-reporter/incident-reporter.component';
import { IncidentsComponent } from './components/apps/incidents/incidents/incidents.component';
import { ScenarioStatisticsComponent } from './components/apps/incidents/scenario-statistics/scenario-statistics.component';
import { InsightComponent } from './components/apps/insight/insight/insight.component';
import { MpuReportComponent } from './components/apps/insight/mpu-report/mpu-report.component';
import { LineDashboardComponent } from './components/apps/performance/line-dashboard/line-dashboard.component';
import { MachineReportComponent } from './components/apps/performance/machine-report/machine-report.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthService] },
  { path: 'home', component: HomeComponent, canActivate: [AuthService] },
  { path: 'login', component: LoginComponent },
  /* Insight */
  { path: 'insight', component: InsightComponent, canActivate: [AuthService] },
  { path: 'insight/:lineId/mpu-report/:machineId/:date/:shift', component: MpuReportComponent, canActivate: [AuthService] },
  /* Performance */
  { path: 'performance', component: LineDashboardComponent, canActivate: [AuthService] },
  { path: 'performance/line/:lineId', component: LineDashboardComponent, canActivate: [AuthService] },
  { path: 'performance/line/:lineId/machine-report/:machineId', component: MachineReportComponent, canActivate: [AuthService] },
  {
    path: 'performance/line/:lineId/machine-report/:machineId/:date/:shift',
    component: MachineReportComponent, canActivate: [AuthService]
  },
  /* Questionnaire */
  { path: 'incidents/reporter', component: IncidentReporterComponent, canActivate: [AuthService] },
  { path: 'incidents/reporter/:incidentId', component: IncidentReporterComponent, canActivate: [AuthService] },
  { path: 'incidents/scenario-editor', component: ScenarioEditorComponent, canActivate: [AuthService] },
  { path: 'incidents/scenario-statistics', component: ScenarioStatisticsComponent, canActivate: [AuthService] },
  { path: 'incidents/incidents', component: IncidentsComponent, canActivate: [AuthService] },

  /* All the Rest */
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [

  ]
})
export class AppRoutingModule { }
