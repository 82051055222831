import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { MachineSpeedItem } from '../../../../../model/machine-history';

@Component({
  selector: 's2a-speedchart',
  templateUrl: './speedchart.component.html',
  styleUrls: ['./speedchart.component.scss']
})
export class SpeedchartComponent implements OnInit {
  @Input() start: number;
  @Input() end: number;
  @Input() nominalSpeed: number;
  @Input() timezone: string;

  speedAreaColor = '#ffdd00';
  nominalSpeedColor = '#66b0e2';
  currentTimeColor = '#3382c2';

  Highcharts = Highcharts;
  chartOptions = {
    chart: {
      type: 'area',
      backgroundColor: 'transparent'
    },
    title: {
      text: '',
    },
    xAxis: {
      title: {
        enabled: false,
      },
      min: 0,
      max: 1,
      tickInterval: 1,
      tickLength: 0,
      labels: {
        enabled: false,
      },
      gridLineWidth: 2,
      gridLineColor: 'rgba(128, 128, 128, 0.5)',
      plotBands: [],
    },
    yAxis: {
      title: {
        enabled: false,
      },
      min: 0,
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      plotBands: [],
    },
    series: [{
      name: 'speed',
      data: [],
      color: this.speedAreaColor,
    }],
    plotOptions: {
      area: {
        step: 'left',
        fillOpacity: 0.8,
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
  };
  updateFlag = false;

  constructor() { }

  @Input()
  set rawSpeeds(rawSpeeds: MachineSpeedItem[]) {
    if (!rawSpeeds || !rawSpeeds.length) {
      return;
    }

    const lastRawSpeed: MachineSpeedItem = rawSpeeds[rawSpeeds.length - 1];
    rawSpeeds.push({
      start: lastRawSpeed.end,
      end: lastRawSpeed.end,
      speed: lastRawSpeed.speed,
    });
    this.chartOptions.series[0].data = this.getSpeeds(rawSpeeds);

    const lastEnd: number = lastRawSpeed.end;
    if (this.end > lastEnd) {
      const currentTimeLinePos: number = ((lastEnd - this.start) / (1000 * 60 * 60)) + this.startHour;
      this.chartOptions.xAxis.plotBands = [
        {
          color: this.currentTimeColor,
          from: currentTimeLinePos - 0.01,
          to: currentTimeLinePos + 0.03,
        }
      ];
    }

    this.updateFlag = true;
  }

  get startHour(): number {
    return (new Date(this.start)).getUTCHours();
  }

  get endHour(): number {
    return (new Date(this.end)).getUTCHours();
  }

  ngOnInit(): void {
    this.chartOptions.xAxis.min = this.startHour;
    let xAxisMax: number = this.endHour;
    if (this.endHour < this.startHour) {
      xAxisMax += 24;
    }
    this.chartOptions.xAxis.max = xAxisMax;
    this.chartOptions.yAxis.plotBands = this.getValuePlotBands(this.nominalSpeed);
  }

  getSpeeds(rawSpeeds: MachineSpeedItem[]): any[] {
    return rawSpeeds.map((rawSpeed: MachineSpeedItem) => {
      const hoursAfterStart: number = (rawSpeed.start - this.start) / (1000 * 60 * 60);
      return [this.startHour + hoursAfterStart, rawSpeed.speed];
    });
  }

  getValuePlotBands(nominalSpeed: number): any[] {
    return [
      {
        from: nominalSpeed * 0.99,
        to: nominalSpeed * 1.01,
        color: this.nominalSpeedColor,
        opacity: 1
      }
    ];
  }
}
