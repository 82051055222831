import { Equipment } from '@s2a/ng-equipment';
import { ProductionLineSelectionComponent } from './../../../../global/production-line-selection/production-line-selection.component';
import { PerformanceService } from './../../../../../services/performance.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 's2a-production-line-selection-performance',
  templateUrl: '../../../../global/production-line-selection/production-line-selection.component.html',
  styleUrls: ['../../../../global/production-line-selection/production-line-selection.component.scss']
})
export class ProductionLineSelectionPerformanceComponent extends ProductionLineSelectionComponent implements OnInit {

  constructor(private performanceService: PerformanceService) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.performanceService.getLines()
      .subscribe((equipments: Equipment[]) => {
        this.lines = equipments.map((equipment: Equipment) => <[string, string]>[equipment.equipmentId, equipment.description]);
        if (!this.selectedLineId) {
          this.selectedLineId = this.lines.length > 0 ? this.lines[0][0] : null;
          this.lineSelectionChanged(this.selectedLineId);
        }
        this.isLoading = false;
      });
  }
}
