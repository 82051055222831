<div class="mx-3 mt-3" style="color: #333;">
  <div class="mb-2" style="width: 100%;
  border-top: 2px solid;
  font-size: 0.8rem;
  border-top-color: #333;">
    {{'components.global.charts.faults.state_colors' | translate}}
  </div>
  <ul style="list-style-type: none;" class="p-0">
    <li *ngFor="let element of elements">
      <div class="d-flex flex-row align-items-center">
        <div [className]="element.border ? 'mr-2 color-field border' : 'mr-2 color-field'" [style.background-color]="element.color"></div>{{ element.translationKey | translate}}
      </div>
    </li>
  </ul>
</div>
