export interface SpeedInterval {
  start: string;
  nominal_speed?: number;
  current_speed?: number;
}

export const compareSpeedIntervals = (a: SpeedInterval, b: SpeedInterval) => {
  const aStart = parseInt(a.start, 10);
  const bStart = parseInt(b.start, 10);
  if (aStart > bStart) {
    return 1;
  }
  if (aStart < bStart) {
    return -1;
  }
  return 0;
};
