import { Component, OnInit, Input } from '@angular/core';
import { Losses } from '../../../../model/losses';
import * as moment from 'moment';
import { KendoHelper } from '../kendo-helper';
import { TranslateService } from '@ngx-translate/core';
import { lossesConfig, LossesConfigElement } from './losses-config';

const greyBarColor = 'rgba(233,233,233,0.85)';

@Component({
  selector: 's2a-losses-waterfall',
  templateUrl: './losses-waterfall.component.html',
  styleUrls: ['./losses-waterfall.component.scss']
})
export class LossesWaterfallComponent implements OnInit {
  public losses_waterfall_data: Array<WaterfallItemModel> = [];

  private _losses: Losses;
  private _duration: number;
  private translations = {};
  kendoHelper = new KendoHelper();

  @Input() set losses(losses: Losses) {
    if (losses !== undefined) {
      this._losses = losses;
    }
    this.calculateChartData();
  }

  @Input() set duration(duration: number) {
    if (duration !== undefined) {
      this._duration = duration;
    }
    this.calculateChartData();
  }

  get losses(): Losses {
    return this._losses;
  }

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.calculateTranslations();
    this.translate.onLangChange.subscribe(() => this.calculateTranslations());
  }

  private calculateTranslations(): void {
    lossesConfig.filter(configElement => 'translationKey' in configElement)
      .forEach(configElement => {
        this.translate.get(configElement.translationKey)
          .subscribe(translation => {
            this.translations[configElement.translationKey] = translation;
            this.calculateChartData();
          });
      });
  }

  public getFormattedDuration(): string {
    return moment.unix(this._duration * 3600).utc().format('hh:mm:ss');
  }

  public labelContent = (e: any): string => {
    const item = e.dataItem;
    if (!item) {
      return '';
    }
    if (this._duration === null) {
      return 'undefined';
    }
    const durationInSeconds = this._duration * 3600;
    const percentualDuration = durationInSeconds * item.value / 100;
    const formattedValue = item.value.toFixed(2);
    return `${item.label} [${this.formatTime(percentualDuration)} | ${formattedValue} %]`;
  }

  private calculateChartData(): void {
    if (this._duration === undefined || this._losses === undefined) {
      return;
    }

    let group = 0;
    let stackedValue = 0;
    this.losses_waterfall_data = [];
    lossesConfig.filter(configElement => configElement.mappedOpMode in this._losses)
      .forEach(configElement => {
        const value = this._losses[configElement.mappedOpMode] * 100;

        this.losses_waterfall_data.push(
          this.createWaterfallItem(stackedValue, value, configElement, group)
        );

        stackedValue += value;
        group++;
      });
  }

  private createWaterfallItem(stackedValue: number, value: number, configElement: LossesConfigElement, group: number): WaterfallItemModel {
    const border = configElement.style.border ? configElement.style.border : '0';
    return {
      value: value,
      stack: stackedValue,
      color: greyBarColor,
      stackColor: configElement.style.color,
      label: this.translations[configElement.translationKey],
      group: group.toString(),
      icon: configElement.icon,
      border: border,
      textColor: configElement.style.text
    };
  }

  private formatTime(seconds: number): string {
    return moment(seconds * 1000).utc().format('HH:mm:ss');
  }

  private getTextWidth(text: string): number {
    const test = document.getElementById('fontsizetest');
    test.innerText = text;
    return test.clientWidth + 5;
  }

  private calculateSide(textWidth: number, greyBarWidth: number, coloredBarWidth: number, blankWidth: number): string {
    if (coloredBarWidth >= textWidth) {
      return 'middle';
    }
    if (greyBarWidth >= textWidth) {
      return 'left';
    }
    if (blankWidth >= textWidth) {
      return 'right';
    }
    return '';
  }

  public calculateSideForItem(item: WaterfallItemModel): string {
    if (!item) {
      return '';
    }
    const waterfallChartWidth = 636;
    const textWidth = this.getTextWidth(this.labelContent({ dataItem: item }));
    const stack = item.stack * waterfallChartWidth / 100;
    const value = item.value * waterfallChartWidth / 100;
    return this.calculateSide(textWidth, stack, value, waterfallChartWidth - value - stack);
  }
}

interface WaterfallItemModel {
  value: number;
  stack: number;
  label: string;
  color: string;
  stackColor: string;
  group: string;
  icon: string;
  border?: string;
  textColor?: string;
}
