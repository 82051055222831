export enum MappedOpMode {
  TECH_FAULT = 'tech_fault',
  ORG_FAULT = 'org_fault',
  PREPARED = 'prepared',
  LACK = 'lack',
  TAILBACK = 'tailback',
  INCIDENT = 'incident',
  BREAKDOWN = 'breakdown',
  EXTERNAL = 'external',
}

export enum MergedMappedOpMode {
  PRODUCTIVE = 'productive',
  PRODUCTION = 'production',  // Deprecated!
  // OEE
  PREPARED = 'prepared',
  LACK = 'lack',
  ORG_FAULT = 'org_fault',
  TECH_FAULT = 'tech_fault',
  NON_PRODUCTIVE = 'non_productive',
  TAILBACK = 'tailback',
  // OPI
  INCIDENT = 'incident',
  EXTERNAL = 'external',
  CHANGEOVER = 'changeover',
  PLANNED = 'planned',
  UNUSED = 'unused',
  MAINTENANCE = 'maintenance',
  NON_OP_MAINTENANCE = 'non_op_maintenance',
  NONA = 'nona',
  MINOR_STOP = 'minor_stop',
  // OTHER (BROWNFIELD)
  STOP = 'stop',
  FAILURE = 'failure'
}

export enum ExternalFaultModes {
  PREPARED = 'prepared',
  LACK = 'lack',
  ORG_FAULT = 'org_fault',
  NON_PRODUCTIVE = 'non_productive',
  TAILBACK = 'tailback',
  EXTERNAL = 'external',
  CHANGEOVER = 'changeover',
  PLANNED = 'planned',
  UNUSED = 'unused',
  MAINTENANCE = 'maintenance',
  NON_OP_MAINTENANCE = 'non_op_maintenance',
  NONA = 'nona',
  MINOR_STOP = 'minor_stop',
  STOP = 'stop'
}

export enum InternalFaultModes {
  TECH_FAULT = 'tech_fault',
  INCIDENT = 'incident',
  FAILURE = 'failure'
}

export enum OtherFaultModes {
  OTHER_FAULT = 'other_fault'  // Deprecated!
}
