import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 's2a-production-line-selection',
  templateUrl: './production-line-selection.component.html',
  styleUrls: ['./production-line-selection.component.scss']
})
export class ProductionLineSelectionComponent implements OnInit {
  isLoading = false;
  lines: [string, string][];
  @Input() selectedLineId?: string;
  @Output() selectedLineIdChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  lineSelectionChanged(lineId: string): void {
    this.selectedLineIdChanged.emit(lineId);
  }
}
