<div class="table-container p-3">
  <h4 class="header">{{'components.insight.mpu-report.main_faults_summary.heading' | translate}}</h4>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th style="width: 20%;" scope="col">
            {{'components.insight.mpu-report.main_faults_common.message_no' | translate}}
          </th>
          <th style="width: 45%;" scope="col">
            {{'components.insight.mpu-report.main_faults_common.message' | translate}}
          </th>
          <th style="width: 10%;" scope="col" class="text-right">
            {{'components.insight.mpu-report.main_faults_common.count' | translate}}
          </th>
          <th style="width: 25%;" scope="col" class="text-right">
            {{'components.insight.mpu-report.main_faults_common.duration' | translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-key [ngForOf]="main_fault_keys">
          <tr class="table-border-top">
            <td class="h-100">
              <div style="display: table; height: 100%; width: 100%;">
                <div style="display: table-cell; height: 100%;">
                  <div class="colorcode h-100" [ngClass]="{'org': losses_grouped[key].mapped_op_mode === 'org_fault',
                'tech': losses_grouped[key].mapped_op_mode === 'tech_fault'}"></div>
                </div>
                <div class="text-left" style="display: table-cell; vertical-align: top; height: 100%;">{{key}}</div>
              </div>
            </td>
            <td class="align-middle">{{losses_grouped[key].info}}</td>
            <td class="text-right">
              <div *ngIf="get_losses_subset_keys(key).length === 0">{{losses_grouped[key].count_total}}</div>
            </td>
            <td class="text-right">
              <div *ngIf="get_losses_subset_keys(key).length === 0">{{losses_grouped[key].duration_total | formatDuration}}</div>
            </td>
          </tr>
          <tr *ngFor="let subsetKey of get_losses_subset_keys(key)">
            <td class="h-100">
              <div style="display: table; height: 100%; width: 100%;">
                <div style="display: table-cell; height: 100%;">
                  <div class="colorcode h-100" [ngClass]="{'org': losses_grouped[key].mapped_op_mode === 'org_fault',
                'tech': losses_grouped[key].mapped_op_mode === 'tech_fault'}"></div>
                </div>

              </div>
            </td>
            <td>{{losses_grouped[key].losses_subset[subsetKey].keyword}}</td>
            <td class="text-right">{{losses_grouped[key].losses_subset[subsetKey].count_total}}</td>
            <td class="text-right">{{losses_grouped[key].losses_subset[subsetKey].duration_total | formatDuration}}</td>
          </tr>
        </ng-template>
        <tr class="table-border-top" *ngIf="losses_grouped['other_faults'].count_total !== 0">
          <td></td>
          <td class="align-middle">{{losses_grouped['other_faults'].info}}</td>
          <td class="text-right">{{losses_grouped['other_faults'].count_total}}</td>
          <td class="text-right">{{losses_grouped['other_faults'].duration_total | formatDuration}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr style="font-weight: bold;">
          <td></td>
          <td class="text-right" style="padding: 0px;">
            {{'components.insight.mpu-report.main_faults_common.total' | translate}}
          </td>
          <td class="text-right" style="padding: 0px;">
            {{overall_fault_count}}
          </td>
          <td class="text-right" style="padding: 0px;">
            {{overall_fault_duration | formatDuration}}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>