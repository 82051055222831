<div *ngIf="formInput && formInput.invalid && ((formInput.dirty || formInput.touched) || validateInput)" class="alert alert-danger">
  
    <div *ngIf="formInput.errors.required">
      <span *ngIf="messagePrefix">{{messagePrefix | translate}}: </span>
      {{'components.global.input_validation.required' | translate}}
    </div>

    <div *ngIf="formInput.errors.min">
      <span *ngIf="messagePrefix">{{messagePrefix | translate}}: </span>
      <span translate [translateParams]="{min: formInput.errors.min.min | json, actual: formInput.errors.min.actual | json}">
        components.global.input_validation.min
      </span>
    </div>

    <div *ngIf="formInput.errors.max">
      <span *ngIf="messagePrefix">{{messagePrefix | translate}}: </span>
      <span translate [translateParams]="{max: formInput.errors.max.max | json, actual: formInput.errors.max.actual | json}">
        components.global.input_validation.max
      </span>
    </div>

    <div *ngIf="formInput.errors.minlength">
      <span *ngIf="messagePrefix">{{messagePrefix | translate}}: </span>
      <span translate [translateParams]="{minlength: formInput.errors.minlength.requiredLength | json, actual: formInput.errors.minlength.actualLength | json}">
        components.global.input_validation.minlength
      </span>
      </div>
  
    <div *ngIf="formInput.errors.maxlength">
      <span *ngIf="messagePrefix">{{messagePrefix | translate}}: </span>
      <span translate [translateParams]="{maxlength: formInput.errors.maxlength.requiredLength | json, actual: formInput.errors.maxlength.actualLength | json}">
        components.global.input_validation.maxlength
      </span>
    </div>

  </div>