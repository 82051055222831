<div *ngIf="!isLoading && !error">
  <div class="row mt-2 container-fluid mb-0">
    <div class="col-5">
      <h3 translate>components.insight.structure-whiteboard.bottle_stream</h3>
    </div>
    <div class="col-5">
      <h3 translate>components.insight.structure-whiteboard.product_stream</h3>
    </div>
  </div>
  <div style="position: relative">
    <div class="bottle-stream-container">
      <s2a-mpu-widget class="mpu-basic container-stream-0"
                      [kpi]="getKpi(activeSite?.containerStream[0]?.representativeId)"
                      [mpu]="activeSite?.containerStream[0]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.containerStream[0]) }"
                      (click)="openMpuReport(activeSite.containerStream[0])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic container-stream-1"
                      [kpi]="getKpi(activeSite?.containerStream[1]?.representativeId)"
                      [mpu]="activeSite?.containerStream[1]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.containerStream[1]) }"
                      (click)="openMpuReport(activeSite?.containerStream[1])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic container-stream-2"
                      [kpi]="getKpi(activeSite?.containerStream[2]?.representativeId)"
                      [mpu]="activeSite?.containerStream[2]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.containerStream[2]) }"
                      (click)="openMpuReport(activeSite?.containerStream[2])"></s2a-mpu-widget>

      <s2a-mpu-widget class="mpu-basic final-stream-0"
                      [kpi]="getKpi(activeSite?.finalStream[0]?.representativeId)"
                      [mpu]="activeSite?.finalStream[0]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.finalStream[0]) }"
                      (click)="openMpuReport(activeSite?.finalStream[0])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic final-stream-1"
                      [kpi]="getKpi(activeSite?.finalStream[1]?.representativeId)"
                      [mpu]="activeSite?.finalStream[1]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.finalStream[1]) }"
                      (click)="openMpuReport(activeSite?.finalStream[1])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic final-stream-2"
                      [kpi]="getKpi(activeSite?.finalStream[2]?.representativeId)"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.finalStream[2]) }"
                      [mpu]="activeSite?.finalStream[2]"
                      (click)="openMpuReport(activeSite?.finalStream[2])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic final-stream-3"
                      [kpi]="getKpi(activeSite?.finalStream[3]?.representativeId)"
                      [mpu]="activeSite?.finalStream[3]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.finalStream[3]) }"
                      (click)="openMpuReport(activeSite?.finalStream[3])"></s2a-mpu-widget>

      <s2a-mpu-widget class="mpu-basic filler"
                      [kpi]="getKpi(activeSite?.filler.representativeId)"
                      [mpu]="activeSite?.filler"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.filler) }"
                      (click)="openMpuReport(activeSite?.filler)"></s2a-mpu-widget>

      <s2a-mpu-widget class="mpu-basic product-stream-0"
                      [kpi]="getKpi(activeSite?.productStream[0]?.representativeId)"
                      [mpu]="activeSite?.productStream[0]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.productStream[0]) }"
                      (click)="openMpuReport(activeSite?.productStream[0])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic product-stream-1"
                      [kpi]="getKpi(activeSite?.productStream[1]?.representativeId)"
                      [mpu]="activeSite?.productStream[1]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.productStream[1]) }"
                      (click)="openMpuReport(activeSite?.productStream[1])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic product-stream-2"
                      [kpi]="getKpi(activeSite?.productStream[2]?.representativeId)"
                      [mpu]="activeSite?.productStream[2]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.productStream[2]) }"
                      (click)="openMpuReport(activeSite?.productStream[2])"></s2a-mpu-widget>
      <s2a-mpu-widget class="mpu-basic product-stream-3"
                      [kpi]="getKpi(activeSite?.productStream[3]?.representativeId)"
                      [mpu]="activeSite?.productStream[3]"
                      [ngClass]="{ 'clickable': mpuReportAvailable(activeSite?.productStream[3]) }"
                      (click)="openMpuReport(activeSite?.productStream[3])"></s2a-mpu-widget>
    </div>
    <div class="stream-border-top"></div>
    <div class="stream-border-bottom"></div>
  </div>
  <div class="legend">
    <div class="line"></div>
    <div>{{'components.insight.structure-whiteboard.legend_heading' | translate}}</div>
    <table>
      <tr class="table-row">
        <td>
          <div class="color-field productive"></div>
        </td>
        <td class="description">
          {{'components.insight.structure-whiteboard.legend_eff_rt' | translate}}
        </td>
        <td>
          <div class="color-field lack"></div>
        </td>
        <td class="description">
          {{'components.insight.structure-whiteboard.legend_lack' | translate}}
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="color-field tech_fault"></div>
        </td>
        <td class="description">
          {{'components.insight.structure-whiteboard.legend_tech_faults' | translate}}
        </td>
        <td>
          <div class="color-field tailback"></div>
        </td>
        <td class="description">
          {{'components.insight.structure-whiteboard.legend_tailback' | translate}}
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="color-field org_fault"></div>
        </td>
        <td class="description">
          {{'components.insight.structure-whiteboard.legend_org_faults' | translate}}
        </td>
        <td>
          <div class="color-field non_productive"></div>
        </td>
        <td class="description">
          {{'components.insight.structure-whiteboard.legend_non_prod' | translate}}
        </td>
      </tr>
    </table>
  </div>
  <div class="shift-selection">
    <div class="container stretch">
      <div class="container">
          <s2a-shift-header [firstHeading]="siteName" [secondHeading]="lineInfo" [shift]="shift" [timezone]="timezone"></s2a-shift-header>
      </div>
      <div class="container boxed">
          <s2a-production-line-selection-insight [selectedLineId]="activeSite.lineId" (selectedLineIdChanged)="changedLine($event)"></s2a-production-line-selection-insight>
          <s2a-shift-selection [shift]="shift" [shiftModel]='shiftModel' [timezone]="timezone" (shiftChanged)="changedShift($event)"></s2a-shift-selection>
      </div>
    </div>
  </div>
</div>

<div *ngIf="error && !isLoading" class="m-5 container-fluid">
  <div class="row justify-content-center">
    <div class="col-6 alert alert-danger">
      <h3>{{error.status}}:{{error.statusText}}</h3>
      <h4>{{error.message}}</h4>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="mt-3">
  <s2a-spinner></s2a-spinner>
</div>
