<div class="btn-group" dropdown [isDisabled]="isDisabled" [class.invalid]='isInvalid()'>
  <div *ngIf="!isLoading">
    <button id="button-basic" dropdownToggle type="button" class="btn btn-dropdown dropdown-toggle"
            aria-controls="dropdown-basic">
          <div class="dropdown-text">
          {{ selectedItemId && dataMap ? dataMap[selectedItemId] : preSelectString }} 
          </div>
          <s2a-svg-icon path="global/actions/" name="navigationArrow" class="dropdownButton" ></s2a-svg-icon>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" [ngClass]="getMenuHeight()"
        role="menu" aria-labelledby="button-basic">
      <li role="menuitem" *ngFor="let item of data" (click)="selectedItemChanged(item[0])" class="pointer">
        <div class="dropdown-item">
          {{ item[1] }}
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="isLoading">
      <button type="button" class="btn btn-dropdown dropdown-toggle">
        <s2a-spinner></s2a-spinner>
      </button>
  </div>
</div>