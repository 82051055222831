import { LossesMessage } from './losses-message';
import { MappedOpMode } from './mapped-op-mode';

export const otherFaults = 'other_faults';

export interface LossDetailed {
  start: string;
  end: string;
  mapped_op_mode: MappedOpMode;
  message?: LossesMessage;
  title?: string; // Deprecated!
}

export function isMessagePropertyMissing(loss: LossDetailed): boolean {
  if (loss.message == null) {
    return true;
  }
  return loss.message.id == null || loss.message.id === '' || loss.message.info == null || loss.message.info === '';
}

export const isMainFault = (loss: LossDetailed) => {
  if (isMessagePropertyMissing(loss)) {
    return false;
  }
  return (loss.mapped_op_mode === MappedOpMode.TECH_FAULT || loss.mapped_op_mode === MappedOpMode.ORG_FAULT);
};

export const isOtherFault = (loss: LossDetailed) => {
  return (loss.mapped_op_mode === MappedOpMode.TECH_FAULT || loss.mapped_op_mode === MappedOpMode.ORG_FAULT)
          && isMessagePropertyMissing(loss);
};

export const isSubsystemMissing = (loss: LossDetailed) => {
  if (loss.message == null) {
    return true;
  }
  return loss.message.subsystem == null || loss.message.subsystem === '';
};

export const getKeyOrDefault = (loss: LossDetailed): string => {
  if (isMessagePropertyMissing(loss) || isSubsystemMissing(loss)) {
    return otherFaults;
  }
  return `[${loss.message.id}]-${loss.message.subsystem}`;
};
