<s2a-platform-header title="{{ 'apps.timeline' | translate }}"></s2a-platform-header>
<section class="container page-header sop">
  <div class="create-sop pt-4">
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-light btn-leaf btn-sm ml-3" (click)="closeView()"> &lt; </button>
      <h3 class="ml-3 font-weight-bold d-inline-block mb-0" translate>
        {{editSopId ? 'components.sop.edit_sop' : 'components.sop.create_sop'}}
      </h3>
    </div>
    <form class="s2a-form" #sop_form (submit)="createOrSaveSop()" [ngClass]="{validationStarted: validationStarted}">
      <div class="p-3">
        <ng-container *ngIf="isLoading">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isLoading">
          <div>

            <!--  Equipment & downtime required -->
            <div class="row">
              <div class="form-group col-md">
                <label for="equipment" translate>components.sop.create_form.equipment</label>
                <kendo-dropdownlist
                        name="equipment"
                        id="equipment"
                        class="equipment-dropdown custom-kendo-select"
                        [data]="equipments"
                        [(ngModel)]="sop.unitId"
                        [defaultItem]="{description: 'components.sop.create_form.select_equipment' | translate, equipmentId: null}"
                        [textField]="'description'"
                        [valueField]="'equipmentId'"
                        [valuePrimitive]="true"
                        [ngClass]="{'ng-invalid': !sop.unitId && validationStarted}"
                ></kendo-dropdownlist>
              </div>
              <div class="form-group col-md">
                <label for="equipment" class="d-none d-md-block">&nbsp;</label>
                <s2a-custom-checkbox
                        name="downtime_required"
                        [(ngModel)]="sop.downtimeRequired"
                        [label]="'components.sop.create_form.downtime_required' | translate"
                ></s2a-custom-checkbox>
              </div>
            </div>

            <!-- title & category -->
            <div class="row">
              <div class="form-group col-md">
                <label for="title" translate>components.sop.create_form.title</label>
                <input class="form-control" name="title" [(ngModel)]="sop.title" id="title"
                       [placeholder]="'components.sop.create_form.add_title' | translate"
                       required />
              </div>
              <div class="form-group col-md">
                <label for="categories" translate>components.sop.create_form.category</label>
                <kendo-multiselect
                        name="categories"
                        id="categories"
                        class="custom-kendo-select category-dropdown"
                        [data]="categories"
                        [(ngModel)]="sop.categoryIds"
                        [textField]="'name'"
                        [placeholder]="'components.sop.create_form.select_please' | translate"
                        [valueField]="'categoryId'"
                        [valuePrimitive]="true"
                        [ngClass]="{'ng-invalid': !sop.categoryIds.length && validationStarted && categories.length}"
                ></kendo-multiselect>
              </div>
            </div>

            <!--  Description -->
            <div>
              <div class="form-group">
                <label for="description" translate>components.sop.create_form.description</label>
                <textarea class="form-control" id="description" rows="5" name="description" [(ngModel)]="sop.description"
                          [placeholder]="'components.sop.create_form.add_description' | translate"
                          required></textarea>
              </div>
            </div>

            <!--  interval & assembly part -->
            <!--
            <div class="row">
              <div class="form-group col-md">
                <label for="interval" translate>components.sop.create_form.interval</label>
                <input type="text" class="form-control" id="interval" [(ngModel)]="sop.interval"
                       [placeholder]="'components.sop.create_form.add_interval' | translate" name="interval">
              </div>
              <div class="form-group col-md">
                <label for="assembly_part" translate>components.sop.create_form.assembly_part</label>
                <input type="text" class="form-control" id="assembly_part" [(ngModel)]="sop.assemblyPart"
                       [placeholder]="'components.sop.create_form.add_assembly_part' | translate" name="assembly_part">
              </div>
            </div>
            -->
          </div>

          <div class="sub-list">
            <div class="d-flex">
              <h5 translate class="sub-list-header text-uppercase">components.sop.create_form.sop_steps</h5>
              <button class="btn btn-link text-uppercase pr-0 addSubItemBtn" (click)="addStep()" type="button"
                      [ngClass]="{'ng-invalid': !sop.steps.length && validationStarted}">
                <span class="plus-symbol mr-2">+</span>
                <span translate>components.sop.create_form.add_step</span>
              </button>
            </div>
            <kendo-sortable [kendoSortableBinding]="sop.steps"
                            [animation] = "true"
                            [emptyText]="''"
                            *ngIf="!isMobile"
            >
              <ng-template let-step="item">
                <div class="sub-list-element py-3 mx-md-3">
                  <div class="element-img"
                       [ngStyle]="{'background-image': stepImgs[step.imageId] ? 'url(\'' + stepImgs[step.imageId] + '\')' : 'none'}">
                    <span class="k-icon k-icon-32 k-i-image" *ngIf="!stepImgs[step.imageId]"></span>
                    <s2a-spinner *ngIf="stepImgs[step.imageId] === '__LOADING__'"></s2a-spinner>
                    <video *ngIf="stepImgs[step.imageId] && stepVideoMimes[step.imageId]" playsinline autoplay loop
                           class="w-100 h-100" style="object-fit: cover;" (canplay)="$event.target.muted = 'muted'">
                      <source [src]="stepImgs[step.imageId]" [type]="stepVideoMimes[step.imageId]">
                    </video>
                  </div>
                  <div class="element-content text-overflow-ellipsis">
                    <strong class="text-overflow-ellipsis">{{step.title}}</strong>
                    <p class="mb-0 text-overflow-ellipsis" [innerHTML]="nl2br(step.description)"></p>
                  </div>
                  <div class="element-actions d-none d-md-flex">
                    <button class="btn btn-light btn-leaf mr-3 p-1" style="cursor: move" type="button">
                      <span class="k-icon k-icon-24 k-i-move"></span>
                    </button>
                    <button class="btn btn-light btn-leaf p-1" (click)="openStep(step)" type="button">
                      <span class="k-icon k-icon-24 k-i-edit"></span>
                    </button>
                  </div>
                </div>
              </ng-template>
            </kendo-sortable>
            <div *ngIf="isMobile">
              <div class="sub-list-element py-3 mx-md-3" *ngFor="let step of sop.steps" (click)="openStep(step)">
                <div class="element-img"
                     [ngStyle]="{'background-image': stepImgs[step.imageId] ? 'url(\'' + stepImgs[step.imageId] + '\')' : 'none'}">
                  <span class="k-icon k-icon-32 k-i-image" *ngIf="!stepImgs[step.imageId]"></span>
                  <video *ngIf="stepImgs[step.imageId] && stepVideoMimes[step.imageId]" playsinline autoplay loop
                         class="w-100 h-100" style="object-fit: cover;" (canplay)="$event.target.muted = 'muted'">
                    <source [src]="stepImgs[step.imageId]" [type]="stepVideoMimes[step.imageId]">
                  </video>
                </div>
                <div class="element-content text-overflow-ellipsis">
                  <strong class="text-overflow-ellipsis">{{step.title}}</strong>
                  <p class="mb-0 text-overflow-ellipsis" [innerHTML]="nl2br(step.description)"></p>
                </div>
                <div class="element-actions d-flex flex-column">
                  <button class="btn btn-light btn-leaf p-1" type="button" (click)="moveStepUp($event, step)">
                    <span class="k-icon k-icon-24 k-i-arrow-chevron-up"></span>
                  </button>
                  <button class="btn btn-light btn-leaf p-1" type="button" (click)="moveStepDown($event, step)">
                    <span class="k-icon k-icon-24 k-i-arrow-chevron-down"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--
          <div class="sub-list">
            <div class="d-flex">
              <h5 translate class="sub-list-header text-uppercase">components.sop.create_form.parts</h5>
              <button class="btn btn-link text-uppercase pr-0" (click)="addPart()" type="button">
                <span class="plus-symbol mr-2">+</span>
                <span translate>components.sop.create_form.add_part</span>
              </button>
            </div>
          </div>

          <div class="sub-list">
            <div class="d-flex">
              <h5 translate class="sub-list-header text-uppercase">
                components.sop.create_form.attachments
              </h5>
              <button class="btn btn-link text-uppercase pr-0 text-overflow-ellipsis" (click)="addAttachment()" type="button">
                <span class="plus-symbol mr-2">+</span>
                <span translate>components.sop.create_form.add_attachment</span>
              </button>
            </div>
          </div>

          -->

        </ng-container>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary btn-leaf float-right" type="Submit" *ngIf="!isLoading"
                translate>{{editSopId ? 'global.save' : 'global.create'}}</button>
      </div>
    </form>
  </div>
</section>
