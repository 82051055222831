import { Injectable } from '@angular/core';
import { S2AHttpClient } from '@s2a/ng-common';
import { ProductionSite } from '../model/production-site';
import { Observable } from 'rxjs';
import { shareReplay, mergeMap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private cache$: Observable<ProductionSite[]>;

  constructor(private http: S2AHttpClient) { }

  /**
   * Retrieves all production sites. Last result is cached. Multiple subscriptions will result in
   * only one API call.
   */
  public getAllSites(): Observable<ProductionSite[]> {
    if (!this.cache$) {
      this.cache$ = this.http.get<ProductionSite[]>('sites')
        .pipe(
          shareReplay(1)
        );
    }
    return this.cache$;
  }

  public getSite(lineId: string): Observable<ProductionSite> {
    return this.getAllSites().pipe(
      mergeMap(sites => sites),
      filter(site => site.lineId === lineId)
    );
  }

  public listSites = async (): Promise<ProductionSite[]> => {
    const result = await this.getAllSites().toPromise();
    return Promise.resolve(result);
  }

  public async loadSites(): Promise<ProductionSite[]> {
    let sites = await this.listSites();
    if (sites.length === 0) {
      throw { status: 'Sites', statusText: '', message: 'no sites available.' };
    }
    sites = sites.sort((firstSite, secondSite) => {
      if (firstSite.name < secondSite.name) {
        return -1;
      }
      if (firstSite.name > secondSite.name) {
        return 1;
      }
      return 0;
    });
    return sites;
  }
}
