import * as moment from 'moment-timezone';
import { Component, OnInit, Input } from '@angular/core';

import { Shift } from '@s2a/ng-shifts';

import { isMainFault, isOtherFault, LossDetailed } from '../../../../../model/loss-detailed';
import { MappedOpMode } from '../../../../../model/mapped-op-mode';

@Component({
  selector: 's2a-main-faults-per-hour',
  templateUrl: './main-faults-per-hour.component.html',
  styleUrls: ['./main-faults-per-hour.component.scss']
})
export class MainFaultsPerHourComponent implements OnInit {
  private _losses_detailed: LossDetailed[];

  @Input() set losses_detailed(value: LossDetailed[]) {
    if (value !== undefined || value !== null) {
      this._losses_detailed = value;
      this.getHoursWithMainFaults();
    }
  }

  @Input() shift: Shift;
  @Input() siteLocation: string;

  get losses_detailed(): LossDetailed[] {
    return this._losses_detailed;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  getHoursWithMainFaults(): Array<{ hourNumber: number, duration: string }> {
    if (this.shift === undefined || this.siteLocation === undefined) {
      return;
    }
    const hourNumbersSet = new Map<number, { hourNumber: number, duration: string }>();
    const shiftStartTimeStamp = moment.unix(this.shift.from / 1000).tz(this.siteLocation);
    this.losses_detailed
      .filter(isMainFault)
      .forEach((loss) => {
        const startTimeStamp = moment.unix(parseInt(loss.start, 10) / 1000).tz(this.siteLocation);
        const duration = `${startTimeStamp.format('h:00:00')}-${moment(startTimeStamp).add(1, 'h').format('h:00:00 a')}`;
        const hourNumber = Math.floor(startTimeStamp.diff(shiftStartTimeStamp) / (3600 * 1000)) + 1;
        const hourNumberEntry = { hourNumber: hourNumber, duration: duration };
        hourNumbersSet.set(hourNumber, hourNumberEntry);
      });
    return Array.from(hourNumbersSet.values())
      .sort((a, b) => this.isGreater(a.hourNumber, b.hourNumber));
  }

  getCompleteMainFaultsByHour(hourNumber: number): Array<LossDetailed> {
    if (this.shift === undefined || this.siteLocation === undefined) {
      return [];
    }
    const shiftStartTimeStamp = moment.unix(this.shift.from / 1000).tz(this.siteLocation);
    const startHour = shiftStartTimeStamp.add(hourNumber - 1, 'h').format('h');
    return this._losses_detailed
      .filter(isMainFault)
      .filter((loss) => moment.unix(parseInt(loss.start, 10) / 1000).tz(this.siteLocation).format('h') === startHour);
  }

  getSumOfOtherMainFaultsByHour(hourNumber: number): CountDuration[] {
    if (this.shift === undefined || this.siteLocation === undefined) {
      return [];
    }
    const shiftStartTimeStamp = moment.unix(this.shift.from / 1000).utc();
    const startHour = shiftStartTimeStamp.add(hourNumber - 1, 'h').format('h');

    const filteredErros = this._losses_detailed
      .filter(isOtherFault)
      .filter((loss) => moment.unix(parseInt(loss.start, 10) / 1000).utc().format('h') === startHour);

    if (filteredErros.length === 0) {
      return [];
    }
    return [filteredErros.map((loss) => {
      const endTime = moment.unix(parseInt(loss.end, 10) / 1000).utc();
      const startTime = moment.unix(parseInt(loss.start, 10) / 1000).utc();
      const difference = endTime.diff(startTime);
      return { count: 1, duration: moment.duration(difference).asMilliseconds() };
    })
      .reduce(({ count, duration }, value) => ({ count: count + value.count, duration: duration + value.duration }))];
  }

  getSumRowForHourNumber(hourNumber: number): CountDuration[] {
    if (this.shift === undefined || this.siteLocation === undefined) {
      return [];
    }
    const shiftStartTimeStamp = moment.unix(this.shift.from / 1000).utc();
    const startHour = shiftStartTimeStamp.add(hourNumber - 1, 'h').format('h');

    const filteredErros = this._losses_detailed
      .filter((loss) => (loss.mapped_op_mode === MappedOpMode.TECH_FAULT || loss.mapped_op_mode === MappedOpMode.ORG_FAULT))
      .filter((loss) => moment.unix(parseInt(loss.start, 10) / 1000).utc().format('h') === startHour);

    if (filteredErros.length === 0) {
      return [{ count: 0, duration: 0 }];
    }

    return [filteredErros.map((loss) => {
      const endTime = moment.unix(parseInt(loss.end, 10) / 1000).utc();
      const startTime = moment.unix(parseInt(loss.start, 10) / 1000).utc();
      const difference = endTime.diff(startTime);
      return { count: 1, duration: moment.duration(difference).asMilliseconds() };
    })
      .reduce(({ count, duration }, value) => ({ count: count + value.count, duration: duration + value.duration }))];
  }

  formatTime(millis: number): string {
    return moment.unix(millis / 1000).tz(this.siteLocation).format('h:mm:ss a');
  }

  private isGreater(a, b): number {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }
}

interface CountDuration {
  count: number;
  duration: number;
}
