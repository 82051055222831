import * as moment from 'moment-timezone';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Shift, ShiftModel, ShiftService } from '@s2a/ng-shifts';

import { EquipmentInsight } from '../../../../model/equipment-insight';
import { Mpu } from '../../../../model/mpu';
import { ProductionSite } from '../../../../model/production-site';
import { ActiveSiteDatastoreService } from '../../../../services/active-site-datastore.service';
import { MpuKpiService } from '../../../../services/mpu-kpi.service';
import { SiteService } from '../../../../services/site.service';

@Component({
  selector: 's2a-structure-whiteboard',
  templateUrl: './structure-whiteboard.component.html',
  styleUrls: ['./structure-whiteboard.component.scss']
})
export class StructureWhiteboardComponent implements OnInit, OnDestroy {
  activeSite: ProductionSite;
  isLoading: boolean;
  mpuKpis: EquipmentInsight[] = [];
  error = null;
  lineId: string;
  timezone = 'Europe/Berlin';
  shift: Shift;
  siteName = '';
  lineInfo = '';
  shiftModel: ShiftModel;


  private subscriptions: Subscription[] = [];
  private sites: ProductionSite[];

  constructor(
    private siteService: SiteService,
    private router: Router,
    private dataStore: ActiveSiteDatastoreService,
    private shiftService: ShiftService,
    private kpiService: MpuKpiService) {
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    if (this.isSiteAlreadyLoaded()) {
      this.activeSite = this.dataStore.getActiveSite();
      this.sites = this.dataStore.getSites();
      this.shift = this.dataStore.getShift();
      this.shiftModel = this.dataStore.getCurrentShiftModel();
    } else {
      await this.initializeSite();
    }
    this.siteName = this.activeSite.name;
    this.lineInfo = this.activeSite.lineName;
    this.timezone = this.activeSite.location;
    this.loadAvailabilityForMpus();
    this.isLoading = false;

    return Promise.resolve(undefined);
  }

  public getKpi(representativeId: string): EquipmentInsight {
    return this.mpuKpis.find((kpi) => kpi !== null && kpi.eq_id === representativeId);
  }

  public mpuReportAvailable(mpu: Mpu): boolean {
    if (!mpu || this.getKpi(mpu.representativeId) === undefined || !this.kpisAvailable(mpu) || !this.lossesAvailable(mpu) ||
        this.shift === undefined) {
      return false;
    }
    return true;
  }

  public kpisAvailable(mpu: Mpu): boolean {
    return !!mpu && !!this.getKpi(mpu.representativeId) &&
      !!this.getKpi(mpu.representativeId).kpis && !this.isEmpty(this.getKpi(mpu.representativeId).kpis);
  }

  public lossesAvailable(mpu: Mpu): boolean {
    return !!mpu && !!this.getKpi(mpu.representativeId) &&
      !!this.getKpi(mpu.representativeId).losses && !this.isEmpty(this.getKpi(mpu.representativeId).losses);
  }

  private isEmpty(obj: any): boolean {
    return !Object.keys(obj).length;
  }

  public openMpuReport(mpu: Mpu): void {
    if (!this.mpuReportAvailable(mpu)) {
      return;
    }
    this.dataStore.setSelectedMpu(mpu);
    this.router.navigate([
      '/insight',
      this.activeSite.lineId,
      'mpu-report',
      mpu.representativeId,
      moment(this.shift.from).utc().format('YYYY-MM-DD'),
      moment(this.shift.from).utc().format('HH:mm')
    ]);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  private async initializeSite(): Promise<void> {
    try {
      await this.loadSites();
      await this.configureInitialShift();
    } catch (e) {
      this.error = e;
    } finally {
      return Promise.resolve(undefined);
    }
  }

  private async loadAvailabilityForMpus(): Promise<void> {
    this.isLoading = true;
    if (this.activeSite === undefined || this.shift === undefined) {
      return Promise.resolve(undefined);
    }
    const representativeIds = [
      ...this.activeSite.containerStream,
      ...this.activeSite.finalStream,
      ...this.activeSite.productStream,
      this.activeSite.filler
    ].map((mpu) => mpu.representativeId);
    this.mpuKpis = await Promise.all(representativeIds.map(async (id) => {
      try {
        const skipLossesDetailed = true;
        const includeSpeeds = false;
        return await this.kpiService.getEquipmentPerformanceIndicators(id, this.shift.from, skipLossesDetailed, includeSpeeds);
      } catch {
        console.log(`error: could not fetch kpis for equipment ${id} from service.`);
        return null;
      }
    }));
    this.isLoading = false;
    return Promise.resolve(undefined);
  }

  private async loadSites(): Promise<void> {
    this.sites = await this.siteService.listSites();
    if (this.sites.length === 0) {
      throw { status: 'Sites', statusText: '', message: 'no sites available.' };
    }
    this.sites = this.sites.sort((firstSite, secondSite) => {
      if (firstSite.name < secondSite.name) {
        return -1;
      }
      if (firstSite.name > secondSite.name) {
        return 1;
      }
      return 0;
    });
    const activeSite = this.sites[0];
    this.activeSite = activeSite;
    this.dataStore.setSites(this.sites);
    this.dataStore.setActiveSite(activeSite);
    return Promise.resolve(undefined);
  }

  private async configureInitialShift(): Promise<void> {
    const shiftModel = await this.shiftService.getShiftModel(this.activeSite.lineId).toPromise();
    const lastCompletedShift = await this.shiftService.getLastCompletedShift(this.activeSite.lineId).toPromise();
    this.dataStore.setCurrentShiftModel(shiftModel);
    this.shift = lastCompletedShift;
    this.dataStore.setShift(this.shift);
    this.shiftModel = shiftModel;
    return Promise.resolve(undefined);
  }

  private isSiteAlreadyLoaded(): boolean {
    return this.dataStore.getActiveSite() != null;
  }

  changedShift($event): void {
    if ($event != null) {
      this.shift = $event;
      this.dataStore.setShift(this.shift);
      this.loadAvailabilityForMpus();
    }
  }

  changedLine($event): void {
    if ($event != null) {
      this.isLoading = true;
      const getSite: ProductionSite = this.dataStore.getSites().find((site) => site.lineId === $event);
      this.dataStore.setActiveSite(getSite);
      this.lineInfo = getSite.lineName;
      this.timezone = getSite.location;

      this.configureInitialShift();
      this.loadAvailabilityForMpus();
      this.isLoading = false;
    }
  }
}
