<s2a-platform-header title="{{ 'apps.timeline' | translate }}"></s2a-platform-header>
<div *ngIf="isLoading" class="mt-5">
  <s2a-spinner></s2a-spinner>
</div>
<section *ngIf="!isLoading" class="container page-header sop">

  <div class="header-row">
    <div class="navigation-breadcrumbs">

      <button class="btn btn-light btn-leaf btn-sm p-1" (click)="backBtnClicked()">
        <span class="k-icon k-icon-24 k-i-arrow-chevron-left"></span>
      </button>

      <div class="d-inline-block">
        <h5 class="font-weight-bold">{{sopData.title}}</h5>
        <small [innerHTML]="sopData.description | nl2br"></small>
      </div>

    </div>
  </div>

  <div class="d-flex">

    <div class="tabber">
      <ul class="nav nav-tabs flex-grow-1" role="tablist">
        <li class="nav-item" [ngClass]="{'active': isActiveTab(tab)}" *ngFor="let tab of tabs">
          <a class="nav-link" [ngClass]="{'active': isActiveTab(tab)}" href="#" (click)="performOpenTab($event, tab)">
            {{tab.title | translate}}
          </a>
        </li>
      </ul>
    </div>
    <div class="moreIconContainer">
      <div class="app-dropdown" dropdown>
        <div class="title-wrapper" dropdownToggle (click)="false">
          <a class="k-icon k-icon-24 k-i-more-vertical" href="#"></a>
        </div>
        <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu">
          <li [s2aHasRole]="['admin', 'maintenance-manager', 'production-manger', 'scenario-editor']" class="dropdown-item" (click)="editSop()">
            <a class="btn btn-link">
              <span translate>global.edit</span>
            </a>
          </li>
          <li [s2aHasRole]="['admin', 'maintenance-manager', 'production-manger', 'scenario-editor']" class="dropdown-item" (click)="deleteSop()">
            <a class="btn btn-link">
              <span translate>global.delete</span>
            </a>
          </li>
          <li class="dropdown-item" (click)="showQRCode()">
            <a class="btn btn-link">
              <span translate>components.timeline.show_qr_code</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</section>
