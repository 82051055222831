<ng-template #tooltip>
  <s2a-faults-tooltip [losses]="tooltipLosses"></s2a-faults-tooltip>
</ng-template>

<div class="mt-5 pb-5 p-3 table-container">
  <div class="row mx-0">
    <div class="col px-0">
      <div>{{'components.insight.mpu-report.losses.heading' | translate}}</div>
    </div>
    <div kendoTooltip [tooltipTemplate]="tooltip" position="bottom" class="col-auto px-0 mr-2 info-icon">
      <svg-icon title="" src="assets/icons/icon-info.svg"></svg-icon>
    </div>
  </div>
  <div class="d-flex flex-row">
    <div class="gantt-icon-container mt-4 pl-1">
      <svg-icon style="margin-top: 68px;" src="assets/icons/icon-losses.svg"></svg-icon>
    </div>
    <div class="gantt-container mt-4">
      <small *ngFor="let hour of shiftHours; let i = index" [style.margin-left]="gridHelper.getHourLabelOffset(i)"
             style="width: 10px" class="shift-index float-left">
        {{gridHelper.getHourLabel(i)}}
      </small>
      <div *ngFor="let hour of shiftHours; let i = index" [style.margin-left]="gridHelper.getHourOffset(i)"
           class="grid-lines float-left">
      </div>
      <div class="gantt">
        <ng-template ngFor let-loss [ngForOf]="losses">
          <div [style.margin-left]="getLossOffset(loss)"
               [style.width]="getLossWidth(loss)"
               [ngClass]="getLossColorClass(loss)"
               class="record float-left"></div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row d-none d-print-block pb-0 pt-1 mb-0">
    <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
  </div>

</div>
