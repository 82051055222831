import { environment } from '../environments/environment';
import { Environment } from '@s2a/ng-common';
/* NG Modules */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BsDropdownModule,
  ModalModule,
  TabsModule,
  BsDatepickerModule,
  AccordionModule,
  TimepickerModule,
  TypeaheadModule, CollapseModule
} from 'ngx-bootstrap';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {
  MqttModule,
  IMqttServiceOptions,
} from 'ngx-mqtt';

/* 3rd Party Modules */
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RapidformsModule } from '@i-novation/ngx-rapidforms';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
import { QRCodeModule } from 'angularx-qrcode';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

//  S2A-Modules
import { NgCommonModule } from '@s2a/ng-common';
import { NgAuthModule } from '@s2a/ng-auth';
import { NgPlatformModule } from '@s2a/ng-platform';
import { NgUsersModule } from '@s2a/ng-users';
import { NgFilesModule } from '@s2a/ng-files';
import { NgSopsModule } from '@s2a/ng-sops';

/* Services */
import { S2AHttpClient, S2AHttpClientCreator } from '@s2a/ng-common';
import { CacheInterceptorService } from '@s2a/ng-common';
import { UserService } from '@s2a/ng-users';
import { AuthHttpInterceptor } from '@s2a/ng-auth';
import { EquipmentService } from '@s2a/ng-equipment';
import { ShiftService } from '@s2a/ng-shifts';
import { AccountService } from '@s2a/ng-users';
import { IncidentsService } from '@s2a/ng-incidents';
import { ActiveSiteDatastoreService } from './services/active-site-datastore.service';
import { ShiftGridHelperService } from './services/shift-grid-helper.service';

/* Components */
import { AppComponent } from './app.component';
import { LossChartComponent } from './components/global/charts/loss-chart/loss-chart.component';
import { TextKpiComponent } from './components/global/kpi/text-kpi/text-kpi.component';
import { DonutKpiComponent } from './components/global/kpi/donut-kpi/donut-kpi.component';
import { ShiftHeaderComponent } from './components/global/shift/shift-header/shift-header.component';
import { ShiftSelectionComponent } from './components/global/shift/shift-selection/shift-selection.component';
import { ProductionLineSelectionComponent } from './components/global/production-line-selection/production-line-selection.component';

/* Platform Pages */
import { LanguageSelectionComponent } from '@s2a/ng-common';

/* Incidents */
import { ScenarioEditorComponent } from './components/apps/incidents/scenario-editor/scenario-editor.component';
import { EditScenarioComponent } from './components/apps/incidents/edit-scenario/edit-scenario.component';
import { IncidentReporterComponent } from './components/apps/incidents/incident-reporter/incident-reporter.component';
import { ScenarioStatisticsComponent } from './components/apps/incidents/scenario-statistics/scenario-statistics.component';
import { IncidentsComponent } from './components/apps/incidents/incidents/incidents.component';
import { EditIncidentsComponent } from './components/apps/incidents/edit-incidents/edit-incidents.component';

/* Insight */
import { InsightComponent } from './components/apps/insight/insight/insight.component';
import { MpuWidgetComponent } from './components/apps/insight/mpu-widget/mpu-widget.component';
import { StructureWhiteboardComponent } from './components/apps/insight/structure-whiteboard/structure-whiteboard.component';
import { MpuReportComponent } from './components/apps/insight/mpu-report/mpu-report.component';
import { MainFaultsPerHourComponent } from './components/apps/insight/mpu-report/main-faults-per-hour/main-faults-per-hour.component';
import {
  ProductionLineSelectionInsightComponent
} from './components/apps/insight/structure-whiteboard/production-line-selection-insight/production-line-selection-insight.component';

/* Performance */
import { LineDashboardComponent } from './components/apps/performance/line-dashboard/line-dashboard.component';
import {
  MachineStateWidgetComponent
} from './components/apps/performance/line-dashboard/machine-state-widget/machine-state-widget.component';
import { IncidentBadgeComponent } from './components/apps/performance/line-dashboard/incident-badge/incident-badge.component';
import {
  PerformanceValueComponent
} from './components/apps/performance/line-dashboard/performance-value/performance-value.component';
import { MachineReportComponent } from './components/apps/performance/machine-report/machine-report.component';
import {
  MachineMainFaultsSummaryComponent
} from './components/apps/performance/machine-report/machine-main-faults-summary/machine-main-faults-summary.component';
import {
  MachineLossesComponent
} from './components/apps/performance/machine-report/machine-losses/machine-losses.component';
import {
  LossesTooltipComponent
} from './components/apps/performance/machine-report/losses-tooltip/losses-tooltip.component';
import { StateInfoTooltipComponent } from './components/apps/performance/shared/state-info-tooltip/state-info-tooltip.component';
import {
  InterruptCategoriesComponent
} from './components/apps/performance/line-dashboard/interrupt-categories/interrupt-categories.component';
import { SpeedchartComponent } from './components/apps/performance/line-dashboard/speedchart/speedchart.component';
import {
  MachineReportWidgetsComponent
} from './components/apps/performance/machine-report/machine-report-widgets/machine-report-widgets.component';
import {
  ProductionLineSelectionPerformanceComponent
} from './components/apps/performance/line-dashboard/production-line-selection-performance/production-line-selection-performance.component';

/* Locales */
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { SliderModule } from '@progress/kendo-angular-inputs';
import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainFaultsSummaryComponent } from './components/apps/insight/mpu-report/main-faults-summary/main-faults-summary.component';
import { LossesWaterfallComponent } from './components/global/charts/losses-waterfall/losses-waterfall.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LossesComponent } from './components/apps/insight/mpu-report/losses/losses.component';
import { FaultsTooltipComponent } from './components/global/charts/faults-tooltip/faults-tooltip.component';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { ConditionsComponent } from './components/global/charts/conditions/conditions.component';
import { StatesForValidationComponent } from './components/apps/insight/mpu-report/states-for-validation/states-for-validation.component';

import { HttpLoaderFactory, setAppInjector } from '@s2a/ng-common';
import { APP_CONFIGS } from '@s2a/ng-common';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false
};

// load app configurations
environment.apps = APP_CONFIGS;

@NgModule({
  declarations: [
    // Components
    AppComponent,
    ScenarioEditorComponent,
    EditScenarioComponent,
    IncidentReporterComponent,
    ScenarioStatisticsComponent,
    IncidentsComponent,
    EditIncidentsComponent,
    InsightComponent,
    MpuWidgetComponent,
    StructureWhiteboardComponent,
    MainFaultsPerHourComponent,
    MpuReportComponent,
    MainFaultsSummaryComponent,
    LossesWaterfallComponent,
    LossesComponent,
    FaultsTooltipComponent,
    ConditionsComponent,
    StatesForValidationComponent,
    LineDashboardComponent,
    MachineStateWidgetComponent,
    IncidentBadgeComponent,
    PerformanceValueComponent,
    MachineReportComponent,
    MachineMainFaultsSummaryComponent,
    MachineLossesComponent,
    LossesTooltipComponent,
    LossChartComponent,
    StateInfoTooltipComponent,
    InterruptCategoriesComponent,
    SpeedchartComponent,
    TextKpiComponent,
    DonutKpiComponent,
    MachineReportWidgetsComponent,
    ShiftHeaderComponent,
    ShiftSelectionComponent,
    ProductionLineSelectionComponent,
    ProductionLineSelectionInsightComponent,
    ProductionLineSelectionPerformanceComponent
  ],
  imports: [
    // NG Modules
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    RapidformsModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    DragAndDropModule,
    UiSwitchModule,
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgCommonModule.forRoot(environment),
    NgAuthModule.forRoot(),
    NgPlatformModule.forRoot(),
    NgUsersModule,
    NgFilesModule,
    NgSopsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ChartsModule,
    TooltipModule,
    NotificationModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    HighchartsChartModule,
    SwitchModule,
    SliderModule,
    CollapseModule.forRoot(),
    QRCodeModule,
    InfiniteScrollModule
  ],
  entryComponents: [
    EditScenarioComponent,
    EditIncidentsComponent,
    InsightComponent,
    LanguageSelectionComponent,
  ],
  providers: [
    // Pipes
    DecimalPipe,

    // Services
    {
      provide: S2AHttpClient,
      useFactory: S2AHttpClientCreator,
      deps: [HttpClient, Environment]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true
    },
    {
      provide: Environment,
      useValue: environment
    },
    UserService,
    EquipmentService,
    ShiftService,
    AccountService,
    IncidentsService,
    ActiveSiteDatastoreService,
    ShiftGridHelperService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(private injector: Injector) {
    setAppInjector(injector);
    defineLocale('de', deLocale);
    defineLocale('en');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEn, 'en');
  }
}
