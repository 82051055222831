import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Losses } from 'src/app/model/losses';
import { lossesConfig } from '../losses-waterfall/losses-config';

@Component({
  selector: 's2a-faults-tooltip',
  templateUrl: './faults-tooltip.component.html',
  styleUrls: ['./faults-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaultsTooltipComponent implements OnInit {

  private _losses: Losses;
  elements: Array<{
    color: string,
    translationKey: string,
    border: string
  }> = [];

  @Input() set losses(losses: Losses) {
    if (losses !== undefined) {
      this._losses = losses;
    }
    this.calculateLossesData();
  }

  constructor() { }

  ngOnInit(): void {
  }

  private calculateLossesData(): void {
    if (this._losses === undefined) {
      return;
    }

    this.elements = [];
    lossesConfig.filter(configElement => configElement.mappedOpMode in this._losses)
      .forEach(configElement => {
        this.elements.push({
          color: configElement.style.color,
          translationKey: configElement.legendTranslationKey,
          border: configElement.style.border
        });
      });
  }
}
