<s2a-modal-header [title]="'components.global.scan_qr-code.modal_title' | translate" [modalRef]="modalRef"></s2a-modal-header>
<div class="modal-body">
    <ng-container>
        <zxing-scanner #scanner [formats]="['QR_CODE']"
                       (camerasFound)="camerasFound($event)"
                       (camerasNotFound)="camerasNotFound($event)"
                       [scannerEnabled]="true"
                       (scanSuccess)="scanSuccessful($event)"
                       (scanFailure)="scanFailure($event)"
                       (scanError)="scanError($event)"
        ></zxing-scanner>
    </ng-container>
</div>

<div class="modal-footer">
    <button class="btn btn-primary btn-leaf float-right" type="Submit" translate (click)="closeAndReturn(null)">
        global.close
    </button>
</div>

